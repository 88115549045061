import common from './common'
import featuresPay from './feature'

const bn = {
    featuresPay,
    common,
    short: {
        finance: 'অর্থ',
        stock: 'স্টক'
    },
    onboarding: {
        welcome: 'স্বাগতম!',
        step: {
            product: {
                title: '৬৫% অর্জিত',
                description: 'আপনার মহান সাফল্য শুরু করতে মাত্র ২টি ধাপ বাকি!'
            },
            stock: {
                title: '৯৫% প্রায় সম্পন্ন!!',
                description: 'শুরু করতে আরও এক পদক্ষেপ বাকি! এখনই এটি সম্পন্ন করুন।'
            }
        },
        business: {
            title: 'ব্যবসা সফলভাবে তৈরি হয়েছে!',
            description: 'দারুণ! আপনার ব্যবসা নিবন্ধিত হয়েছে'
        },
        product: {
            title: 'আপনার প্রথম পণ্য যুক্ত করুন',
            description: 'স্টক রেকর্ড করা শুরু করতে একটি পণ্য যুক্ত করুন'
        },
        stock: {
            title: 'স্টক যুক্ত করার চেষ্টা করুন',
            description: 'আসন্ন এবং বাহ্যিক স্টক রেকর্ড করা শুরু করুন'
        },
        action: {
            add_business: {
                title: 'ব্যবসা তৈরি করুন',
                description: 'নতুন ব্যবসা তৈরি করতে চান? এখানে ক্লিক করুন'
            },
            join_business: {
                title: 'ব্যবসায় যোগ দিন',
                description: 'কোন আমন্ত্রণ কোড আছে? এখানে যোগ দিন'
            }
        }
    },
    business: {
        title: 'একটি ব্যবসা নির্বাচন করুন, নতুন তৈরি করুন, বা যোগ দিন',
        add_business: {
            title: 'নতুন ব্যবসা তৈরি করুন',
            name: 'ব্যবসার নাম',
            name_placeholder: 'নাম লিখুন',
            category: 'ব্যবসার বিভাগ',
            category_placeholder: 'বিভাগ নির্বাচন করুন',
            button: 'ব্যবসা তৈরি করুন'
        },
        edit_business: {
            title: 'ব্যবসা সম্পাদনা করুন'
        },
        join_business: {
            title: 'ব্যবসায় যোগ দিন',
            input_code: 'নিচে আমন্ত্রণ কোড লিখুন:',
            field_placeholder: 'আমন্ত্রণ কোড',
            confirm_title: 'যোগদান করুন',
            confirm_text: 'আপনি দলটির অংশ হয়ে যাবেন '
        },
        no_business: 'কোন ব্যবসা নেই',
        create_or_join: 'একটি ব্যবসা তৈরি করুন বা যোগ দিন',
        message: {
            saved: 'ব্যবসা সফলভাবে সংরক্ষিত হয়েছে!'
        }
    },
    profile: {
        edit_profile: {
            title: 'প্রোফাইল সম্পাদনা করুন',
            name: 'পূর্ণ নাম',
            name_placeholder: 'পূর্ণ নাম লিখুন',
            email: 'ইমেইল',
            phone_number: 'ফোন নম্বর'
        },
        message: {
            required_name: 'নাম আবশ্যক'
        }
    },
    product: {
        choose_product: 'পণ্য নির্বাচন করুন',
        choose_product_empty: 'পণ্য নির্বাচন করুন এবং পরিমাণ লিখুন',
        search: {
            placeholder: 'নামের দ্বারা অনুসন্ধান করুন',
            tooltip: 'স্ক্যানার খুলুন'
        },
        column: {
            image: 'ফটো',
            name: 'নাম',
            price: 'মূল্য',
            buying_price: 'ক্রয় মূল্য',
            selling_price: 'বিক্রয় মূল্য',
            sku: 'SKU',
            stock: 'স্টক',
            quantity: 'পরিমাণ',
            latest_stock: 'অবশিষ্ট',
            product_deleted: 'পণ্য মুছে ফেলা হয়েছে',
            info_not_found: 'তথ্য উপলব্ধ নেই',
            expired_date: 'মেয়াদ শেষের তারিখ'
        },
        no_product: 'কোন পণ্য উপলব্ধ নেই',
        add_product: {
            sku: 'পণ্য কোড',
            sku_placeholder: 'পণ্য কোড। উদাহরণ: 12345',
            name: 'পণ্যের নাম',
            name_placeholder: 'পণ্যের নাম লিখুন',
            initial_stock: 'প্রাথমিক স্টক',
            initial_stock_placeholder: 'প্রাথমিক স্টক লিখুন',
            buying_price: 'ক্রয় মূল্য',
            buying_price_placeholder: 'ক্রয় মূল্য লিখুন',
            selling_price: 'বিক্রয় মূল্য',
            selling_price_placeholder: 'বিক্রয় মূল্য লিখুন',
            category: 'শ্রেণী',
            category_placeholder: 'শ্রেণী নির্বাচন করুন',
            add_selling_price: 'থোক বিক্রয় মূল্য যোগ করুন',
            expired_date: 'মেয়াদ শেষের তারিখ',
            expired_date_placeholder: 'তারিখ নির্বাচন করুন',
            description: 'বিবরণ',
            description_placeholder: 'বিবরণ লিখুন'
        },
        choose_selling_price: 'বিক্রয় মূল্য নির্বাচন করুন',
        selling_price: 'বিক্রয় মূল্য',
        total_product: 'মোট পণ্য',
        total_quantity: 'মোট স্টক',
        total_buying_price: 'মোট ক্রয় মূল্য',
        total_selling_price: 'মোট বিক্রয় মূল্য',
        message: {
            required_name: 'পণ্যের নাম আবশ্যক',
            required_sku: 'SKU আবশ্যক',
            required_category: 'শ্রেণী আবশ্যক',
            required_quantity: 'প্রাথমিক স্টক আবশ্যক',
            invalid_input_qty: 'অবৈধ স্টক পরিমাণ',
            required_buying_price: 'ক্রয় মূল্য আবশ্যক',
            required_selling_price: 'বিক্রয় মূল্য আবশ্যক',
            required_selling_price_name: 'বিক্রয় মূল্য নাম আবশ্যক',
            required_selling_price_price: 'বিক্রয় মূল্য আবশ্যক'
        }
    },
    note: {
        title: 'নোটস',
        add: 'নতুন নোট যুক্ত করুন',
        no_note: 'কোনো নোট উপলব্ধ নেই'
    },
    settings: {
        category: 'শ্রেণী',
        language: 'ভাষা',
        switch_business: 'ব্যবসা পরিবর্তন করুন',
        currencySelect: 'মুদ্রা'
    },
    category: {
        category_list: {
            title: 'পণ্যের শ্রেণী'
        },
        add_category: {
            button: 'নতুন শ্রেণী',
            title: 'নতুন শ্রেণী যুক্ত করুন'
        },
        edit_category: {
            title: 'শ্রেণী সম্পাদনা করুন'
        },
        name: 'শ্রেণীর নাম',
        name_placeholder: 'শ্রেণীর নাম লিখুন',
        no_category: 'কোনো শ্রেণী উপলব্ধ নেই'
    },
    staff: {
        add: 'স্টাফ যুক্ত করুন',
        edit: 'স্টাফ সম্পাদনা করুন',
        access: {
            stock: 'স্টক',
            add_product: 'নতুন পণ্য যুক্ত করুন',
            edit_product: 'পণ্য সম্পাদনা করুন',
            delete_product: 'পণ্য মুছে ফেলুন',
            view_capital_price: 'মূল্য দেখুন',
            add_category: 'নতুন শ্রেণী যুক্ত করুন',
            edit_category: 'শ্রেণী সম্পাদনা করুন',
            delete_category: 'শ্রেণী মুছে ফেলুন',
            add_supplier: 'নতুন ভেন্ডর যুক্ত করুন',
            edit_supplier: 'ভেন্ডর সম্পাদনা করুন',
            delete_supplier: 'ভেন্ডর মুছে ফেলুন',
            add_customer: 'নতুন গ্রাহক যুক্ত করুন',
            edit_customer: 'গ্রাহক সম্পাদনা করুন',
            delete_customer: 'গ্রাহক মুছে ফেলুন',
            add_staff: 'স্টাফ যুক্ত করুন',
            edit_staff: 'স্টাফ সম্পাদনা করুন',
            delete_staff: 'স্টাফ মুছে ফেলুন',
            change_business_profile: 'ব্যবসার প্রোফাইল পরিবর্তন করুন'
        },
        no_staff: 'কোনো স্টাফ উপলব্ধ নেই।'
    },
    code: {
        title: 'নতুন স্টাফ যুক্ত হয়েছে!',
        subtitle: 'যোগ দিতে নিম্নলিখিত আমন্ত্রণ কোড শেয়ার করুন:',
        valid: 'কোডটি শুধুমাত্র 48 ঘণ্টা বৈধ',
        share: 'হোয়াটসঅ্যাপে শেয়ার করুন',
        copied: 'কপি করা হয়েছে!',
        invalid: 'অবৈধ কোড!'
    },
    history: {
        no_history: 'কোনো ইতিহাস নেই।',
        detail: {
            remove: 'ইতিহাস মুছে ফেলুন',
            confirm: 'এই বৈশিষ্ট্যটি বিদ্যমান স্টক, ইতিহাস এবং রিপোর্টকে প্রভাবিত করবে।'
        }
    },
    supplier: {
        add: {
            title: 'ভেন্ডর যুক্ত করুন'
        },
        edit: {
            title: 'ভেন্ডর সম্পাদনা করুন'
        },
        new: 'নতুন ভেন্ডর',
        no_supplier: 'কোনো ভেন্ডর উপলব্ধ নেই।',
        bank: {
            title: 'ব্যাংক তথ্য'
        },
        note: 'নোটস',
        form: {
            name: {
                label: 'ভেন্ডরের নাম',
                placeholder: 'ভেন্ডরের নাম লিখুন',
                placheolder_not_mandatory: 'নাম লিখুন (ঐচ্ছিক)',
                error: {
                    required: 'নাম খালি থাকতে পারে না'
                }
            },
            contact: {
                label: 'যোগাযোগের নম্বর',
                placeholder: '91934554432',
                error: {
                    required: 'যোগাযোগের নম্বর খালি থাকতে পারে না',
                    invalid: 'অবৈধ নম্বর। দেশ কোড অন্তর্ভুক্ত থাকতে হবে'
                }
            },
            bank_name: {
                label: 'ব্যাংকের নাম',
                placeholder: 'ব্যাংকের নাম লিখুন'
            },
            bank_account_name: {
                label: 'অ্যাকাউন্ট হোল্ডারের নাম',
                placeholder: 'অ্যাকাউন্ট হোল্ডারের নাম লিখুন'
            },
            bank_account_number: {
                label: 'অ্যাকাউন্ট নম্বর',
                placeholder: 'অ্যাকাউন্ট নম্বর লিখুন'
            },
            note: {
                label: 'নোটস',
                placeholder: 'নোট যোগ করুন'
            }
        },
        choose: 'ভেন্ডর নির্বাচন করুন'
    },
    customer: {
        add: {
            title: 'গ্রাহক যুক্ত করুন'
        },
        edit: {
            title: 'গ্রাহক সম্পাদনা করুন'
        },
        new: 'নতুন গ্রাহক',
        no_customer: 'কোনো গ্রাহক উপলব্ধ নেই।',
        note: 'নোটস',
        form: {
            name: {
                label: 'গ্রাহকের নাম',
                placeholder: 'গ্রাহকের নাম লিখুন',
                placheolder_not_mandatory: 'নাম লিখুন (ঐচ্ছিক)',
                error: {
                    required: 'নাম খালি থাকতে পারে না'
                }
            },
            contact: {
                label: 'যোগাযোগের নম্বর',
                placeholder: '91934554432',
                error: {
                    required: 'যোগাযোগের নম্বর খালি থাকতে পারে না',
                    invalid: 'অবৈধ নম্বর। দেশ কোড অন্তর্ভুক্ত থাকতে হবে'
                }
            },
            address: {
                label: 'ঠিকানা',
                placeholder: 'ঠিকানা লিখুন'
            },
            note: {
                label: 'নোটস',
                placeholder: 'নোট যোগ করুন'
            }
        },
        choose: 'ক্রেতা নির্বাচন করুন',
        report: {
            view: 'ক্রেতা রিপোর্ট দেখুন',
            title: 'ক্রেতা রিপোর্ট',
            no_report: 'নির্বাচিত তারিখে ক্রেতাদের জন্য কোনো রিপোর্ট নেই।',
            column: {
                name: 'নাম',
                total_transaction: 'মোট লেনদেন',
                total_amount: 'মোট বিক্রি',
                total_profit: 'মোট মুনাফা'
            }
        }
    },
    confirm_delete: 'আপনি কি নিশ্চিত যে আপনি এই তথ্য মুছে ফেলতে চান?',
    report: {
        title: 'রিপোর্ট'
    },
    accounting: {
        title: 'অ্যাকাউন্টিং',
        view_report: 'আর্থিক রিপোর্ট দেখুন',
        dashboard_title: 'আর্থিক রিপোর্ট',
        stats: 'পরিসংখ্যান',
        transaction_report: 'লেনদেনের রিপোর্ট',
        filter: {
            date: {
                placeholder: 'তারিখ নির্বাচন করুন',
                submit: 'প্রয়োগ করুন',
                cancel: 'ফিল্টার রিসেট করুন'
            },
            timeframe: {
                day: 'দৈনিক',
                week: 'সাপ্তাহিক',
                month: 'মাসিক',
                year: 'বার্ষিক'
            }
        },
        total_sales: 'মোট বিক্রি',
        total_purchases: 'মোট ক্রয়',
        total_profit: 'মোট মুনাফা',
        assets_value: 'বর্তমান সম্পদের মান',
        table: {
            date: 'তারিখ',
            sales: 'বিক্রি',
            purchases: 'ক্রয়',
            profit: 'মুনাফা'
        },
        no_data: 'কোনো তথ্য নেই।'
    },
    stock_report: {
        title: 'মজুদ রিপোর্ট',
        view: 'মজুদ রিপোর্ট দেখুন',
        no_report: 'নির্বাচিত তারিখ বা কর্মচারীর জন্য কোনো মজুদ রেকর্ড নেই।',
        allStaffPlaceHolder: 'সমস্ত কর্মচারী',
        column: {
            total_product: 'বর্তমান পণ্যের প্রকার',
            total_product_quantity: 'মোট বর্তমান পণ্য'
        }
    },
    subscription: {
        paywall_small: {
            title: 'ভিআইপি বৈশিষ্ট্য',
            description:
                'এই বৈশিষ্ট্যের জন্য আপনার ট্রায়াল সময়সীমা শেষ হয়েছে। সাবস্ক্রাইব করে এই স্থানীয় অ্যাপটির উন্নয়ন সমর্থন করুন।',
            button: 'সাবস্ক্রিপশন পরিকল্পনা দেখুন'
        }
    },
    payment: {
        order: {
            title: 'পেমেন্ট'
        }
    },
    imageUploadMessage: {
        uploadSize: 'ছবির আকার 5MB এর বেশি হতে পারবে না!',
        failedMessage: 'আইটেম ছবিটি আপলোড করতে ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন!',
        successUpload: 'ছবিটি সফলভাবে আপলোড করা হয়েছে!',
        failedUIError: 'ছবিটি মুছে ফেলতে ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন!',
        SuccessErrorImage: 'ছবিটি সফলভাবে মুছে ফেলা হয়েছে!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'পণ্যের নাম',
        categoryLabel: 'শ্রেণী',
        BuyingPriceLabel: 'ক্রয় মূল্য',
        SellingLabel: 'বিক্রয় মূল্য',
        StockLabel: 'মজুদ'
    },
    historyColumns: {
        Transactionlabel: 'লেনদেনের প্রকার',
        Locallabel: 'স্থানীয় সময়',
        Createdlabel: 'দ্বারা তৈরি',
        Vendorlabel: 'বিক্রেতার নাম',
        Buyerlabel: 'ক্রেতার নাম',
        SKUlabel: 'SKU',
        Productlabel: 'পণ্যের নাম',
        Categorylabel: 'শ্রেণী',
        Buyinglabel: 'ক্রয় মূল্য',
        Sellinglabel: 'বিক্রয় মূল্য',
        Initiallabel: 'প্রাথমিক মজুদ',
        Finallabel: 'চূড়ান্ত মজুদ',
        Stocklabel: 'মজুদ পরিবর্তন'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'পণ্যের নাম',
        categoryLabel: 'শ্রেণী',
        buyingPriceLabel: 'ক্রয় মূল্য',
        sellingPriceLabel: 'বিক্রয় মূল্য',
        stockInLabel: 'মজুদ প্রবাহ',
        stockOutLabel: 'মজুদ প্রত্যাহার',
        remainingStockLabel: 'বাকি মজুদ'
    },
    accountingReportColumns: {
        labelDate: 'তারিখ',
        labelSales: 'বিক্রি',
        labelPurchases: 'ক্রয়',
        labelProfit: 'মুনাফা'
    },
    customerReportColumns: {
        labelName: 'নাম',
        labelTotalTransaction: 'মোট লেনদেন',
        labelTotalSales: 'মোট বিক্রি',
        labelTotalProfit: 'মোট মুনাফা'
    },
    scannerDialog: {
        finished: 'সম্পন্ন',
        reduce: 'কমানো',
        successfull: 'সফল',
        searching: 'অনুসন্ধান হচ্ছে...',
        title: 'স্ক্যানার সক্রিয়। বারকোডের দিকে নির্দেশ করুন!'
    },
    historyFilter: {
        SelectBuyer: 'ক্রেতা নির্বাচন করুন',
        SelectVendor: 'বিক্রেতা নির্বাচন করুন'
    },
    productStockFilter: {
        StockLowtoHigh: 'মজুদ কম থেকে বেশি',
        StockHightoLow: 'মজুদ বেশি থেকে কম',
        AlphabetAZ: 'অক্ষর A-Z',
        AlphabetZA: 'অক্ষর Z-A'
    },
    minimizeTitle: 'স্ক্যানার সক্রিয়',
    security_guarantee: {
        title: 'ডেটা নিরাপত্তা গ্যারান্টি'
    },
    invoice: {
        invoicePlaceholder: 'চালানের নম্বর',
        invoiceTitle: 'চালান'
    }
}

export default bn
