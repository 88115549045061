const featuresPay = {
    featureTitle: 'డేటా ఆధారంగా వ్యాపార నిర్ణయాలను తీసుకోండి',
    featureDescription:
        'ఎక్కడైనా, ఎప్పుడు వాణిజ్య నివేదికలను యాక్సెస్ చేయండి, మరియు అత్యంత లాభం అందిస్తున్న అంశాలను తెలుసుకోండి. మీ వ్యాపారం మీ చేతిలో!',
    featureButton: 'యాప్ నుండి ప్రీమియం సబ్స్క్రిప్షన్',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'ప్రారంభం నుండి',
    featureEndingText: 'వేలు',

    features: [
        {
            title: 'అనంతమైన అంశాలు',
            description: 'మీ దగ్గర ఉన్న అన్ని అంశాలను పరిమితులు లేకుండా జోడించండి.'
        },
        {
            title: 'అనంతమైన సిబ్బంది',
            description: 'సిబ్బంది/అడ్మిన్‌ను వ్యాపారానికి పరిమితులు లేకుండా జోడించండి.'
        },
        {
            title: 'అనంతమైన చరిత్ర',
            description: 'పాత స్టాక్ రికార్డులలో మార్పులను పరిమితులు లేకుండా చూడండి.'
        },
        {
            title: 'చరిత్ర ఫిల్టర్',
            description: 'ప్రకారం, కేటగిరీ, సిబ్బంది, మరియు తేదీ ఆధారంగా చరిత్రను సులభంగా చూడండి.'
        },
        {
            title: 'ఎక్సెల్‌కు డౌన్‌లోడ్ చేయండి',
            description: 'ఒకే క్లిక్‌లో మైక్రోసాఫ్ట్ ఎక్సెల్‌లో స్టాక్ రికార్డ్ చరిత్రను పొందండి.'
        },
        {
            title: 'PDFకి డౌన్‌లోడ్ చేయండి',
            description: 'ఒకే క్లిక్‌లో తాజా అంశాలు మరియు స్టాక్ జాబితాను PDFలో పొందండి.'
        }
    ]
}

export default featuresPay
