import React from 'react'

const IconCheck = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0479 6.35159C20.2729 6.57662 20.3993 6.88179 20.3993 7.19999C20.3993 7.51819 20.2729 7.82335 20.0479 8.04839L10.4479 17.6484C10.2229 17.8734 9.91771 17.9997 9.59951 17.9997C9.28131 17.9997 8.97614 17.8734 8.75111 17.6484L3.95111 12.8484C3.73252 12.6221 3.61157 12.3189 3.6143 12.0043C3.61704 11.6897 3.74324 11.3887 3.96573 11.1662C4.18822 10.9437 4.48919 10.8175 4.80383 10.8148C5.11847 10.812 5.42159 10.933 5.64791 11.1516L9.59951 15.1032L18.3511 6.35159C18.5761 6.12662 18.8813 6.00024 19.1995 6.00024C19.5177 6.00024 19.8229 6.12662 20.0479 6.35159Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.64791 11.1516C5.42159 10.933 5.11847 10.812 4.80383 10.8148C4.48919 10.8175 4.18822 10.9437 3.96573 11.1662C3.74324 11.3887 3.61704 11.6897 3.6143 12.0043C3.61157 12.3189 3.73252 12.6221 3.95111 12.8484L8.75111 17.6484C8.97614 17.8734 9.28131 17.9997 9.59951 17.9997C9.91771 17.9997 10.2229 17.8734 10.4479 17.6484L20.0479 8.04839C20.2729 7.82335 20.3993 7.51819 20.3993 7.19999C20.3993 6.88179 20.2729 6.57662 20.0479 6.35159C19.8229 6.12662 19.5177 6.00024 19.1995 6.00024C18.8813 6.00024 18.5761 6.12662 18.3511 6.35159L9.59951 15.1032L5.64791 11.1516Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconCheck
