import Icon from '~/components/Icon'

import locale from '~/locale'

const navigationMenu = [
    // {
    //   text: locale.id.common.home,
    //   slug: '/',
    //   icon: <Icon name="home" width={22} height={22}></Icon>
    // },
    {
        text: locale.en.accounting.dashboard_title,
        slug: '/report',
        icon: <Icon name="money-outline" width={22} height={22}></Icon>
    },
    {
        text: locale.en.stock_report.title,
        slug: '/stock-report',
        icon: <Icon name="report" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.product,
        slug: '/product',
        icon: <Icon name="archive-outline" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.stock_in,
        slug: '/stock-in',
        icon: <Icon name="download" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.stock_out,
        slug: '/stock-out',
        icon: <Icon name="upload" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.stock_audit,
        slug: '/stock-audit',
        icon: <Icon name="switch-vertical" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.history,
        slug: '/history',
        icon: <Icon name="history" width={22} height={22}></Icon>
    },
    {
        text: locale.en.common.contact,
        slug: '/contact',
        icon: <Icon name="user-group" width={22} height={22}></Icon>,
        submenu: [
            {
                text: locale.en.common.customer,
                slug: '/customer'
            },
            {
                text: locale.en.common.supplier,
                slug: '/supplier'
            },
            {
                text: locale.en.common.staff,
                slug: '/staff'
            }
        ]
    }

    // {
    //     text: locale.id.common.customer,
    //     slug: '/customer',
    //     icon: <Icon name="user-group" width={22} height={22}></Icon>
    // },
    // {
    //     text: locale.id.common.supplier,
    //     slug: '/supplier',
    //     icon: <Icon name="box-car" width={22} height={22}></Icon>
    // },
    // {
    //     text: locale.id.common.staff,
    //     slug: '/staff',
    //     icon: <Icon name="users-outline" width={22} height={22}></Icon>
    // }
    // {
    //     text: locale.id.common.category,
    //     slug: '/category',
    //     icon: <Icon name="category" width={22} height={22}></Icon>
    // },
]

export default navigationMenu
