import React from 'react'

const IconBell = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0003 2.40002C10.0908 2.40002 8.25942 3.15859 6.90916 4.50886C5.5589 5.85912 4.80033 7.69047 4.80033 9.60002V13.9032L3.95193 14.7516C3.78416 14.9194 3.66991 15.1332 3.62363 15.366C3.57735 15.5987 3.60111 15.84 3.69192 16.0592C3.78272 16.2784 3.93649 16.4658 4.13378 16.5977C4.33107 16.7296 4.56303 16.8 4.80033 16.8H19.2003C19.4376 16.8 19.6696 16.7296 19.8669 16.5977C20.0642 16.4658 20.2179 16.2784 20.3087 16.0592C20.3995 15.84 20.4233 15.5987 20.377 15.366C20.3307 15.1332 20.2165 14.9194 20.0487 14.7516L19.2003 13.9032V9.60002C19.2003 7.69047 18.4418 5.85912 17.0915 4.50886C15.7412 3.15859 13.9099 2.40002 12.0003 2.40002ZM12.0003 21.6C11.0456 21.6 10.1299 21.2207 9.45475 20.5456C8.77961 19.8705 8.40033 18.9548 8.40033 18H15.6003C15.6003 18.9548 15.221 19.8705 14.5459 20.5456C13.8708 21.2207 12.9551 21.6 12.0003 21.6Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00033 14.4003L4.80059 15.6H19.2001L18.0003 14.4003V9.60002C18.0003 8.00873 17.3682 6.4826 16.243 5.35738C15.1178 4.23217 13.5916 3.60002 12.0003 3.60002C10.409 3.60002 8.88291 4.23217 7.75769 5.35738C6.63247 6.4826 6.00033 8.00873 6.00033 9.60002V14.4003ZM19.2003 16.8H4.80033C4.56303 16.8 4.33107 16.7296 4.13378 16.5977C3.93649 16.4658 3.78272 16.2784 3.69192 16.0592C3.60111 15.84 3.57735 15.5987 3.62363 15.366C3.66991 15.1332 3.78416 14.9194 3.95193 14.7516L4.80033 13.9032V9.60002C4.80033 7.69047 5.5589 5.85912 6.90916 4.50886C8.25942 3.15859 10.0908 2.40002 12.0003 2.40002C13.9099 2.40002 15.7412 3.15859 17.0915 4.50886C18.4418 5.85912 19.2003 7.69047 19.2003 9.60002V13.9032L20.0487 14.7516C20.2165 14.9194 20.3307 15.1332 20.377 15.366C20.4233 15.5987 20.3995 15.84 20.3087 16.0592C20.2179 16.2784 20.0642 16.4658 19.8669 16.5977C19.6696 16.7296 19.4376 16.8 19.2003 16.8ZM10.3033 19.6971C10.7534 20.1472 11.3638 20.4 12.0003 20.4C12.6369 20.4 13.2473 20.1472 13.6974 19.6971C13.847 19.5475 13.9748 19.3801 14.0788 19.2H9.92186C10.0258 19.3801 10.1537 19.5475 10.3033 19.6971ZM8.60622 19.2C8.4712 18.8182 8.40033 18.4128 8.40033 18H15.6003C15.6003 18.4128 15.5295 18.8182 15.3944 19.2C15.2171 19.7015 14.9292 20.1623 14.5459 20.5456C13.8708 21.2207 12.9551 21.6 12.0003 21.6C11.0456 21.6 10.1299 21.2207 9.45475 20.5456C9.07146 20.1623 8.78353 19.7015 8.60622 19.2Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconBell
