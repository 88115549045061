import React from 'react'

const IconCheckCircle = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0004 21.6C14.5465 21.6 16.9883 20.5886 18.7886 18.7882C20.589 16.9879 21.6004 14.5461 21.6004 12C21.6004 9.45395 20.589 7.01215 18.7886 5.2118C16.9883 3.41145 14.5465 2.40002 12.0004 2.40002C9.45431 2.40002 7.01251 3.41145 5.21217 5.2118C3.41182 7.01215 2.40039 9.45395 2.40039 12C2.40039 14.5461 3.41182 16.9879 5.21217 18.7882C7.01251 20.5886 9.45431 21.6 12.0004 21.6ZM16.4488 10.4484C16.6674 10.2221 16.7883 9.91898 16.7856 9.60434C16.7829 9.28971 16.6567 8.98873 16.4342 8.76624C16.2117 8.54375 15.9107 8.41755 15.5961 8.41481C15.2814 8.41208 14.9783 8.53303 14.752 8.75162L10.8004 12.7032L9.24879 11.1516C9.02247 10.933 8.71934 10.8121 8.40471 10.8148C8.09007 10.8175 7.7891 10.9438 7.56661 11.1662C7.34412 11.3887 7.21792 11.6897 7.21518 12.0043C7.21245 12.319 7.3334 12.6221 7.55199 12.8484L9.95199 15.2484C10.177 15.4734 10.4822 15.5998 10.8004 15.5998C11.1186 15.5998 11.4238 15.4734 11.6488 15.2484L16.4488 10.4484Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9108 7.89572L13.9183 7.88848C14.371 7.4513 14.9772 7.20939 15.6065 7.21486C16.2358 7.22033 16.8377 7.47273 17.2827 7.91771C17.7277 8.36269 17.9801 8.96464 17.9856 9.59392C17.991 10.2232 17.7491 10.8294 17.3119 11.2821L16.4488 10.4484C16.6674 10.2221 16.7883 9.91898 16.7856 9.60434C16.7829 9.28971 16.6567 8.98873 16.4342 8.76624C16.2117 8.54375 15.9107 8.41755 15.5961 8.41481C15.2814 8.41208 14.9783 8.53303 14.752 8.75162L10.8004 12.7032L9.24879 11.1516C9.02247 10.933 8.71935 10.8121 8.40471 10.8148C8.09007 10.8175 7.7891 10.9438 7.56661 11.1662C7.34412 11.3887 7.21792 11.6897 7.21518 12.0043C7.21245 12.319 7.3334 12.6221 7.55199 12.8484L9.95199 15.2484C10.177 15.4734 10.4822 15.5998 10.8004 15.5998C11.1186 15.5998 11.4238 15.4734 11.6488 15.2484L16.4488 10.4484L17.2973 11.297L12.4973 16.097C12.0473 16.5469 11.4368 16.7998 10.8004 16.7998C10.164 16.7998 9.55366 16.547 9.10359 16.0971L6.69609 13.6896L6.68884 13.6821C6.25167 13.2294 6.00976 12.6232 6.01523 11.9939C6.02069 11.3646 6.2731 10.7627 6.71808 10.3177C7.16306 9.87273 7.76501 9.62033 8.39428 9.61486C9.02355 9.60939 9.6298 9.8513 10.0824 10.2885L10.0899 10.2957L10.8004 11.0062L13.9108 7.89572ZM12.0004 20.4C14.2282 20.4 16.3648 19.515 17.9401 17.9397C19.5154 16.3644 20.4004 14.2278 20.4004 12C20.4004 9.77221 19.5154 7.63563 17.9401 6.06033C16.3648 4.48502 14.2282 3.60002 12.0004 3.60002C9.77257 3.60002 7.636 4.48502 6.06069 6.06033C4.48539 7.63563 3.60039 9.77221 3.60039 12C3.60039 14.2278 4.48539 16.3644 6.06069 17.9397C7.636 19.515 9.77257 20.4 12.0004 20.4ZM18.7886 18.7882C16.9883 20.5886 14.5465 21.6 12.0004 21.6C9.45431 21.6 7.01251 20.5886 5.21217 18.7882C3.41182 16.9879 2.40039 14.5461 2.40039 12C2.40039 9.45395 3.41182 7.01215 5.21217 5.2118C7.01251 3.41145 9.45431 2.40002 12.0004 2.40002C14.5465 2.40002 16.9883 3.41145 18.7886 5.2118C20.589 7.01215 21.6004 9.45395 21.6004 12C21.6004 14.5461 20.589 16.9879 18.7886 18.7882Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconCheckCircle
