const IconSwitchBusiness = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_302_8341)">
                <path
                    d="M21.75 4.58625H18.375V3.375C18.375 2.77826 18.1379 2.20597 17.716 1.78401C17.294 1.36205 16.7217 1.125 16.125 1.125H7.875C7.27826 1.125 6.70597 1.36205 6.28401 1.78401C5.86205 2.20597 5.625 2.77826 5.625 3.375V4.57875H2.25C1.65326 4.57875 1.08097 4.8158 0.65901 5.23776C0.237053 5.65972 0 6.23201 0 6.82875L0 20.625C0 21.2217 0.237053 21.794 0.65901 22.216C1.08097 22.6379 1.65326 22.875 2.25 22.875H21.75C22.3467 22.875 22.919 22.6379 23.341 22.216C23.7629 21.794 24 21.2217 24 20.625V6.83625C24 6.23951 23.7629 5.66722 23.341 5.24526C22.919 4.8233 22.3467 4.58625 21.75 4.58625ZM7.125 3.375C7.125 3.17609 7.20402 2.98532 7.34467 2.84467C7.48532 2.70402 7.67609 2.625 7.875 2.625H16.125C16.3239 2.625 16.5147 2.70402 16.6553 2.84467C16.796 2.98532 16.875 3.17609 16.875 3.375V4.57875H7.125V3.375ZM22.5 20.625C22.5 20.8239 22.421 21.0147 22.2803 21.1553C22.1397 21.296 21.9489 21.375 21.75 21.375H2.25C2.05109 21.375 1.86032 21.296 1.71967 21.1553C1.57902 21.0147 1.5 20.8239 1.5 20.625V6.83625C1.5 6.63734 1.57902 6.44657 1.71967 6.30592C1.86032 6.16527 2.05109 6.08625 2.25 6.08625H21.75C21.9489 6.08625 22.1397 6.16527 22.2803 6.30592C22.421 6.44657 22.5 6.63734 22.5 6.83625V20.625Z"
                    fill="currentColor"
                />
                <path
                    d="M15.6825 8.31385C15.532 8.19263 15.3405 8.13418 15.148 8.15069C14.9555 8.16721 14.7767 8.2574 14.649 8.40248C14.5214 8.54756 14.4546 8.73632 14.4627 8.9294C14.4708 9.12248 14.5532 9.30498 14.6925 9.43885H9.14254C8.45349 9.44474 7.79438 9.72134 7.30748 10.2089C6.82058 10.6965 6.54494 11.356 6.54004 12.0451V14.5989C6.54004 14.7978 6.61906 14.9885 6.75971 15.1292C6.90036 15.2698 7.09113 15.3489 7.29004 15.3489C7.48895 15.3489 7.67972 15.2698 7.82037 15.1292C7.96102 14.9885 8.04004 14.7978 8.04004 14.5989V12.0451C8.04484 11.7539 8.16242 11.4758 8.36804 11.2695C8.57367 11.0632 8.8513 10.9446 9.14254 10.9389H14.6963C14.5569 11.0727 14.4746 11.2552 14.4665 11.4483C14.4584 11.6414 14.5251 11.8302 14.6528 11.9752C14.7805 12.1203 14.9592 12.2105 15.1517 12.227C15.3443 12.2435 15.5358 12.1851 15.6863 12.0639L17.1863 10.7551C17.2679 10.6843 17.3333 10.5968 17.378 10.4984C17.4226 10.4 17.4455 10.2931 17.445 10.1851C17.445 10.0777 17.4218 9.97155 17.3772 9.87386C17.3326 9.77617 17.2674 9.68921 17.1863 9.61885L15.6825 8.31385Z"
                    fill="currentColor"
                />
                <path
                    d="M16.7099 12.026C16.511 12.026 16.3203 12.105 16.1796 12.2457C16.039 12.3864 15.9599 12.5771 15.9599 12.776V15.3298C15.9551 15.621 15.8376 15.8991 15.6319 16.1054C15.4263 16.3117 15.1487 16.4302 14.8574 16.436H9.30369C9.44306 16.3022 9.52538 16.1197 9.53349 15.9266C9.54159 15.7335 9.47485 15.5447 9.34718 15.3997C9.21951 15.2546 9.04077 15.1644 8.84823 15.1479C8.65568 15.1314 8.46419 15.1898 8.31369 15.311L6.81369 16.6198C6.73205 16.6905 6.66666 16.7781 6.622 16.8765C6.57734 16.9749 6.55447 17.0817 6.55494 17.1898C6.55501 17.2972 6.57814 17.4033 6.62278 17.501C6.66743 17.5987 6.73253 17.6857 6.81369 17.756L8.33244 19.061C8.46927 19.1781 8.64364 19.2419 8.82369 19.241C8.97459 19.2399 9.12163 19.1933 9.2456 19.1072C9.36958 19.0212 9.46471 18.8998 9.51859 18.7588C9.57246 18.6178 9.58256 18.4639 9.54756 18.3171C9.51257 18.1703 9.43411 18.0375 9.32244 17.936H14.8724C15.5615 17.9301 16.2206 17.6535 16.7075 17.1659C17.1944 16.6783 17.47 16.0188 17.4749 15.3298V12.776C17.475 12.6763 17.4551 12.5775 17.4164 12.4855C17.3778 12.3935 17.3212 12.3102 17.25 12.2403C17.1788 12.1705 17.0943 12.1156 17.0016 12.0788C16.9088 12.042 16.8097 12.024 16.7099 12.026Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_302_8341">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconSwitchBusiness
