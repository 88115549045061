const featuresPay = {
    featureTitle: 'اتخذ قرارات العمل بناءً على البيانات',
    featureDescription:
        'اعرف أي العناصر تُباع وتحقق أكبر قدر من الأرباح، واطلع على التقارير المالية في أي وقت ومن أي مكان. عملك في متناول يدك!',
    featureButton: 'اشتراك بريميوم من التطبيق',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'ابتداءً من',
    featureEndingText: 'ألف',

    features: [
        {
            title: 'عناصر غير محدودة',
            description: 'أضف جميع العناصر التي تمتلكها دون أي حدود.'
        },
        {
            title: 'موظفين غير محدودين',
            description: 'أضف موظفين/مديرين للعمل دون أي حدود.'
        },
        {
            title: 'تاريخ غير محدود',
            description: 'اطلع على التغييرات في سجلات المخزون القديمة دون أي حدود.'
        },
        {
            title: 'تصفية التاريخ',
            description: 'قم بعرض التاريخ بسهولة بناءً على النوع والفئة والموظف والتاريخ.'
        },
        {
            title: 'تحميل إلى Excel',
            description: 'احصل على تاريخ سجلات المخزون في Microsoft Excel بنقرة واحدة فقط.'
        },
        {
            title: 'تحميل إلى PDF',
            description: 'احصل على قائمة العناصر والمخزون الأخيرة في PDF بنقرة واحدة فقط.'
        }
    ]
}

export default featuresPay
