const IconHistory = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-inherit h-inherit" viewBox="0 0 48 48">
            <path
                d="m26.05 23.3 5.4 5.3q.6.6.6 1.3 0 .7-.6 1.25-.55.6-1.25.6t-1.25-.6l-5.75-5.6q-.35-.35-.525-.8-.175-.45-.175-.95v-8.15q0-.8.5-1.3t1.3-.5q.75 0 1.25.5t.5 1.3Zm-2.4 19.95q-7.1 0-12.2-4.45T5.1 27.55q-.25-1 .275-1.875T6.95 24.7q.85-.1 1.575.325.725.425.975 1.225 1.1 5.2 4.875 8.75t9.275 3.55q6.15 0 10.425-4.325T38.35 23.75q0-6-4.3-10.15t-10.4-4.15q-2.95 0-5.625 1.225T13.3 14h2.4q.75 0 1.275.525.525.525.525 1.325 0 .8-.525 1.3t-1.275.5H8.4q-1.05 0-1.725-.675T6 15.3V8.1q0-.75.525-1.275Q7.05 6.3 7.8 6.3q.8 0 1.3.525T9.6 8.1v2.95q2.75-2.95 6.375-4.65Q19.6 4.7 23.65 4.7q4 0 7.55 1.525t6.175 4.125q2.625 2.6 4.175 6.075Q43.1 19.9 43.1 23.9q0 4-1.55 7.525-1.55 3.525-4.175 6.15-2.625 2.625-6.175 4.15-3.55 1.525-7.55 1.525Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconHistory
