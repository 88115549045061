const IconStaff = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.918 14.382C18.918 11.412 16.488 8.982 13.518 8.982C10.548 8.982 8.118 11.412 8.118 14.382C8.118 17.352 10.548 19.782 13.518 19.782C16.488 19.782 18.918 17.352 18.918 14.382ZM13.518 16.182C12.528 16.182 11.718 15.372 11.718 14.382C11.718 13.392 12.528 12.582 13.518 12.582C14.508 12.582 15.318 13.392 15.318 14.382C15.318 15.372 14.508 16.182 13.518 16.182ZM25.218 19.782C27.216 19.782 28.818 18.18 28.818 16.182C28.818 14.184 27.216 12.582 25.218 12.582C23.22 12.582 21.6 14.184 21.618 16.182C21.618 18.18 23.22 19.782 25.218 19.782ZM18 0C8.064 0 0 8.064 0 18C0 27.936 8.064 36 18 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 18 0ZM6.93 27.198C8.154 26.226 11.016 25.2 13.518 25.2C13.644 25.2 13.788 25.218 13.932 25.218C14.364 24.066 15.138 22.896 16.272 21.87C15.3638 21.696 14.4425 21.5997 13.518 21.582C11.178 21.582 7.416 22.392 5.004 24.156C4.104 22.284 3.6 20.196 3.6 17.982C3.6 10.044 10.062 3.582 18 3.582C25.938 3.582 32.4 10.044 32.4 17.982C32.4 20.142 31.914 22.194 31.05 24.048C29.25 22.986 26.802 22.482 25.218 22.482C22.482 22.482 17.118 23.94 17.118 27.342V32.346C15.1571 32.2345 13.2403 31.7198 11.4874 30.834C9.73441 29.9482 8.18297 28.7104 6.93 27.198Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconStaff
