const IconBarcodeScanner = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-inhert h-inherit">
            <path
                fill="currentColor"
                d="M40 936V754h60v122h122v60H40Zm697 0v-60h122V754h60v182H737ZM153 825V326h80v499h-80Zm121 0V326h42v499h-42Zm122 0V326h83v499h-83Zm125 0V326h121v499H521Zm163 0V326h42v499h-42Zm83 0V326h38v499h-38ZM40 398V216h182v60H100v122H40Zm819 0V276H737v-60h182v182h-60Z"
            />
        </svg>
    )
}

export default IconBarcodeScanner
