import axios from 'axios'

import { AppProps } from 'next/app'

import AuthProvider from '~/components/Auth/AuthProvider'
import GlobalDataProvider from '~/components/Common/GlobalDataProvider'
import { useInterval } from '~/hooks'

import { Provider } from 'react-redux'
import { SubscriptionProvider } from '~/components/Subscription/SubscriptionProvider'
import { store } from '~/redux/store'
import '../styles/components/calender.css'
import '../styles/globals.css'

import { useEffect, useState } from 'react'
import { Meta } from '~/components/Common/Meta'
import { Toaster } from '~/components/ui/toaster'

const rtlLanguages = ['ar', 'ur'] // Define languages that need RTL

const MyApp = ({ Component, pageProps }: AppProps) => {
    const [language, setLanguage] = useState('en')

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'en'
        setLanguage(storedLanguage)

        const isRTL = rtlLanguages.includes(storedLanguage)
        document.documentElement.dir = isRTL ? 'rtl' : 'ltr'
    }, [language])

    useInterval(
        async () => {
            const { buildId } = await axios.get('/api/build-id').then((res) => res.data)

            if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
                window.location.reload()
            }
        },
        { interval: 5000 * 60 }
    )
    return (
        <>
            <Meta>
                <meta name="application-name" content="simplystock.co" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="apple-mobile-web-app-title" content="Simply" />

                <meta name="format-detection" content="telephone=no" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="msapplication-TileColor" content="#0051E0" />
                <meta name="msapplication-tap-highlight" content="no" />
                <meta name="theme-color" content="#000000" />

                <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />

                <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="mask-icon" href="/icons/apple-touch-icon.png" color="#0051E0" />
                <link rel="shortcut icon" href="/favicon.ico" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:url" content="https://web.simplystock.co" />
                <meta name="twitter:title" content="Simply" />

                <meta
                    name="twitter:image"
                    content="https://simplyazurestorage.blob.core.windows.net/common/Download%20Options.png?sv=2024-11-04&st=2024-10-17T10%3A24%3A02Z&se=2034-10-15T10%3A24%3A02Z&sr=b&sp=r&sig=9AxfuakmxcPZlIkXS699OwyI4fwe12JWXoZ90ruv2m4%3D"
                />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content="Simply" />
                <meta property="og:url" content="https://web.simplystock.co" />

                <meta
                    property="og:image"
                    content="https://simplyazurestorage.blob.core.windows.net/common/Download%20Options.png?sv=2024-11-04&st=2024-10-17T10%3A24%3A02Z&se=2034-10-15T10%3A24%3A02Z&sr=b&sp=r&sig=9AxfuakmxcPZlIkXS699OwyI4fwe12JWXoZ90ruv2m4%3D"
                />
            </Meta>

            {/* <Script src="https://js.durianpay.id/0.1.39/durianpay.min.js"></Script> */}
            <Provider store={store}>
                <Toaster></Toaster>
                <AuthProvider>
                    <SubscriptionProvider>
                        <GlobalDataProvider>
                            <Component {...pageProps} />
                        </GlobalDataProvider>
                    </SubscriptionProvider>
                </AuthProvider>
            </Provider>
        </>
    )
}

export default MyApp
