const IconUserGroup = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M-12-228v-65.434q0-44.133 45-71.35Q78-392 150.376-392H162q5 0 11 .652-10 20.348-15.18 41.671-5.181 21.323-5.181 44.501V-228H-12Zm240 0v-77q0-35 18-64t52-50q34-21 80.294-32 46.293-11 101.43-11 56.197 0 101.99 11 45.794 11 80.286 32 34 21 52 50t18 64v77H228Zm579 0v-77.429q0-23.802-5-45.121-5-21.32-14-40.723 6-.727 11.257-.727H810q73.7 0 117.85 27.046Q972-337.907 972-293v65H807Zm-485-80h316v-6q-8-32-50.5-50.5T480-383q-65 0-107.5 19T322-313v5ZM149.78-419Q118-419 95.5-441.83T73-496.719Q73-529 95.624-551.5t54.394-22.5Q182-574 205-551.465q23 22.536 23 54.977Q228-465 205.261-442t-55.481 23Zm660 0q-31.78 0-54.28-22.83T733-496.719Q733-529 755.624-551.5t54.394-22.5Q842-574 865-551.465q23 22.536 23 54.977Q888-465 865.261-442t-55.481 23Zm-329.289-72q-55.204 0-93.848-38.643Q348-568.286 348-623.491 348-679 386.643-717q38.644-38 93.848-38Q536-755 574-717t38 93.509q0 55.205-38 93.848T480.491-491Zm.36-80Q502-571 517.5-586.456t15.5-37.5Q533-646 517.226-661q-15.774-15-36.875-15Q458-676 443-660.919t-15 37.375Q428-602 442.851-586.5q14.851 15.5 38 15.5ZM480-308Zm0-315Z"
            />
        </svg>
    )
}

export default IconUserGroup
