const IconStockAudit = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.625 0C13.7299 0 12.8714 0.355579 12.2385 0.988515C11.6056 1.62145 11.25 2.47989 11.25 3.375V5.625C11.25 6.52011 11.6056 7.37855 12.2385 8.01149C12.8714 8.64442 13.7299 9 14.625 9H21.375C22.2701 9 23.1286 8.64442 23.7615 8.01149C24.3944 7.37855 24.75 6.52011 24.75 5.625V3.375C24.75 2.47989 24.3944 1.62145 23.7615 0.988515C23.1286 0.355579 22.2701 0 21.375 0L14.625 0ZM21.375 2.25C21.6734 2.25 21.9595 2.36853 22.1705 2.5795C22.3815 2.79048 22.5 3.07663 22.5 3.375V5.625C22.5 5.92337 22.3815 6.20952 22.1705 6.4205C21.9595 6.63147 21.6734 6.75 21.375 6.75H14.625C14.3266 6.75 14.0405 6.63147 13.8295 6.4205C13.6185 6.20952 13.5 5.92337 13.5 5.625V3.375C13.5 3.07663 13.6185 2.79048 13.8295 2.5795C14.0405 2.36853 14.3266 2.25 14.625 2.25H21.375Z"
                fill="currentColor"
            />
            <path
                d="M9 3.375H6.75C5.55653 3.375 4.41193 3.84911 3.56802 4.69302C2.72411 5.53693 2.25 6.68153 2.25 7.875V31.5C2.25 32.6935 2.72411 33.8381 3.56802 34.682C4.41193 35.5259 5.55653 36 6.75 36H29.25C30.4435 36 31.5881 35.5259 32.432 34.682C33.2759 33.8381 33.75 32.6935 33.75 31.5V7.875C33.75 6.68153 33.2759 5.53693 32.432 4.69302C31.5881 3.84911 30.4435 3.375 29.25 3.375H27V5.625C27 6.36369 26.8545 7.09514 26.5718 7.77759C26.2891 8.46005 25.8748 9.08015 25.3525 9.60248C24.8301 10.1248 24.2101 10.5391 23.5276 10.8218C22.8451 11.1045 22.1137 11.25 21.375 11.25H14.625C13.1332 11.25 11.7024 10.6574 10.6475 9.60248C9.59263 8.54758 9 7.11684 9 5.625V3.375ZM24.4215 19.9215L17.6715 26.6715C17.567 26.7763 17.4429 26.8594 17.3062 26.9161C17.1695 26.9728 17.023 27.002 16.875 27.002C16.727 27.002 16.5805 26.9728 16.4438 26.9161C16.3071 26.8594 16.183 26.7763 16.0785 26.6715L12.7035 23.2965C12.4923 23.0853 12.3736 22.7987 12.3736 22.5C12.3736 22.2013 12.4923 21.9147 12.7035 21.7035C12.9147 21.4923 13.2013 21.3736 13.5 21.3736C13.7987 21.3736 14.0853 21.4923 14.2965 21.7035L16.875 24.2843L22.8285 18.3285C23.0397 18.1173 23.3263 17.9986 23.625 17.9986C23.9237 17.9986 24.2103 18.1173 24.4215 18.3285C24.6327 18.5397 24.7514 18.8263 24.7514 19.125C24.7514 19.4237 24.6327 19.7103 24.4215 19.9215Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconStockAudit
