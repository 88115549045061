import { IS_PROD } from '~/constants'

export const $log = (message?: any, ...optionalParams: any[]) => {
    if (IS_PROD) {
        return
    }

    if (optionalParams.length === 0) {
        // eslint-disable-next-line no-console
        console.log(message)
        return
    }

    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams)
}

export const $error = (message?: any, ...optionalParams: any[]) => {
    if (IS_PROD) {
        return
    }

    if (optionalParams.length === 0) {
        // eslint-disable-next-line no-console
        console.error(message)
        return
    }

    // eslint-disable-next-line no-console
    console.error(message, ...optionalParams)
}
