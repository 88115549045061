const COLORS = {
    MAIN: {
        PRIMARY: {
            DEFAULT: '#0051E0',
            900: '#00297B',
            800: '#00297B',
            700: '#003CAC',
            600: '#0051E0',
            500: '#306DFB',
            400: '#5F8BFF',
            300: '#8AA8FF',
            200: '#B3C5FF',
            100: '#DCE5FB',
            50: '#F3F6FE'
        },
        SECONDARY: {
            DEFAULT: '#6554C0',
            900: '#000768',
            800: '#0C1E90',
            700: '#2A39A6',
            600: '#4453BF',
            500: '#5F6DDA',
            400: '#7987F6',
            300: '#98A5FF',
            200: '#BBC3FF',
            100: '#DEE0FF',
            50: '#EFEFFF'
        },
        TERTIARY: {
            DEFAULT: '#006974',
            900: '#001F23',
            800: '#00363C',
            700: '#004F57',
            600: '#006974',
            500: '#008491',
            400: '#00A0AF',
            300: '#20BCCC',
            200: '#4ED8E9',
            100: '#87F2FF',
            50: '#C8F9FF'
        },
        NEUTRALS: {
            DEFAULT: '#5D5E67',
            900: '#1A181E',
            800: '#2E3038',
            700: '#4D4D4D',
            600: '#5D5E67',
            500: '#75767F',
            400: '#8F909A',
            300: '#A9AAB4',
            200: '#C6C6D0',
            100: '#E1E1EC',
            50: '#F0F0FB',
            light: '#FFFFFF'
        }
    },
    SEMANTIC: {
        GREEN: {
            DEFAULT: '#017D19',
            900: '#00523B',
            800: '#00664A',
            700: '#007A59',
            600: '#009069',
            500: '#009E75',
            400: '#03AD87',
            300: '#45BC9C',
            200: '#7DD0B8',
            100: '#B0E2D4',
            50: '#DFF3EE'
        },
        RED: {
            DEFAULT: '#BA1B1B',
            900: '#410001',
            800: '#680003',
            700: '#930006',
            600: '#BA1B1B',
            500: '#DD3730',
            400: '#FF5449',
            300: '#FF897A',
            200: '#FFB4A9',
            100: '#FFDAD4',
            50: '#FFEDE9'
        },
        BLUE: {
            DEFAULT: '#0052CC',
            900: '#233876',
            800: '#1E429F',
            700: '#1A56DB',
            600: '#1C64F2',
            500: '#3F83F8',
            400: '#76A9FA',
            300: '#A4CAFE',
            200: '#C3DDFD',
            100: '#EBF5FF',
            50: '#F5FAFF'
        },
        AMBER: {
            DEFAULT: '#FF8A00',
            900: '#78350F',
            800: '#92400E',
            700: '#B45309',
            600: '#D97706',
            500: '#F59E0B',
            400: '#FBBF24',
            300: '#FCD34D',
            200: '#FDE68A',
            100: '#FEF3C7',
            50: '#FFFBEB'
        }
    }
}

exports.COLORS = COLORS
