import React from 'react'

const IconNext = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.293 8.29303C10.1108 8.48163 10.01 8.73424 10.0123 8.99643C10.0146 9.25863 10.1198 9.50944 10.3052 9.69485C10.4906 9.88026 10.7414 9.98543 11.0036 9.98771C11.2658 9.98998 11.5184 9.88919 11.707 9.70703L15.707 5.70703C15.8945 5.5195 15.9998 5.2652 15.9998 5.00003C15.9998 4.73487 15.8945 4.48056 15.707 4.29303L11.707 0.293031C11.5195 0.10556 11.2652 0.000244141 11 0.000244141C10.7348 0.000244141 10.4805 0.10556 10.293 0.293031C10.1055 0.480558 10.0002 0.734866 10.0002 1.00003C10.0002 1.26519 10.1055 1.5195 10.293 1.70703L12.586 4.00003H1C0.734784 4.00003 0.48043 4.10539 0.292893 4.29292C0.105357 4.48046 0 4.73481 0 5.00003C0 5.26525 0.105357 5.5196 0.292893 5.70714C0.48043 5.89467 0.734784 6.00003 1 6.00003H12.586L10.293 8.29303Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconNext
