import React from 'react'

const IconSearch = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4.00003C6.93913 4.00003 5.92172 4.42146 5.17157 5.17161C4.42143 5.92175 4 6.93917 4 8.00003C4 9.0609 4.42143 10.0783 5.17157 10.8285C5.92172 11.5786 6.93913 12 8 12C9.06087 12 10.0783 11.5786 10.8284 10.8285C11.5786 10.0783 12 9.0609 12 8.00003C12 6.93917 11.5786 5.92175 10.8284 5.17161C10.0783 4.42146 9.06087 4.00003 8 4.00003ZM2 8.00003C1.99988 7.05574 2.22264 6.12475 2.65017 5.28278C3.0777 4.4408 3.69792 3.71163 4.4604 3.15456C5.22287 2.59749 6.10606 2.22825 7.03815 2.07687C7.97023 1.92549 8.92488 1.99625 9.82446 2.28338C10.724 2.57052 11.5432 3.06594 12.2152 3.72933C12.8872 4.39272 13.3931 5.20537 13.6919 6.10117C13.9906 6.99697 14.0737 7.95063 13.9343 8.88459C13.795 9.81855 13.4372 10.7064 12.89 11.476L17.707 16.293C17.8892 16.4816 17.99 16.7342 17.9877 16.9964C17.9854 17.2586 17.8802 17.5094 17.6948 17.6949C17.5094 17.8803 17.2586 17.9854 16.9964 17.9877C16.7342 17.99 16.4816 17.8892 16.293 17.707L11.477 12.891C10.5794 13.5293 9.52335 13.9082 8.42468 13.9862C7.326 14.0641 6.22707 13.8381 5.2483 13.333C4.26953 12.8279 3.44869 12.0631 2.87572 11.1224C2.30276 10.1817 1.99979 9.10147 2 8.00003Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0352 10.9526C11.8847 11.1582 11.7179 11.3532 11.5355 11.5356C10.5979 12.4733 9.32608 13 8 13C6.67392 13 5.40215 12.4733 4.46447 11.5356C3.52683 10.5979 3.00005 9.32624 3 8.00023C3 8.00016 3 8.0001 3 8.00003C3 7.99999 3 7.99995 3 7.99991M2 8.00003L3 8.00023C2.99982 8.91809 3.2523 9.81829 3.72977 10.6022C4.20724 11.3861 4.89127 12.0234 5.70692 12.4444C6.52256 12.8653 7.43834 13.0536 8.3539 12.9887C9.26946 12.9237 10.1495 12.608 10.8975 12.0761L11.5861 11.5864L11.5852 11.5855L12.0352 10.9526M16.293 17.707L11.477 12.891C10.5794 13.5293 9.52335 13.9082 8.42468 13.9862C7.326 14.0641 6.22707 13.8381 5.2483 13.333C4.26953 12.8279 3.44869 12.0631 2.87572 11.1224C2.30276 10.1817 1.99979 9.10147 2 8.00003C1.99988 7.05574 2.22264 6.12475 2.65017 5.28278C3.0777 4.4408 3.69792 3.71163 4.4604 3.15456C5.22287 2.59749 6.10606 2.22825 7.03815 2.07687C7.97023 1.92549 8.92488 1.99625 9.82446 2.28338C10.724 2.57052 11.5432 3.06594 12.2152 3.72933C12.8872 4.39272 13.3931 5.20537 13.6919 6.10117C13.9906 6.99697 14.0737 7.95063 13.9343 8.88459C13.795 9.81855 13.4372 10.7064 12.89 11.476L17.707 16.293C17.8892 16.4816 17.99 16.7342 17.9877 16.9964C17.9854 17.2586 17.8802 17.5094 17.6948 17.6949C17.5094 17.8803 17.2586 17.9854 16.9964 17.9877C16.7342 17.99 16.4816 17.8892 16.293 17.707ZM2 8.00003L3 7.99991L2 8.00003ZM5.17157 5.17161C5.92172 4.42146 6.93913 4.00003 8 4.00003C9.06087 4.00003 10.0783 4.42146 10.8284 5.17161C11.5786 5.92175 12 6.93917 12 8.00003C12 9.0609 11.5786 10.0783 10.8284 10.8285C10.0783 11.5786 9.06087 12 8 12C6.93913 12 5.92172 11.5786 5.17157 10.8285C4.42143 10.0783 4 9.0609 4 8.00003C4 6.93917 4.42143 5.92175 5.17157 5.17161Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconSearch
