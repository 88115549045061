const IconSelector = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9999 3.59998C12.3182 3.60004 12.6233 3.72652 12.8483 3.95158L16.4483 7.55158C16.6669 7.7779 16.7879 8.08102 16.7851 8.39566C16.7824 8.71029 16.6562 9.01127 16.4337 9.23376C16.2112 9.45625 15.9103 9.58245 15.5956 9.58518C15.281 9.58792 14.9779 9.46696 14.7515 9.24837L11.9999 6.49678L9.24834 9.24837C9.02202 9.46696 8.71889 9.58792 8.40426 9.58518C8.08962 9.58245 7.78865 9.45625 7.56616 9.23376C7.34367 9.01127 7.21746 8.71029 7.21473 8.39566C7.212 8.08102 7.33295 7.7779 7.55154 7.55158L11.1515 3.95158C11.3765 3.72652 11.6817 3.60004 11.9999 3.59998ZM7.55154 14.7516C7.77657 14.5266 8.08174 14.4002 8.39994 14.4002C8.71814 14.4002 9.02331 14.5266 9.24834 14.7516L11.9999 17.5032L14.7515 14.7516C14.9779 14.533 15.281 14.412 15.5956 14.4148C15.9103 14.4175 16.2112 14.5437 16.4337 14.7662C16.6562 14.9887 16.7824 15.2897 16.7851 15.6043C16.7879 15.9189 16.6669 16.2221 16.4483 16.4484L12.8483 20.0484C12.6233 20.2733 12.3181 20.3997 11.9999 20.3997C11.6817 20.3997 11.3766 20.2733 11.1515 20.0484L7.55154 16.4484C7.32657 16.2233 7.2002 15.9182 7.2002 15.6C7.2002 15.2818 7.32657 14.9766 7.55154 14.7516Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.55154 7.55158C7.33295 7.7779 7.212 8.08102 7.21473 8.39566C7.21746 8.71029 7.34367 9.01127 7.56616 9.23376C7.78865 9.45625 8.08962 9.58245 8.40426 9.58518C8.71889 9.58792 9.02202 9.46696 9.24834 9.24837L11.9999 6.49678L14.7515 9.24837C14.9779 9.46696 15.281 9.58792 15.5956 9.58518C15.9103 9.58245 16.2112 9.45625 16.4337 9.23376C16.6562 9.01127 16.7824 8.71029 16.7851 8.39566C16.7879 8.08102 16.6669 7.7779 16.4483 7.55158L12.8483 3.95158C12.6233 3.72652 12.3182 3.60004 11.9999 3.59998C11.6817 3.60004 11.3765 3.72652 11.1515 3.95158L7.55154 7.55158ZM9.24834 14.7516C9.02331 14.5266 8.71814 14.4002 8.39994 14.4002C8.08174 14.4002 7.77657 14.5266 7.55154 14.7516C7.32657 14.9766 7.2002 15.2818 7.2002 15.6C7.2002 15.9182 7.32657 16.2233 7.55154 16.4484L11.1515 20.0484C11.3766 20.2733 11.6817 20.3997 11.9999 20.3997C12.3181 20.3997 12.6233 20.2733 12.8483 20.0484L16.4483 16.4484C16.6669 16.2221 16.7879 15.9189 16.7851 15.6043C16.7824 15.2897 16.6562 14.9887 16.4337 14.7662C16.2112 14.5437 15.9103 14.4175 15.5956 14.4148C15.281 14.412 14.9779 14.533 14.7515 14.7516L11.9999 17.5032L9.24834 14.7516Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconSelector
