const IconOpenFullscreen = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-inherit h-inherit">
            <path
                d="M110.391 945.609V626.391h79.218V810.26L714.26 285.609H530.391v-79.218h319.218v319.218h-79.218V341.74L245.74 866.391h183.869v79.218H110.391Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconOpenFullscreen
