import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const config: FirebaseOptions = {
    apiKey: 'AIzaSyAZCxo19CvK7wiIe8k7lRDaAzjDRl2hTTY',
    authDomain: 'simply-cbd7a.firebaseapp.com',
    projectId: 'simply-cbd7a'
}

const app = initializeApp(config)

export const firebaseAuth = getAuth()
