const featuresPay = {
    featureTitle: 'தரவை அடிப்படையாகக் கொண்டு வணிக முடிவுகளை எடுக்கவும்',
    featureDescription:
        'எந்த பொருட்கள் அதிகம் விற்பனையாகின்றன மற்றும் அதிக லாபத்தை வழங்குகின்றன என்பதை அறிந்து, எப்போது, எங்கேனும் நிதி அறிக்கைகளை அணுகவும். உங்கள் வணிகம் உங்கள் கைக்குள்!',
    featureButton: 'அப்பில் இருந்து பிரீமியம் சந்தா',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'தொடக்கம்',
    featureEndingText: 'ஆயிரம்',

    features: [
        {
            title: 'எல்லையற்ற பொருட்கள்',
            description: 'எல்லாத் தரவுகளையும் எந்த கட்டுப்பாடும் இன்றி சேர்க்கவும்.'
        },
        {
            title: 'எல்லையற்ற ஸ்டாஃப்',
            description: 'ஸ்டாஃப்/நிர்வாகியை எந்தக் கட்டுப்பாடும் இன்றி வணிகத்திற்கு சேர்க்கவும்.'
        },
        {
            title: 'எல்லையற்ற வரலாறு',
            description: 'பழைய பங்கு பதிவுகளில் மாற்றங்களை எந்தக் கட்டுப்பாடும் இன்றி பார்க்கவும்.'
        },
        {
            title: 'வரலாறு வடிகட்டி',
            description: 'வகை, பிரிவு, ஸ்டாஃப் மற்றும் தேதியை அடிப்படையாகக் கொண்டு வரலாற்றைப் பார்க்கவும்.'
        },
        {
            title: 'எக்செலுக்கு பதிவிறக்கவும்',
            description: 'ஒரே கிளிக்கில் பங்கு பதிவுகள் வரலாற்றை Microsoft Excel-க்கு எடுங்கள்.'
        },
        {
            title: 'PDF-க்கு பதிவிறக்கவும்',
            description: 'புதிய பொருட்கள் மற்றும் பங்கு பட்டியலை ஒரே கிளிக்கில் PDF-ஆக எடுங்கள்.'
        }
    ]
}

export default featuresPay
