import { Dispatch, SetStateAction, useEffect } from 'react'

export const useGetDeviceWidth = (setter: Dispatch<SetStateAction<number>>, callback?: () => void) => {
    let timeoutEvent: ReturnType<typeof setTimeout> | undefined = undefined

    useEffect(() => {
        checkWidth()
        window.addEventListener('resize', checkWidth)

        return () => {
            window.removeEventListener('resize', checkWidth)
        }
    }, [])

    const checkWidth = () => {
        if (timeoutEvent) {
            clearTimeout(timeoutEvent)
        }

        timeoutEvent = setTimeout(() => {
            setter(window.innerWidth)
            if (callback) {
                callback()
            }
        }, 50)
    }
}
