const IconChart = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.40039 13.2C2.40039 12.8817 2.52682 12.5765 2.75186 12.3514C2.97691 12.1264 3.28213 12 3.60039 12H6.00039C6.31865 12 6.62387 12.1264 6.84892 12.3514C7.07396 12.5765 7.20039 12.8817 7.20039 13.2V19.2C7.20039 19.5182 7.07396 19.8235 6.84892 20.0485C6.62387 20.2735 6.31865 20.4 6.00039 20.4H3.60039C3.28213 20.4 2.97691 20.2735 2.75186 20.0485C2.52682 19.8235 2.40039 19.5182 2.40039 19.2V13.2ZM9.60039 8.39998C9.60039 8.08172 9.72682 7.77649 9.95186 7.55145C10.1769 7.3264 10.4821 7.19998 10.8004 7.19998H13.2004C13.5187 7.19998 13.8239 7.3264 14.0489 7.55145C14.274 7.77649 14.4004 8.08172 14.4004 8.39998V19.2C14.4004 19.5182 14.274 19.8235 14.0489 20.0485C13.8239 20.2735 13.5187 20.4 13.2004 20.4H10.8004C10.4821 20.4 10.1769 20.2735 9.95186 20.0485C9.72682 19.8235 9.60039 19.5182 9.60039 19.2V8.39998ZM16.8004 4.79998C16.8004 4.48172 16.9268 4.17649 17.1519 3.95145C17.3769 3.7264 17.6821 3.59998 18.0004 3.59998H20.4004C20.7186 3.59998 21.0239 3.7264 21.2489 3.95145C21.474 4.17649 21.6004 4.48172 21.6004 4.79998V19.2C21.6004 19.5182 21.474 19.8235 21.2489 20.0485C21.0239 20.2735 20.7186 20.4 20.4004 20.4H18.0004C17.6821 20.4 17.3769 20.2735 17.1519 20.0485C16.9268 19.8235 16.8004 19.5182 16.8004 19.2V4.79998Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00039 13.2H3.60039V19.2H6.00039V13.2ZM13.2004 8.39998H10.8004V19.2H13.2004V8.39998ZM20.4004 4.79998H18.0004V19.2H20.4004V4.79998ZM2.75186 12.3514C2.52682 12.5765 2.40039 12.8817 2.40039 13.2V19.2C2.40039 19.5182 2.52682 19.8235 2.75186 20.0485C2.97691 20.2735 3.28213 20.4 3.60039 20.4H6.00039C6.31865 20.4 6.62387 20.2735 6.84892 20.0485C7.07396 19.8235 7.20039 19.5182 7.20039 19.2V13.2C7.20039 12.8817 7.07396 12.5765 6.84892 12.3514C6.62387 12.1264 6.31865 12 6.00039 12H3.60039C3.28213 12 2.97691 12.1264 2.75186 12.3514ZM9.95186 7.55145C9.72682 7.77649 9.60039 8.08172 9.60039 8.39998V19.2C9.60039 19.5182 9.72682 19.8235 9.95186 20.0485C10.1769 20.2735 10.4821 20.4 10.8004 20.4H13.2004C13.5187 20.4 13.8239 20.2735 14.0489 20.0485C14.274 19.8235 14.4004 19.5182 14.4004 19.2V8.39998C14.4004 8.08172 14.274 7.77649 14.0489 7.55145C13.8239 7.3264 13.5187 7.19998 13.2004 7.19998H10.8004C10.4821 7.19998 10.1769 7.3264 9.95186 7.55145ZM17.1519 3.95145C16.9268 4.17649 16.8004 4.48172 16.8004 4.79998V19.2C16.8004 19.5182 16.9268 19.8235 17.1519 20.0485C17.3769 20.2735 17.6821 20.4 18.0004 20.4H20.4004C20.7186 20.4 21.0239 20.2735 21.2489 20.0485C21.474 19.8235 21.6004 19.5182 21.6004 19.2V4.79998C21.6004 4.48172 21.474 4.17649 21.2489 3.95145C21.0239 3.7264 20.7186 3.59998 20.4004 3.59998H18.0004C17.6821 3.59998 17.3769 3.7264 17.1519 3.95145Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconChart
