import React from 'react'

const IconX = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.15115 5.15162C5.37618 4.92666 5.68135 4.80028 5.99955 4.80028C6.31775 4.80028 6.62291 4.92666 6.84795 5.15162L11.9995 10.3032L17.1511 5.15162C17.2618 5.03701 17.3943 4.94559 17.5407 4.8827C17.6871 4.81981 17.8445 4.78671 18.0039 4.78532C18.1632 4.78394 18.3212 4.8143 18.4687 4.87464C18.6162 4.93497 18.7502 5.02408 18.8628 5.13675C18.9755 5.24942 19.0646 5.3834 19.1249 5.53088C19.1853 5.67835 19.2156 5.83637 19.2142 5.99571C19.2129 6.15504 19.1798 6.3125 19.1169 6.45891C19.054 6.60531 18.9626 6.73773 18.8479 6.84842L13.6963 12L18.8479 17.1516C19.0665 17.3779 19.1875 17.6811 19.1848 17.9957C19.182 18.3103 19.0558 18.6113 18.8333 18.8338C18.6108 19.0563 18.3099 19.1825 17.9952 19.1852C17.6806 19.188 17.3775 19.067 17.1511 18.8484L11.9995 13.6968L6.84795 18.8484C6.62163 19.067 6.3185 19.188 6.00387 19.1852C5.68923 19.1825 5.38826 19.0563 5.16577 18.8338C4.94328 18.6113 4.81707 18.3103 4.81434 17.9957C4.81161 17.6811 4.93256 17.3779 5.15115 17.1516L10.3027 12L5.15115 6.84842C4.92618 6.62339 4.7998 6.31822 4.7998 6.00002C4.7998 5.68183 4.92618 5.37666 5.15115 5.15162Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.84795 5.15162C6.62291 4.92666 6.31775 4.80028 5.99955 4.80028C5.68135 4.80028 5.37618 4.92666 5.15115 5.15162C4.92618 5.37666 4.7998 5.68183 4.7998 6.00002C4.7998 6.31822 4.92618 6.62339 5.15115 6.84842L10.3027 12L5.15115 17.1516C4.93256 17.3779 4.81161 17.6811 4.81434 17.9957C4.81707 18.3103 4.94328 18.6113 5.16577 18.8338C5.38826 19.0563 5.68923 19.1825 6.00387 19.1852C6.3185 19.188 6.62163 19.067 6.84795 18.8484L11.9995 13.6968L17.1511 18.8484C17.3775 19.067 17.6806 19.188 17.9952 19.1852C18.3099 19.1825 18.6108 19.0563 18.8333 18.8338C19.0558 18.6113 19.182 18.3103 19.1848 17.9957C19.1875 17.6811 19.0665 17.3779 18.8479 17.1516L13.6963 12L18.8479 6.84842C18.9626 6.73773 19.054 6.60531 19.1169 6.45891C19.1798 6.3125 19.2129 6.15504 19.2142 5.99571C19.2156 5.83637 19.1853 5.67835 19.1249 5.53088C19.0646 5.3834 18.9755 5.24942 18.8628 5.13675C18.7502 5.02408 18.6162 4.93497 18.4687 4.87464C18.3212 4.8143 18.1632 4.78394 18.0039 4.78532C17.8445 4.78671 17.6871 4.81981 17.5407 4.8827C17.3943 4.94559 17.2618 5.03701 17.1511 5.15162L11.9995 10.3032L6.84795 5.15162Z"
                fill="currentColor"
            />
        </svg>
    )
}
export default IconX
