const IconTranslate = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_302_8331)">
                <path
                    d="M19.9999 2H9.33325V5.33333H10.6666V3.33333H19.9999C20.3999 3.33333 20.6666 3.6 20.6666 4V11.3333C20.6666 11.7333 20.3999 12 19.9999 12H11.3333V16.6667H7.79992L5.33325 18.6V16.6667H3.33325C2.93325 16.6667 2.66659 16.4 2.66659 16V8.66667C2.66659 8.26667 2.93325 8 3.33325 8H11.9999V6.66667H3.33325C2.19992 6.66667 1.33325 7.53333 1.33325 8.66667V16C1.33325 17.1333 2.19992 18 3.33325 18H3.99992V21.4L8.19992 18H12.6666V13.3333H19.9999C21.1333 13.3333 21.9999 12.4667 21.9999 11.3333V4C21.9999 2.86667 21.1333 2 19.9999 2Z"
                    fill="currentColor"
                />
                <path
                    d="M4.1333 15.2668H5.7333L6.1333 14.2002H8.19997L8.59997 15.2668H10.2L7.9333 9.3335H6.3333L4.1333 15.2668ZM7.1333 11.0002L7.79997 13.0668H6.46663L7.1333 11.0002Z"
                    fill="currentColor"
                />
                <path
                    d="M13.3333 11.3333C14.0666 11.3333 15.0666 11.1333 15.9999 10.6667C16.9333 11.1333 17.9999 11.3333 18.6666 11.3333V10C18.6666 10 17.9999 10 17.2666 9.73333C18.0666 8.93333 18.6666 7.73333 18.6666 6V5.33333H16.6666V4H15.3333V5.33333H13.3333V6.66667H17.2666C17.1333 7.86667 16.5999 8.6 15.9999 9.06667C15.5999 8.73333 15.1999 8.26667 14.9333 7.66667H13.5333C13.7999 8.53333 14.1999 9.2 14.7333 9.73333C14.0666 10 13.4666 10 13.3333 10V11.3333Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_302_8331">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconTranslate
