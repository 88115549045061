const featuresPay = {
    featureTitle: 'આંકડાના આધારે વ્યાપારિક નિર્ણયો લો',
    featureDescription:
        'જાણો કે કયા વસ્તુઓ વધુ વેચાઇ રહી છે અને વધુ નફો આપી રહી છે, અને કોઈપણ સમયે ક્યાંયથી પણ નાણાકીય અહેવાલો મેળવો. તમારો વ્યાપાર તમારા હાથમાં!',
    featureButton: 'એપમાંથી પ્રીમિયમ સબ્સ્ક્રિપ્શન',
    featureStartingPrice: '૮૦',
    featureEndingPart: '૩૦',
    featureStaringText: 'શરૂઆતથી',
    featureEndingText: 'હજાર',

    features: [
        {
            title: 'અનલિમિટેડ આઇટમ્સ',
            description: 'તમારું તમામ માલ કોઈ મર્યાદા વિના ઉમેરો.'
        },
        {
            title: 'અનલિમિટેડ સ્ટાફ',
            description: 'તમારા બિઝનેસમાં સ્ટાફ/એડમિન કોઈ મર્યાદા વિના ઉમેરો.'
        },
        {
            title: 'અનલિમિટેડ ઇતિહાસ',
            description: 'જૂના સ્ટોક રેકોર્ડ્સમાં ફેરફારો કોઈ મર્યાદા વિના જુઓ.'
        },
        {
            title: 'ઇતિહાસ ફિલ્ટર',
            description: 'પ્રકાર, કેટેગરી, સ્ટાફ અને તારીખના આધારે ઇતિહાસ સરળતાથી જુઓ.'
        },
        {
            title: 'એક્સેલમાં ડાઉનલોડ કરો',
            description: 'માત્ર એક ક્લિકથી સ્ટોક રેકોર્ડ ઇતિહાસને માઇક્રોસોફ્ટ એક્સેલમાં મેળવો.'
        },
        {
            title: 'પીડીએફમાં ડાઉનલોડ કરો',
            description: 'માત્ર એક ક્લિકથી તાજેતરના આઇટમ્સ અને સ્ટોક યાદીને પીડીએફમાં મેળવો.'
        }
    ]
}

export default featuresPay
