import React from 'react'

const IconChevronDown = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29279 7.29302C5.48031 7.10555 5.73462 7.00023 5.99979 7.00023C6.26495 7.00023 6.51926 7.10555 6.70679 7.29302L9.99979 10.586L13.2928 7.29302C13.385 7.19751 13.4954 7.12133 13.6174 7.06892C13.7394 7.01651 13.8706 6.98892 14.0034 6.98777C14.1362 6.98662 14.2678 7.01192 14.3907 7.0622C14.5136 7.11248 14.6253 7.18673 14.7192 7.28062C14.8131 7.37452 14.8873 7.48617 14.9376 7.60907C14.9879 7.73196 15.0132 7.86364 15.012 7.99642C15.0109 8.1292 14.9833 8.26042 14.9309 8.38242C14.8785 8.50443 14.8023 8.61477 14.7068 8.70702L10.7068 12.707C10.5193 12.8945 10.265 12.9998 9.99979 12.9998C9.73462 12.9998 9.48031 12.8945 9.29279 12.707L5.29279 8.70702C5.10532 8.51949 5 8.26518 5 8.00002C5 7.73486 5.10532 7.48055 5.29279 7.29302Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.70679 7.29302C6.51926 7.10555 6.26495 7.00023 5.99979 7.00023C5.73462 7.00023 5.48031 7.10555 5.29279 7.29302C5.10532 7.48055 5 7.73486 5 8.00002C5 8.26518 5.10532 8.51949 5.29279 8.70702L9.29279 12.707C9.48031 12.8945 9.73462 12.9998 9.99979 12.9998C10.265 12.9998 10.5193 12.8945 10.7068 12.707L14.7068 8.70702C14.8023 8.61477 14.8785 8.50443 14.9309 8.38242C14.9833 8.26042 15.0109 8.1292 15.012 7.99642C15.0132 7.86364 14.9879 7.73196 14.9376 7.60907C14.8873 7.48617 14.8131 7.37452 14.7192 7.28062C14.6253 7.18673 14.5136 7.11248 14.3907 7.0622C14.2678 7.01192 14.1362 6.98662 14.0034 6.98777C13.8706 6.98892 13.7394 7.01651 13.6174 7.06892C13.4954 7.12133 13.385 7.19751 13.2928 7.29302L9.99979 10.586L6.70679 7.29302Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconChevronDown
