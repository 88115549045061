const IconWand = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_302_6868)">
                <path
                    d="M2.19463 23.794C2.46917 24.0686 2.91439 24.0686 3.18898 23.794L12.4824 14.5006L12.6288 15.728C12.6628 16.0135 12.8673 16.2495 13.145 16.324C13.4221 16.3982 13.7174 16.2966 13.89 16.066L16.2383 12.927L19.8416 14.4713C20.1061 14.5846 20.4125 14.5255 20.6158 14.3222C20.8191 14.1189 20.8781 13.8123 20.7649 13.548L19.2206 9.94477L22.3597 7.5964C22.5899 7.42418 22.6921 7.1291 22.6177 6.85142C22.5432 6.57368 22.3073 6.36926 22.0217 6.33523L18.1291 5.87099L17.6648 1.97832C17.6308 1.69281 17.4263 1.45684 17.1486 1.3824C16.8708 1.30797 16.5758 1.41015 16.4036 1.64035L14.0552 4.77937L10.452 3.23508C10.1876 3.12178 9.88105 3.18089 9.6778 3.38419C9.47451 3.58748 9.41545 3.89409 9.5287 4.15832L11.073 7.76158L7.93392 10.11C7.70372 10.2822 7.60153 10.5773 7.67592 10.8549C7.75036 11.1327 7.98633 11.3371 8.27184 11.3711L9.4993 11.5175L0.205874 20.8109C-0.0685309 21.0853 -0.0687183 21.5307 0.205874 21.8053L2.19463 23.794ZM12.3583 8.55616C12.6183 8.36172 12.7113 8.01457 12.5834 7.71616L11.5137 5.22004L14.0098 6.28981C14.3081 6.41769 14.6553 6.32469 14.8498 6.06472L16.4766 3.8902L16.7982 6.58681C16.8366 6.90912 17.0907 7.16328 17.4131 7.20171L20.1096 7.52332L17.9351 9.15015C17.6751 9.34459 17.5821 9.69174 17.71 9.99015L18.7798 12.4863L16.2836 11.4165C15.9852 11.2886 15.6381 11.3816 15.4436 11.6416L13.8168 13.8161L13.4953 11.1195C13.4568 10.7971 13.2027 10.543 12.8804 10.5046L10.1838 10.183L12.3583 8.55616ZM11.276 11.7294L12.1645 11.8354L12.2704 12.7238L2.69178 22.3025L1.69738 21.3081L11.276 11.7294Z"
                    fill="currentColor"
                />
                <path
                    d="M20.6156 2.38954L19.6212 3.3839C19.3466 3.65849 19.3466 4.10366 19.6212 4.37826C19.8958 4.65285 20.341 4.65285 20.6156 4.37826L21.6099 3.3839C21.8845 3.1093 21.8845 2.66413 21.6099 2.38954C21.3354 2.11495 20.8902 2.11495 20.6156 2.38954Z"
                    fill="currentColor"
                />
                <path
                    d="M12.7811 0.0241008C12.406 0.124601 12.1834 0.510148 12.2839 0.885241L12.6479 2.24359C12.7481 2.61765 13.133 2.84157 13.509 2.74074C13.8842 2.64024 14.1067 2.25469 14.0062 1.8796L13.6422 0.521257C13.5417 0.146163 13.1562 -0.0763054 12.7811 0.0241008Z"
                    fill="currentColor"
                />
                <path
                    d="M16.7845 14.9655C16.4094 15.066 16.1868 15.4516 16.2873 15.8267L16.6513 17.185C16.7517 17.5596 17.137 17.7829 17.5124 17.6822C17.8876 17.5817 18.1101 17.1961 18.0096 16.821L17.6457 15.4627C17.5451 15.0876 17.1596 14.8651 16.7845 14.9655Z"
                    fill="currentColor"
                />
                <path
                    d="M7.17873 5.98995C6.80415 5.88945 6.41809 6.11202 6.31759 6.48711C6.21709 6.8622 6.4397 7.24775 6.81479 7.34825L8.17313 7.71219C8.54912 7.81297 8.93401 7.58914 9.03427 7.21498C9.13477 6.83989 8.91216 6.45434 8.53707 6.35384L7.17873 5.98995Z"
                    fill="currentColor"
                />
                <path
                    d="M23.4788 10.3578L22.1204 9.99384C21.7456 9.89339 21.3598 10.1159 21.2592 10.491C21.1587 10.8661 21.3813 11.2516 21.7564 11.3521L23.1147 11.7161C23.4914 11.8171 23.8758 11.5925 23.9759 11.219C24.0764 10.8439 23.8538 10.4583 23.4788 10.3578Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_302_6868">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconWand
