import { SpacingMap } from '../types'

export const paddingSizeMap: SpacingMap = {
    xs: {
        left: 'pl-2',
        top: 'pt-2',
        right: 'pr-2',
        bottom: 'pb-2'
    },
    sm: {
        left: 'pl-3',
        top: 'pt-3',
        right: 'pr-3',
        bottom: 'pb-3'
    },
    md: {
        left: 'pl-4',
        top: 'pt-4',
        right: 'pr-4',
        bottom: 'pb-4'
    },
    lg: {
        left: 'pl-5',
        top: 'pt-5',
        right: 'pr-5',
        bottom: 'pb-5'
    },
    xl: {
        left: 'pl-6',
        top: 'pt-6',
        right: 'pr-6',
        bottom: 'pb-6'
    }
}

export const responsiveBreakpointMap = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
}
