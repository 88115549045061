const IconExpand = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-inherit h-inherit">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M190.391 865.609V606.391h79.218v180h180v79.218H190.391Zm500-320v-180h-180v-79.218h259.218v259.218h-79.218Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconExpand
