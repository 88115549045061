import React from 'react'

const IconLockClosed = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99961 10.8V8.40002C5.99961 6.80873 6.63175 5.2826 7.75697 4.15738C8.88219 3.03217 10.4083 2.40002 11.9996 2.40002C13.5909 2.40002 15.117 3.03217 16.2423 4.15738C17.3675 5.2826 17.9996 6.80873 17.9996 8.40002V10.8C18.6361 10.8 19.2466 11.0529 19.6967 11.503C20.1468 11.9531 20.3996 12.5635 20.3996 13.2V19.2C20.3996 19.8365 20.1468 20.447 19.6967 20.8971C19.2466 21.3472 18.6361 21.6 17.9996 21.6H5.99961C5.36309 21.6 4.75264 21.3472 4.30255 20.8971C3.85247 20.447 3.59961 19.8365 3.59961 19.2V13.2C3.59961 12.5635 3.85247 11.9531 4.30255 11.503C4.75264 11.0529 5.36309 10.8 5.99961 10.8V10.8ZM15.5996 8.40002V10.8H8.39961V8.40002C8.39961 7.44524 8.77889 6.52957 9.45403 5.85444C10.1292 5.17931 11.0448 4.80002 11.9996 4.80002C12.9544 4.80002 13.8701 5.17931 14.5452 5.85444C15.2203 6.52957 15.5996 7.44524 15.5996 8.40002V8.40002Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9996 12H7.19961V10.8H5.99961V8.40002C5.99961 6.80873 6.63175 5.2826 7.75697 4.15738C8.88219 3.03217 10.4083 2.40002 11.9996 2.40002C13.5909 2.40002 15.117 3.03217 16.2423 4.15738C17.3675 5.2826 17.9996 6.80873 17.9996 8.40002V10.8C18.6361 10.8 19.2466 11.0529 19.6967 11.503C20.1468 11.9531 20.3996 12.5635 20.3996 13.2V19.2C20.3996 19.8365 20.1468 20.447 19.6967 20.8971C19.2466 21.3472 18.6361 21.6 17.9996 21.6H5.99961C5.36309 21.6 4.75264 21.3472 4.30255 20.8971C3.85247 20.447 3.59961 19.8365 3.59961 19.2V13.2C3.59961 12.5635 3.85247 11.9531 4.30255 11.503C4.75264 11.0529 5.36309 10.8 5.99961 10.8V12C5.68135 12 5.37613 12.1265 5.15108 12.3515C4.92604 12.5765 4.79961 12.8818 4.79961 13.2V19.2C4.79961 19.5183 4.92604 19.8235 5.15108 20.0486C5.37613 20.2736 5.68135 20.4 5.99961 20.4H17.9996C18.3179 20.4 18.6231 20.2736 18.8481 20.0486C19.0732 19.8235 19.1996 19.5183 19.1996 19.2V13.2C19.1996 12.8818 19.0732 12.5765 18.8481 12.3515C18.6231 12.1265 18.3179 12 17.9996 12ZM15.5996 8.40002C15.5996 7.44524 15.2203 6.52957 14.5452 5.85444C13.8701 5.17931 12.9544 4.80002 11.9996 4.80002C11.0448 4.80002 10.1292 5.17931 9.45403 5.85444C8.77889 6.52957 8.39961 7.44524 8.39961 8.40002V10.8H15.5996V8.40002Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconLockClosed
