const IconStockOut = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.559 7.938L28.0605 3.4395C27.9214 3.29994 27.7562 3.18926 27.5741 3.11383C27.3921 3.0384 27.197 2.99972 27 3H9C8.80298 2.99972 8.60786 3.0384 8.42585 3.11383C8.24385 3.18926 8.07856 3.29994 7.9395 3.4395L3.441 7.938C3.30085 8.07701 3.1897 8.24247 3.114 8.42478C3.0383 8.60708 2.99955 8.8026 3 9V28.5C3 30.1545 4.3455 31.5 6 31.5H30C31.6545 31.5 33 30.1545 33 28.5V9C33.0005 8.8026 32.9617 8.60708 32.886 8.42478C32.8103 8.24247 32.6991 8.07701 32.559 7.938ZM9.621 6H26.379L27.879 7.5H8.121L9.621 6ZM6 28.5V10.5H30L30.003 28.5H6Z"
                fill="currentColor"
            />
            <path d="M21 25.5H15V21H10.5L18 13.5L25.5 21H21V25.5Z" fill="currentColor" />
        </svg>
    )
}

export default IconStockOut
