import { useEffect, useRef } from 'react'

export function useInterval<T extends Function>(
    callback: T,
    { interval, lead }: { interval: number; lead?: boolean }
): void {
    const savedCallback = useRef<T>(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = (): void => savedCallback.current()

        lead && tick()

        if (interval !== null) {
            const id = setInterval(tick, interval)

            return () => clearInterval(id)
        }
    }, [interval])
}
