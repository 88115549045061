const IconSwitchVertical = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.00029 14.4C6.00029 14.7183 6.12672 15.0235 6.35177 15.2486C6.57681 15.4736 6.88203 15.6 7.20029 15.6C7.51855 15.6 7.82378 15.4736 8.04882 15.2486C8.27386 15.0235 8.40029 14.7183 8.40029 14.4V7.69682L9.95189 9.24842C10.1782 9.46701 10.4813 9.58797 10.796 9.58523C11.1106 9.5825 11.4116 9.4563 11.6341 9.23381C11.8566 9.01132 11.9828 8.71034 11.9855 8.39571C11.9882 8.08107 11.8673 7.77795 11.6487 7.55162L8.04869 3.95162C7.82366 3.72666 7.51849 3.60028 7.20029 3.60028C6.8821 3.60028 6.57693 3.72666 6.35189 3.95162L2.75189 7.55162C2.5333 7.77795 2.41235 8.08107 2.41508 8.39571C2.41782 8.71034 2.54402 9.01132 2.76651 9.23381C2.989 9.4563 3.28998 9.5825 3.60461 9.58523C3.91925 9.58797 4.22237 9.46701 4.44869 9.24842L6.00029 7.69682V14.4ZM18.0003 9.60002C18.0003 9.28176 17.8739 8.97654 17.6488 8.7515C17.4238 8.52645 17.1186 8.40002 16.8003 8.40002C16.482 8.40002 16.1768 8.52645 15.9518 8.7515C15.7267 8.97654 15.6003 9.28176 15.6003 9.60002V16.3032L14.0487 14.7516C13.8224 14.533 13.5192 14.4121 13.2046 14.4148C12.89 14.4175 12.589 14.5438 12.3665 14.7662C12.144 14.9887 12.0178 15.2897 12.0151 15.6043C12.0124 15.919 12.1333 16.2221 12.3519 16.4484L15.9519 20.0484C16.1769 20.2734 16.4821 20.3998 16.8003 20.3998C17.1185 20.3998 17.4237 20.2734 17.6487 20.0484L21.2487 16.4484C21.4673 16.2221 21.5882 15.919 21.5855 15.6043C21.5828 15.2897 21.4566 14.9887 21.2341 14.7662C21.0116 14.5438 20.7106 14.4175 20.396 14.4148C20.0813 14.4121 19.7782 14.533 19.5519 14.7516L18.0003 16.3032V9.60002Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.04869 3.95162L11.6487 7.55162C11.8673 7.77795 11.9882 8.08107 11.9855 8.39571C11.9828 8.71034 11.8566 9.01132 11.6341 9.23381C11.4116 9.4563 11.1106 9.5825 10.796 9.58523C10.4813 9.58797 10.1782 9.46701 9.95189 9.24842L8.40029 7.69682V14.4C8.40029 14.7183 8.27386 15.0235 8.04882 15.2486C7.82378 15.4736 7.51855 15.6 7.20029 15.6C6.88203 15.6 6.57681 15.4736 6.35177 15.2486C6.12672 15.0235 6.00029 14.7183 6.00029 14.4V7.69682L4.44869 9.24842C4.22237 9.46701 3.91925 9.58797 3.60461 9.58523C3.28998 9.5825 2.989 9.4563 2.76651 9.23381C2.54402 9.01132 2.41782 8.71034 2.41508 8.39571C2.41235 8.08107 2.5333 7.77795 2.75189 7.55162L6.35189 3.95162C6.57693 3.72666 6.8821 3.60028 7.20029 3.60028C7.51849 3.60028 7.82366 3.72666 8.04869 3.95162ZM15.9519 20.0484L12.3519 16.4484C12.1333 16.2221 12.0124 15.919 12.0151 15.6043C12.0178 15.2897 12.144 14.9887 12.3665 14.7662C12.589 14.5438 12.89 14.4175 13.2046 14.4148C13.5192 14.4121 13.8224 14.533 14.0487 14.7516L15.6003 16.3032V9.60002C15.6003 9.28176 15.7267 8.97654 15.9518 8.7515C16.1768 8.52645 16.482 8.40002 16.8003 8.40002C17.1186 8.40002 17.4238 8.52645 17.6488 8.7515C17.8739 8.97654 18.0003 9.28176 18.0003 9.60002V16.3032L19.5519 14.7516C19.7782 14.533 20.0813 14.4121 20.396 14.4148C20.7106 14.4175 21.0116 14.5438 21.2341 14.7662C21.4566 14.9887 21.5828 15.2897 21.5855 15.6043C21.5882 15.919 21.4673 16.2221 21.2487 16.4484L17.6487 20.0484C17.4237 20.2734 17.1185 20.3998 16.8003 20.3998C16.4821 20.3998 16.1769 20.2734 15.9519 20.0484Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconSwitchVertical
