// redux/slices/languageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LanguageState } from '../type'

const getInitialLanguage = (): string => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem('language') || 'en' // Default value if not set
    }
    return 'en' // Default value for server-side rendering
}

const initialState: LanguageState = {
    language: getInitialLanguage()
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload
            if (typeof window !== 'undefined') {
                localStorage.setItem('language', action.payload) // Save to localStorage
            }
        }
    }
})

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer
