export * from './countries'
export * from './navigationMenu'
export * from './size'

const ENV_STATE = process.env.NEXT_PUBLIC_ENV

export const ENV = {
    ENV_STATE
}

export const IS_PROD = ENV_STATE === 'prod'
export const IS_LOCAL = ENV_STATE === 'local'
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
export const API_BASE_URL_V2 = process.env.NEXT_PUBLIC_API_BASE_URL_V2
export const AUTH_API_BASE_URL = process.env.NEXT_PUBLIC_AUTH_API_BASE_URL
export const OAUTH_API_BASE_URL = process.env.NEXT_PUBLIC_OAUTH_API_BASE_URL
export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY
export const MIXPANEL_API_KEY = process.env.NEXT_PUBLIC_MIXPANEL_API_KEY
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
