import ar from './ar'
import bn from './bn'
import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import gu from './gu'
import hi from './hi'
import id from './id'
import kn from './kn'
import ml from './ml'
import mr from './mr'
import ta from './ta'
import te from './te'
import ur from './ur'

const locale = {
    id,
    en,
    hi,
    gu,
    mr,
    es,
    de,
    te,
    kn,
    bn,
    ml,
    ta,
    ur,
    ar,
    fr
}

export default locale
