import React from 'react'

const IconLocationMarker = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.04953 4.04999C6.36235 2.73717 8.14292 1.99963 9.99953 1.99963C11.8561 1.99963 13.6367 2.73717 14.9495 4.04999C16.2623 5.36281 16.9999 7.14338 16.9999 8.99999C16.9999 10.8566 16.2623 12.6372 14.9495 13.95L9.99953 18.9L5.04953 13.95C4.39944 13.3 3.88376 12.5283 3.53193 11.6789C3.18011 10.8296 2.99902 9.9193 2.99902 8.99999C2.99902 8.08068 3.18011 7.17037 3.53193 6.32104C3.88376 5.47172 4.39944 4.70001 5.04953 4.04999ZM9.99953 11C10.53 11 11.0387 10.7893 11.4137 10.4142C11.7888 10.0391 11.9995 9.53042 11.9995 8.99999C11.9995 8.46956 11.7888 7.96085 11.4137 7.58578C11.0387 7.2107 10.53 6.99999 9.99953 6.99999C9.4691 6.99999 8.96039 7.2107 8.58532 7.58578C8.21024 7.96085 7.99953 8.46956 7.99953 8.99999C7.99953 9.53042 8.21024 10.0391 8.58532 10.4142C8.96039 10.7893 9.4691 11 9.99953 11Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.75664 13.2429L9.99953 17.4858L14.2424 13.2429C15.3677 12.1176 15.9999 10.5914 15.9999 8.99999C15.9999 7.4086 15.3677 5.88238 14.2424 4.7571C13.1171 3.63181 11.5909 2.99963 9.99953 2.99963C8.40813 2.99963 6.88192 3.63181 5.75664 4.7571L5.04953 4.04999C6.36235 2.73717 8.14292 1.99963 9.99953 1.99963C11.8561 1.99963 13.6367 2.73717 14.9495 4.04999C16.2623 5.36281 16.9999 7.14338 16.9999 8.99999C16.9999 10.8566 16.2623 12.6372 14.9495 13.95L9.99953 18.9L5.04953 13.95C4.39944 13.3 3.88376 12.5283 3.53193 11.6789C3.18011 10.8296 2.99902 9.9193 2.99902 8.99999C2.99902 8.08068 3.18011 7.17037 3.53193 6.32104C3.88376 5.47172 4.39944 4.70001 5.04953 4.04999L5.75664 4.7571C5.19942 5.31426 4.75737 5.97576 4.4558 6.70375C4.15424 7.43174 3.99902 8.21201 3.99902 8.99999C3.99902 9.78797 4.15424 10.5682 4.4558 11.2962C4.75737 12.0242 5.19942 12.6857 5.75664 13.2429ZM12.1208 11.1213C11.5582 11.6839 10.7952 12 9.99953 12C9.20388 12 8.44082 11.6839 7.87821 11.1213C7.3156 10.5587 6.99953 9.79564 6.99953 8.99999C6.99953 8.20434 7.3156 7.44128 7.87821 6.87867C8.44082 6.31606 9.20388 5.99999 9.99953 5.99999C10.7952 5.99999 11.5582 6.31606 12.1208 6.87867C12.6835 7.44128 12.9995 8.20434 12.9995 8.99999C12.9995 9.79564 12.6835 10.5587 12.1208 11.1213ZM11.4137 10.4142C11.0387 10.7893 10.53 11 9.99953 11C9.4691 11 8.96039 10.7893 8.58532 10.4142C8.21024 10.0391 7.99953 9.53042 7.99953 8.99999C7.99953 8.46956 8.21024 7.96085 8.58532 7.58578C8.96039 7.2107 9.4691 6.99999 9.99953 6.99999C10.53 6.99999 11.0387 7.2107 11.4137 7.58578C11.7888 7.96085 11.9995 8.46956 11.9995 8.99999C11.9995 9.53042 11.7888 10.0391 11.4137 10.4142Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconLocationMarker
