import { useSelector } from 'react-redux'
import { responsiveBreakpointMap } from '~/constants'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import { DesktopPrompt } from '../DesktopPrompt'

interface AuthLayoutProps {
    children: JSX.Element | JSX.Element[]
    showMobile?: boolean
}

export const AuthLayout = ({ children, showMobile }: AuthLayoutProps) => {
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any
    if (window.innerWidth < responsiveBreakpointMap.md && !showMobile) {
        return (
            <>
                <DesktopPrompt localeText={localeText}></DesktopPrompt>
            </>
        )
    }

    return (
        <>
            <main className="w-full h-full flex justify-center items-center overflow-auto">
                <div className="max-h-full w-full">{children}</div>
            </main>
        </>
    )
}
