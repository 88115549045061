const IconLink = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_98_3464)">
                <path
                    d="M8.22875 12.1539L3.32125 17.0614C2.05789 18.3441 1.34912 20.0719 1.34772 21.8723C1.34632 23.6727 2.05239 25.4016 3.31375 26.6864C3.9445 27.3205 4.69438 27.8238 5.52028 28.1672C6.34618 28.5106 7.23181 28.6874 8.12625 28.6874C9.0207 28.6874 9.90633 28.5106 10.7322 28.1672C11.5581 27.8238 12.308 27.3205 12.9388 26.6864L15.4263 24.1989C15.5456 24.0835 15.6409 23.9456 15.7064 23.7931C15.7719 23.6406 15.8064 23.4766 15.8078 23.3106C15.8093 23.1446 15.7776 22.98 15.7148 22.8264C15.6519 22.6728 15.5591 22.5332 15.4417 22.4159C15.3244 22.2985 15.1848 22.2057 15.0312 22.1428C14.8776 22.08 14.713 22.0483 14.547 22.0498C14.381 22.0512 14.217 22.0857 14.0645 22.1512C13.912 22.2167 13.7741 22.312 13.6588 22.4314L11.1638 24.9189C10.3538 25.7102 9.26438 26.1501 8.13203 26.1433C6.99968 26.1365 5.9157 25.6834 5.11532 24.8823C4.31494 24.0813 3.86273 22.9969 3.85682 21.8646C3.85091 20.7322 4.29178 19.6432 5.08375 18.8339L10 13.9214C10.5317 13.3839 11.1977 12.9989 11.9288 12.8064C12.6581 12.6094 13.4267 12.6091 14.1562 12.8054C14.8858 13.0017 15.5504 13.3875 16.0825 13.9239C16.1888 14.0289 16.2938 14.1451 16.4163 14.2789C16.5264 14.3997 16.6595 14.4975 16.8077 14.5667C16.9558 14.6358 17.1163 14.675 17.2796 14.6818C17.443 14.6887 17.6062 14.6631 17.7596 14.6066C17.9131 14.5501 18.0538 14.4638 18.1738 14.3526C18.4177 14.1263 18.5624 13.8128 18.5762 13.4803C18.59 13.1478 18.4718 12.8234 18.2475 12.5776C18.1138 12.4314 17.98 12.2851 17.8525 12.1589C17.2213 11.5254 16.4713 11.0228 15.6455 10.6799C14.8196 10.3369 13.9342 10.1604 13.04 10.1604C12.1458 10.1604 11.2604 10.3369 10.4345 10.6799C9.60869 11.0228 8.85868 11.5254 8.2275 12.1589L8.22875 12.1539Z"
                    fill="currentColor"
                />
                <path
                    d="M16.3463 7.57131L18.8363 5.08131C19.6544 4.29497 20.7455 3.85641 21.8803 3.85781C23.015 3.85921 24.1051 4.30045 24.9213 5.08881C25.7087 5.90442 26.1488 6.99385 26.1488 8.12756C26.1488 9.26126 25.7087 10.3507 24.9213 11.1663L20 16.0788C19.4684 16.6162 18.8023 17.0013 18.0713 17.1938C17.3418 17.3898 16.5735 17.3897 15.8442 17.1935C15.1148 16.9972 14.4501 16.6118 13.9175 16.0763C13.8113 15.9713 13.7063 15.8551 13.5838 15.7213C13.4736 15.6005 13.3405 15.5026 13.1924 15.4335C13.0442 15.3643 12.8838 15.3252 12.7204 15.3183C12.557 15.3115 12.3939 15.337 12.2404 15.3935C12.0869 15.45 11.9462 15.5364 11.8263 15.6476C11.5823 15.8739 11.4377 16.1874 11.4238 16.5199C11.41 16.8523 11.5282 17.1768 11.7525 17.4226C11.8863 17.5688 12.02 17.7151 12.1475 17.8413C12.9942 18.6809 14.0449 19.2855 15.1963 19.5957C16.3476 19.9058 17.5599 19.9108 18.7138 19.6101C19.8706 19.3037 20.925 18.6949 21.7688 17.8463L26.6763 12.9388C27.9223 11.647 28.6192 9.92262 28.6206 8.12782C28.622 6.33302 27.9278 4.60752 26.6838 3.31381C26.053 2.67962 25.3031 2.17636 24.4772 1.83294C23.6513 1.48953 22.7657 1.31274 21.8713 1.31274C20.9768 1.31274 20.0912 1.48953 19.2653 1.83294C18.4394 2.17636 17.6895 2.67962 17.0588 3.31381L14.5713 5.80131C14.3436 6.03706 14.2176 6.35281 14.2204 6.68056C14.2233 7.0083 14.3547 7.32182 14.5865 7.55358C14.8182 7.78534 15.1318 7.9168 15.4595 7.91965C15.7873 7.9225 16.103 7.7965 16.3388 7.56881L16.3463 7.57131Z"
                    fill="currentColor"
                />
                <path
                    d="M6.26249 8.02245C6.50202 8.24689 6.81798 8.37179 7.14624 8.37179C7.4745 8.37179 7.79046 8.24689 8.02999 8.02245C8.14669 7.9062 8.23927 7.76804 8.3024 7.61591C8.36553 7.46377 8.39797 7.30065 8.39785 7.13594C8.39774 6.97122 8.36507 6.80815 8.30172 6.6561C8.23838 6.50405 8.14561 6.36602 8.02874 6.24995L5.07874 3.3112C4.96326 3.19198 4.82519 3.09696 4.6726 3.03167C4.52 2.96639 4.35594 2.93214 4.18997 2.93094C4.024 2.92974 3.85945 2.9616 3.70592 3.02467C3.5524 3.08774 3.41297 3.18075 3.29578 3.29828C3.17858 3.4158 3.08596 3.55549 3.02333 3.7092C2.9607 3.8629 2.9293 4.02754 2.93097 4.1935C2.93264 4.35947 2.96735 4.52344 3.03307 4.67585C3.09879 4.82826 3.1942 4.96605 3.31374 5.0812L6.26249 8.02245Z"
                    fill="currentColor"
                />
                <path
                    d="M11.0688 0C11.4003 0 11.7182 0.131696 11.9526 0.366117C12.1871 0.600537 12.3188 0.918479 12.3188 1.25V3.22375C12.3188 3.55527 12.1871 3.87321 11.9526 4.10763C11.7182 4.34205 11.4003 4.47375 11.0688 4.47375C10.7372 4.47375 10.4193 4.34205 10.1849 4.10763C9.95045 3.87321 9.81876 3.55527 9.81876 3.22375V1.25C9.81876 0.918479 9.95045 0.600537 10.1849 0.366117C10.4193 0.131696 10.7372 0 11.0688 0V0Z"
                    fill="currentColor"
                />
                <path
                    d="M1.25 12.3213L3.22875 12.3113C3.56027 12.3113 3.87822 12.1796 4.11264 11.9452C4.34706 11.7107 4.47875 11.3928 4.47875 11.0613C4.47875 10.7298 4.34706 10.4118 4.11264 10.1774C3.87822 9.94298 3.56027 9.81128 3.22875 9.81128H3.2225L1.2425 9.82128C1.07835 9.82177 0.915902 9.85459 0.764433 9.91786C0.612965 9.98114 0.475442 10.0736 0.359717 10.19C0.126 10.4252 -0.00474259 10.7435 -0.00374803 11.075C-0.00275347 11.4065 0.129896 11.7241 0.36502 11.9578C0.600144 12.1915 0.918481 12.3223 1.25 12.3213Z"
                    fill="currentColor"
                />
                <path
                    d="M25.8037 27.0536C26.0508 27.0536 26.2923 26.9803 26.4977 26.8431C26.7031 26.7059 26.8633 26.5109 26.958 26.2828C27.0527 26.0546 27.0777 25.8035 27.0297 25.5611C26.9818 25.3188 26.8632 25.0961 26.6887 24.9211L23.75 21.9711C23.634 21.8549 23.4963 21.7627 23.3447 21.6997C23.1931 21.6367 23.0305 21.6042 22.8663 21.604C22.7021 21.6038 22.5395 21.636 22.3878 21.6987C22.236 21.7613 22.0981 21.8533 21.9819 21.9693C21.8657 22.0852 21.7734 22.223 21.7104 22.3746C21.6474 22.5262 21.6149 22.6887 21.6147 22.8529C21.6146 23.0171 21.6467 23.1797 21.7094 23.3315C21.7721 23.4832 21.864 23.6212 21.98 23.7374L24.9212 26.6861C25.1551 26.9207 25.4725 27.0529 25.8037 27.0536Z"
                    fill="currentColor"
                />
                <path
                    d="M25.5275 18.9326C25.5275 19.2641 25.6592 19.5821 25.8936 19.8165C26.128 20.0509 26.446 20.1826 26.7775 20.1826H28.75C29.0815 20.1826 29.3995 20.0509 29.6339 19.8165C29.8683 19.5821 30 19.2641 30 18.9326C30 18.6011 29.8683 18.2832 29.6339 18.0487C29.3995 17.8143 29.0815 17.6826 28.75 17.6826H26.7762C26.4449 17.6829 26.1273 17.8148 25.8932 18.0492C25.659 18.2836 25.5275 18.6013 25.5275 18.9326Z"
                    fill="currentColor"
                />
                <path
                    d="M18.9287 30.0001C19.259 30.0001 19.5758 29.8694 19.81 29.6366C20.0442 29.4038 20.1768 29.0878 20.1787 28.7576L20.1887 26.7776C20.1887 26.4471 20.0579 26.1301 19.8248 25.8959C19.5918 25.6617 19.2754 25.5292 18.945 25.5276H18.9387C18.6083 25.5276 18.2913 25.6584 18.0571 25.8915C17.8228 26.1246 17.6904 26.4409 17.6887 26.7713L17.6787 28.7501C17.6787 29.0803 17.8094 29.3971 18.0422 29.6313C18.275 29.8655 18.591 29.9981 18.9212 30.0001H18.9287Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_98_3464">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconLink
