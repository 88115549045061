const IconAcademicCapOutline = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14L21 9L12 4L3 9L12 14Z" stroke="currentColor" />
            <path
                d="M11.9996 14L18.1596 10.578C18.9701 12.6361 19.2001 14.8772 18.8246 17.057C16.2882 17.3032 13.8968 18.3536 11.9996 20.055C10.1026 18.3538 7.71167 17.3034 5.17559 17.057C4.79979 14.8772 5.02982 12.636 5.84059 10.578L11.9996 14Z"
                stroke="currentColor"
            />
            <path
                d="M12 14L21 9L12 4L3 9L12 14ZM12 14L18.16 10.578C18.9705 12.6361 19.2005 14.8772 18.825 17.057C16.2886 17.3032 13.8972 18.3536 12 20.055C10.1031 18.3538 7.71208 17.3034 5.176 17.057C4.8002 14.8772 5.03023 12.636 5.841 10.578L12 14ZM8 20V12.5L12 10.278"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconAcademicCapOutline
