const IconOffice = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.79961 4.80002C4.79961 4.1635 5.05247 3.55306 5.50255 3.10297C5.95264 2.65288 6.56309 2.40002 7.19961 2.40002H16.7996C17.4361 2.40002 18.0466 2.65288 18.4967 3.10297C18.9468 3.55306 19.1996 4.1635 19.1996 4.80002V19.2C19.5179 19.2 19.8231 19.3265 20.0481 19.5515C20.2732 19.7765 20.3996 20.0818 20.3996 20.4C20.3996 20.7183 20.2732 21.0235 20.0481 21.2486C19.8231 21.4736 19.5179 21.6 19.1996 21.6H15.5996C15.2814 21.6 14.9761 21.4736 14.7511 21.2486C14.526 21.0235 14.3996 20.7183 14.3996 20.4V18C14.3996 17.6818 14.2732 17.3765 14.0481 17.1515C13.8231 16.9265 13.5179 16.8 13.1996 16.8H10.7996C10.4813 16.8 10.1761 16.9265 9.95108 17.1515C9.72604 17.3765 9.59961 17.6818 9.59961 18V20.4C9.59961 20.7183 9.47318 21.0235 9.24814 21.2486C9.02309 21.4736 8.71787 21.6 8.39961 21.6H4.79961C4.48135 21.6 4.17612 21.4736 3.95108 21.2486C3.72604 21.0235 3.59961 20.7183 3.59961 20.4C3.59961 20.0818 3.72604 19.7765 3.95108 19.5515C4.17612 19.3265 4.48135 19.2 4.79961 19.2V4.80002ZM8.39961 6.00002H10.7996V8.40002H8.39961V6.00002ZM10.7996 10.8H8.39961V13.2H10.7996V10.8ZM13.1996 6.00002H15.5996V8.40002H13.1996V6.00002ZM15.5996 10.8H13.1996V13.2H15.5996V10.8Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9996 20.4V4.80002C17.9996 4.48177 17.8732 4.17654 17.6481 3.9515C17.4231 3.72645 17.1179 3.60002 16.7996 3.60002H7.19961C6.88135 3.60002 6.57612 3.72645 6.35108 3.9515C6.12604 4.17654 5.99961 4.48176 5.99961 4.80002V20.4H8.39961V18C8.39961 17.3635 8.65247 16.7531 9.10255 16.303C9.55264 15.8529 10.1631 15.6 10.7996 15.6H13.1996C13.8361 15.6 14.4466 15.8529 14.8967 16.303C15.3468 16.7531 15.5996 17.3635 15.5996 18V20.4H17.9996ZM7.19961 4.80002H16.7996V14.4H7.19961V4.80002ZM4.79961 19.2C4.48135 19.2 4.17612 19.3265 3.95108 19.5515C3.72604 19.7765 3.59961 20.0818 3.59961 20.4C3.59961 20.7183 3.72604 21.0235 3.95108 21.2486C4.17612 21.4736 4.48135 21.6 4.79961 21.6H8.39961C8.71787 21.6 9.02309 21.4736 9.24814 21.2486C9.47318 21.0235 9.59961 20.7183 9.59961 20.4V18C9.59961 17.6818 9.72604 17.3765 9.95108 17.1515C10.1761 16.9265 10.4813 16.8 10.7996 16.8H13.1996C13.5179 16.8 13.8231 16.9265 14.0481 17.1515C14.2732 17.3765 14.3996 17.6818 14.3996 18V20.4C14.3996 20.7183 14.526 21.0235 14.7511 21.2486C14.9761 21.4736 15.2813 21.6 15.5996 21.6H19.1996C19.5179 21.6 19.8231 21.4736 20.0481 21.2486C20.2732 21.0235 20.3996 20.7183 20.3996 20.4C20.3996 20.0818 20.2732 19.7765 20.0481 19.5515C19.8231 19.3265 19.5179 19.2 19.1996 19.2V4.80002C19.1996 4.1635 18.9468 3.55306 18.4967 3.10297C18.0466 2.65288 17.4361 2.40002 16.7996 2.40002H7.19961C6.56309 2.40002 5.95264 2.65288 5.50255 3.10297C5.05247 3.55306 4.79961 4.1635 4.79961 4.80002V19.2ZM8.39961 6.00002V8.40002H10.7996V6.00002H8.39961ZM10.7996 10.8V13.2H8.39961V10.8H10.7996ZM13.1996 6.00002V8.40002H15.5996V6.00002H13.1996ZM15.5996 10.8V13.2H13.1996V10.8H15.5996Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconOffice
