const IconUpload = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.59961 20.4C3.59961 20.0817 3.72604 19.7765 3.95108 19.5515C4.17612 19.3264 4.48135 19.2 4.79961 19.2H19.1996C19.5179 19.2 19.8231 19.3264 20.0481 19.5515C20.2732 19.7765 20.3996 20.0817 20.3996 20.4C20.3996 20.7183 20.2732 21.0235 20.0481 21.2485C19.8231 21.4736 19.5179 21.6 19.1996 21.6H4.79961C4.48135 21.6 4.17612 21.4736 3.95108 21.2485C3.72604 21.0235 3.59961 20.7183 3.59961 20.4ZM7.55121 8.04841C7.32624 7.82338 7.19987 7.51821 7.19987 7.20001C7.19987 6.88181 7.32624 6.57664 7.55121 6.35161L11.1512 2.75161C11.3762 2.52665 11.6814 2.40027 11.9996 2.40027C12.3178 2.40027 12.623 2.52665 12.848 2.75161L16.448 6.35161C16.6666 6.57793 16.7876 6.88106 16.7848 7.19569C16.7821 7.51033 16.6559 7.8113 16.4334 8.03379C16.2109 8.25628 15.9099 8.38249 15.5953 8.38522C15.2807 8.38795 14.9775 8.267 14.7512 8.04841L13.1996 6.49681V15.6C13.1996 15.9183 13.0732 16.2235 12.8481 16.4485C12.6231 16.6736 12.3179 16.8 11.9996 16.8C11.6813 16.8 11.3761 16.6736 11.1511 16.4485C10.926 16.2235 10.7996 15.9183 10.7996 15.6V6.49681L9.24801 8.04841C9.02298 8.27338 8.71781 8.39976 8.39961 8.39976C8.08141 8.39976 7.77624 8.27338 7.55121 8.04841Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7512 8.04841C14.9775 8.267 15.2807 8.38795 15.5953 8.38522C15.9099 8.38249 16.2109 8.25628 16.4334 8.03379C16.6559 7.8113 16.7821 7.51033 16.7848 7.19569C16.7876 6.88106 16.6666 6.57793 16.448 6.35161L12.848 2.75161C12.623 2.52665 12.3178 2.40027 11.9996 2.40027C11.6814 2.40027 11.3762 2.52665 11.1512 2.75161L7.55121 6.35161C7.32624 6.57664 7.19987 6.88181 7.19987 7.20001C7.19987 7.51821 7.32624 7.82338 7.55121 8.04841C7.77624 8.27338 8.08141 8.39976 8.39961 8.39976C8.71781 8.39976 9.02298 8.27338 9.24801 8.04841L10.7996 6.49681V15.6C10.7996 15.9183 10.926 16.2235 11.1511 16.4485C11.3761 16.6736 11.6813 16.8 11.9996 16.8C12.3179 16.8 12.6231 16.6736 12.8481 16.4485C13.0732 16.2235 13.1996 15.9183 13.1996 15.6V6.49681L14.7512 8.04841ZM3.95108 19.5515C3.72604 19.7765 3.59961 20.0817 3.59961 20.4C3.59961 20.7183 3.72604 21.0235 3.95108 21.2485C4.17612 21.4736 4.48135 21.6 4.79961 21.6H19.1996C19.5179 21.6 19.8231 21.4736 20.0481 21.2485C20.2732 21.0235 20.3996 20.7183 20.3996 20.4C20.3996 20.0817 20.2732 19.7765 20.0481 19.5515C19.8231 19.3264 19.5179 19.2 19.1996 19.2H4.79961C4.48135 19.2 4.17612 19.3264 3.95108 19.5515Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconUpload
