const IconExclamationOutline = () => (
    <svg className="h-full w-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.0004 8.99999V11M12.0004 15H12.0104M5.07241 19H18.9284C20.4684 19 21.4304 17.333 20.6604 16L13.7324 3.99999C12.9624 2.66699 11.0384 2.66699 10.2684 3.99999L3.34041 16C2.57041 17.333 3.53241 19 5.07241 19Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default IconExclamationOutline
