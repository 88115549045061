const IconLayers = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 -960 960 960">
            <path
                d="M480-118 120-398l66-50 294 228 294-228 66 50-360 280Zm0-202L120-600l360-280 360 280-360 280Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconLayers
