// redux/store.ts
import { configureStore } from '@reduxjs/toolkit'
import currencyReducer from './slices/currencySlice'
import languageReducer from './slices/languageSlice'

export const store = configureStore({
    reducer: {
        language: languageReducer,
        currency: currencyReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
