import React from 'react'

const IconChat = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 10C18 13.866 14.418 17 10 17C8.58005 17.006 7.17955 16.6698 5.917 16.02L2 17L3.338 13.877C2.493 12.767 2 11.434 2 10C2 6.134 5.582 3 10 3C14.418 3 18 6.134 18 10ZM7 9H5V11H7V9ZM15 9H13V11H15V9ZM9 9H11V11H9V9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.9958 16L10 16C13.9953 16 17 13.1924 17 10C17 6.80756 13.9953 4 10 4C6.00465 4 3 6.80756 3 10C3 11.1977 3.41026 12.321 4.13368 13.2713L4.48673 13.7351L3.71319 15.5406L6.03971 14.9585L6.37463 15.1309C7.49437 15.7072 8.73646 16.0053 9.9958 16ZM2 17L3.338 13.877C2.493 12.767 2 11.434 2 10C2 6.134 5.582 3 10 3C14.418 3 18 6.134 18 10C18 13.866 14.418 17 10 17C8.58005 17.006 7.17955 16.6698 5.917 16.02L2 17ZM16 8V12H4V8H16ZM7 9V11H5V9H7ZM15 9V11H13V9H15ZM9 9V11H11V9H9Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconChat
