import { SimplyLogo } from '~/components/Logo/SimplyLogo'

export const InitialPageLoad = () => {
    return (
        <>
            {/* <div className="relative h-36 w-36 border-2 flex justify-center items-center"><KelolaLogo></KelolaLogo>Simplystock.co</div> */}

            <div className="w-full h-screen flex items-center justify-center left-0 top-0 fixed transition-all">
                <div className="relative  text-2xl text-emerald-600 flex justify-center items-center">
                    <SimplyLogo></SimplyLogo>
                </div>
            </div>
        </>
    )
}
