import React from 'react'

const IconHome = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7064 2.29303C10.5189 2.10556 10.2646 2.00024 9.99943 2.00024C9.73427 2.00024 9.47996 2.10556 9.29243 2.29303L2.29243 9.29303C2.11027 9.48163 2.00948 9.73423 2.01176 9.99643C2.01403 10.2586 2.1192 10.5094 2.30461 10.6948C2.49002 10.8803 2.74083 10.9854 3.00303 10.9877C3.26523 10.99 3.51783 10.8892 3.70643 10.707L3.99943 10.414V17C3.99943 17.2652 4.10479 17.5196 4.29232 17.7071C4.47986 17.8947 4.73421 18 4.99943 18H6.99943C7.26465 18 7.519 17.8947 7.70654 17.7071C7.89407 17.5196 7.99943 17.2652 7.99943 17V15C7.99943 14.7348 8.10479 14.4805 8.29232 14.2929C8.47986 14.1054 8.73421 14 8.99943 14H10.9994C11.2646 14 11.519 14.1054 11.7065 14.2929C11.8941 14.4805 11.9994 14.7348 11.9994 15V17C11.9994 17.2652 12.1048 17.5196 12.2923 17.7071C12.4799 17.8947 12.7342 18 12.9994 18H14.9994C15.2646 18 15.519 17.8947 15.7065 17.7071C15.8941 17.5196 15.9994 17.2652 15.9994 17V10.414L16.2924 10.707C16.481 10.8892 16.7336 10.99 16.9958 10.9877C17.258 10.9854 17.5088 10.8803 17.6942 10.6948C17.8797 10.5094 17.9848 10.2586 17.9871 9.99643C17.9894 9.73423 17.8886 9.48163 17.7064 9.29303L10.7064 2.29303Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconHome
