const IconReport = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M296-72.109 232.109-136 516-420.652l140 140 207-207L927.652-423 656-152.109l-140-140-220 220Zm-133.13-39.76q-37.783 0-64.392-26.609Q71.87-165.087 71.87-202.87v-554.26q0-37.783 26.61-64.392 26.608-26.609 64.391-26.609h554.26q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392v167.043H162.87V-111.869Zm0-566.827h554.26v-78.434H162.87v78.434Zm0 0v-78.434 78.434Z"
            />
        </svg>
    )
}

export default IconReport
