import React from 'react'

const IconPrev = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41379 3.99998H14.9998C15.265 3.99998 15.5194 4.10534 15.7069 4.29287C15.8944 4.48041 15.9998 4.73476 15.9998 4.99998C15.9998 5.2652 15.8944 5.51955 15.7069 5.70709C15.5194 5.89462 15.265 5.99998 14.9998 5.99998H3.41379L5.70678 8.29298C5.89426 8.48051 5.99957 8.73482 5.99957 8.99998C5.99957 9.26514 5.89426 9.51945 5.70678 9.70698C5.51926 9.89445 5.26495 9.99977 4.99979 9.99977C4.73462 9.99977 4.48031 9.89445 4.29279 9.70698L0.292786 5.70698C0.105315 5.51945 0 5.26514 0 4.99998C0 4.73482 0.105315 4.48051 0.292786 4.29298L4.29279 0.29298C4.48139 0.110822 4.73399 0.0100274 4.99619 0.0123059C5.25838 0.0145843 5.5092 0.119753 5.6946 0.305162C5.88001 0.49057 5.98518 0.741382 5.98746 1.00358C5.98974 1.26578 5.88894 1.51838 5.70678 1.70698L3.41379 3.99998Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconPrev
