import React from 'react'

const IconShoppingBag = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99999 2C7.79099 2 5.99999 3.791 5.99999 6V7H4.99999C4.48999 7 4.062 7.38301 4.006 7.89001L3.006 16.89C2.975 17.172 3.06499 17.455 3.25499 17.667C3.44499 17.879 3.71599 18 3.99999 18H16C16.284 18 16.555 17.879 16.745 17.667C16.935 17.455 17.025 17.172 16.994 16.89L15.994 7.89001C15.938 7.38301 15.51 7 15 7H14V6C14 3.791 12.209 2 9.99999 2ZM12 7V6C12 4.895 11.105 4 9.99999 4C8.89499 4 7.99999 4.895 7.99999 6V7H12ZM5.99999 10C5.99999 9.448 6.44799 9 6.99999 9C7.55199 9 7.99999 9.448 7.99999 10C7.99999 10.552 7.55199 11 6.99999 11C6.44799 11 5.99999 10.552 5.99999 10ZM13 9C12.448 9 12 9.448 12 10C12 10.552 12.448 11 13 11C13.552 11 14 10.552 14 10C14 9.448 13.552 9 13 9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99999 8H4.99999L4.00001 16.9993L16 17L15.0001 8.00045L13 8H6.99999ZM6.99999 8C8.10427 8 8.99999 8.89572 8.99999 10C8.99999 11.1043 8.10427 12 6.99999 12C5.89571 12 4.99999 11.1043 4.99999 10C4.99999 8.89572 5.89571 8 6.99999 8ZM13 8C14.1043 8 15 8.89572 15 10C15 11.1043 14.1043 12 13 12C11.8957 12 11 11.1043 11 10C11 8.89572 11.8957 8 13 8ZM16.994 16.89C17.025 17.172 16.935 17.455 16.745 17.667C16.555 17.879 16.284 18 16 18H3.99999C3.71599 18 3.44499 17.879 3.25499 17.667C3.06499 17.455 2.975 17.172 3.006 16.89L4.006 7.89001C4.062 7.38301 4.48999 7 4.99999 7H5.99999V6C5.99999 3.791 7.79099 2 9.99999 2C12.209 2 14 3.791 14 6V7H15C15.51 7 15.938 7.38301 15.994 7.89001L16.994 16.89ZM7.99999 7V6C7.99999 4.895 8.89499 4 9.99999 4C11.105 4 12 4.895 12 6V7H7.99999ZM6.99999 9C6.44799 9 5.99999 9.448 5.99999 10C5.99999 10.552 6.44799 11 6.99999 11C7.55199 11 7.99999 10.552 7.99999 10C7.99999 9.448 7.55199 9 6.99999 9ZM12 10C12 9.448 12.448 9 13 9C13.552 9 14 9.448 14 10C14 10.552 13.552 11 13 11C12.448 11 12 10.552 12 10Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconShoppingBag
