import { useRouter } from 'next/router'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetDeviceWidth } from '~/hooks'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import { getPageMap } from '~/utils/page'
import { useAuth } from '../Auth/AuthProvider'
import { AuthLayout } from '../Layout/AuthLayout/AuthLayout'
import { Dashboard } from '../Layout/Dashboard/Dashboard'
import { InitialPageLoad } from '../Layout/InitialPageLoad'
import { ToastWrapper } from '../Layout/ToastWrapper'
import { Meta } from './Meta'
import { ToastProps } from './Toast'

export interface GlobalData {
    pageLoading: boolean
    setPageLoading: Dispatch<SetStateAction<boolean>>
    deviceWidth: number
    setDeviceWidth: Dispatch<SetStateAction<number>>
    toastProps: ToastProps
    setToastProps: Dispatch<SetStateAction<ToastProps>>
}

const GlobalDataContextDefaultValue: GlobalData = {
    pageLoading: false,
    setPageLoading: () => false,
    deviceWidth: 0,
    setDeviceWidth: () => false,
    toastProps: {
        message: ''
    },
    setToastProps: () => false
}

export const GlobalDataContext = createContext<GlobalData>(GlobalDataContextDefaultValue)

export const useGlobal = () => useContext(GlobalDataContext)

interface GlobalDataProviderProps {
    children: JSX.Element | JSX.Element[]
}

const GlobalDataProvider = ({ children }: GlobalDataProviderProps) => {
    const router = useRouter()
    const { isAuthenticating, profile, googleSignIn } = useAuth()
    const [pageLoading, setPageLoading] = useState(true)
    const [deviceWidth, setDeviceWidth] = useState(0)
    const [toastProps, setToastProps] = useState<ToastProps>({
        message: ''
    })
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any

    const pageProps = getPageMap(localeText)[router.pathname]

    useGetDeviceWidth(setDeviceWidth)

    const globalDataValue: GlobalData = {
        pageLoading,
        setPageLoading,
        deviceWidth,
        setDeviceWidth,
        toastProps,
        setToastProps
    }

    const renderComponent = () => {
        // if device width is unknown return empty component
        if (!deviceWidth || !pageProps) {
            return <></>
        }

        // if page still loading return page load component
        if (isAuthenticating) {
            return <InitialPageLoad></InitialPageLoad>
        }

        // For auth page use auth layout
        if (pageProps.layout === 'fullscreen') {
            return <AuthLayout showMobile={pageProps.mobile}>{children}</AuthLayout>
        }

        const hideNavigation = pageProps.hideNavigation || router.query.is_onboard === 'true'

        return (
            <>
                <Dashboard pathname={router.pathname} hideNavigation={hideNavigation} showMobile={pageProps.mobile}>
                    {children}
                </Dashboard>
            </>
        )
    }

    return (
        <GlobalDataContext.Provider value={globalDataValue}>
            <Meta title={pageProps.title}></Meta>
            {renderComponent()}
            <ToastWrapper></ToastWrapper>
        </GlobalDataContext.Provider>
    )
}

export default GlobalDataProvider
