const featuresPay = {
    featureTitle: 'ডেটার ভিত্তিতে ব্যবসায়িক সিদ্ধান্ত নিন',
    featureDescription:
        'জানুন কোন আইটেমগুলি বিক্রি হচ্ছে এবং সর্বাধিক লাভ দিচ্ছে, এবং যেকোনো সময়, যেকোনো স্থানে আর্থিক রিপোর্টে অ্যাক্সেস করুন। আপনার ব্যবসা আপনার হাতের তালুতে!',
    featureButton: 'অ্যাপ থেকে প্রিমিয়াম সাবস্ক্রিপশন',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'শুরু হচ্ছে',
    featureEndingText: 'হাজার',

    features: [
        {
            title: 'অসীম আইটেম',
            description: 'আপনার কাছে থাকা সমস্ত আইটেম যোগ করুন সীমাবদ্ধতা ছাড়াই।'
        },
        {
            title: 'অসীম কর্মী',
            description: 'কোনো সীমাবদ্ধতা ছাড়াই ব্যবসায় কর্মী/অ্যাডমিন যোগ করুন।'
        },
        {
            title: 'অসীম ইতিহাস',
            description: 'পুরানো স্টক রেকর্ডের পরিবর্তনগুলি সীমাবদ্ধতা ছাড়াই দেখুন।'
        },
        {
            title: 'ইতিহাস ফিল্টার',
            description: 'প্রকার, ক্যাটাগরি, কর্মী এবং তারিখের ভিত্তিতে সহজেই ইতিহাস দেখুন।'
        },
        {
            title: 'এক্সেলে ডাউনলোড',
            description: 'এক ক্লিকের মাধ্যমে মাইক্রোসফট এক্সেলে স্টক রেকর্ডের ইতিহাস পান।'
        },
        {
            title: 'পিডিএফ এ ডাউনলোড',
            description: 'এক ক্লিকের মাধ্যমে সর্বশেষ আইটেম এবং স্টক তালিকা পিডিএফ এ পান।'
        }
    ]
}

export default featuresPay
