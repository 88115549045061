import React from 'react'

const IconPencilAlt = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.414 2.586C17.0389 2.21106 16.5303 2.00043 16 2.00043C15.4697 2.00043 14.9611 2.21106 14.586 2.586L7 10.172V13H9.828L17.414 5.414C17.7889 5.03894 17.9996 4.53033 17.9996 4C17.9996 3.46967 17.7889 2.96106 17.414 2.586Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7068 3.293C16.5193 3.10566 16.2651 3.00043 16 3.00043C15.7349 3.00043 15.4806 3.1057 15.2931 3.29311C15.2931 3.29314 15.2931 3.29307 15.2931 3.29311L8 10.5862V12H9.41379L16.7068 4.707C16.7068 4.70696 16.7068 4.70704 16.7068 4.707C16.8942 4.51948 16.9996 4.26511 16.9996 4C16.9996 3.73494 16.8941 3.48051 16.7068 3.293C16.7069 3.29307 16.7067 3.29293 16.7068 3.293ZM17.414 5.414L9.828 13H7V10.172L14.586 2.586C14.9611 2.21106 15.4697 2.00043 16 2.00043C16.5303 2.00043 17.0389 2.21106 17.414 2.586C17.7889 2.96106 17.9996 3.46967 17.9996 4C17.9996 4.53033 17.7889 5.03894 17.414 5.414Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H8C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5C9 5.26522 8.89464 5.51957 8.70711 5.70711C8.51957 5.89464 8.26522 6 8 6H4V16H14V12C14 11.7348 14.1054 11.4804 14.2929 11.2929C14.4804 11.1054 14.7348 11 15 11C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V6Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 12C14 11.7348 14.1054 11.4804 14.2929 11.2929C14.4804 11.1054 14.7348 11 15 11C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H8C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5C9 5.26522 8.89464 5.51957 8.70711 5.70711C8.51957 5.89464 8.26522 6 8 6H4V16H14V12Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconPencilAlt
