const IconChevronUp = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7072 12.707C14.5197 12.8945 14.2654 12.9998 14.0002 12.9998C13.735 12.9998 13.4807 12.8945 13.2932 12.707L10.0002 9.414L6.70721 12.707C6.61501 12.8025 6.50463 12.8787 6.38263 12.9311C6.26063 12.9835 6.12942 13.0111 5.99663 13.0122C5.86383 13.0134 5.73221 12.9881 5.60932 12.9378C5.48642 12.8875 5.37473 12.8133 5.28084 12.7194C5.18694 12.6255 5.11269 12.5138 5.06241 12.3909C5.01213 12.268 4.98683 12.1363 4.988 12.0035C4.98914 11.8707 5.01673 11.7395 5.06914 11.6175C5.12155 11.4955 5.19773 11.3851 5.29321 11.293L9.29321 7.29302C9.48073 7.10555 9.73504 7.00023 10.0002 7.00023C10.2654 7.00023 10.5197 7.10555 10.7072 7.29302L14.7072 11.293C14.8947 11.4805 15 11.7349 15 12.0001C15 12.2652 14.8947 12.5195 14.7072 12.707Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2932 12.707C13.4807 12.8945 13.735 12.9998 14.0002 12.9998C14.2654 12.9998 14.5197 12.8945 14.7072 12.707C14.8947 12.5195 15 12.2652 15 12.0001C15 11.7349 14.8947 11.4805 14.7072 11.293L10.7072 7.29302C10.5197 7.10555 10.2654 7.00023 10.0002 7.00023C9.73504 7.00023 9.48073 7.10555 9.29321 7.29302L5.29321 11.293C5.10573 11.4805 5.00041 11.7349 5.00041 12.0001C5.00041 12.2652 5.10573 12.5195 5.29321 12.707C5.48073 12.8945 5.73504 12.9998 6.00021 12.9998C6.26538 12.9998 6.51969 12.8945 6.70721 12.707L10.0002 9.414L13.2932 12.707Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconChevronUp
