import React from 'react'

const IconChevronLeftOutline = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 19L8 12L15 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconChevronLeftOutline
