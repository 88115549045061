import common from './common'
import featuresPay from './feature'

const te = {
    featuresPay,
    common,
    short: {
        finance: 'ఆర్థికం',
        stock: 'స్టాక్'
    },
    onboarding: {
        welcome: 'స్వాగతం!',
        step: {
            product: {
                title: '65% సాధించబడింది',
                description: 'మీ గొప్ప విజయాన్ని ప్రారంభించడానికి ఇంకా 2 దశలు మిగిలి ఉన్నాయి!'
            },
            stock: {
                title: '95% పూర్తిగా పూర్తయ్యింది!!',
                description: 'మీ గొప్ప విజయాన్ని ప్రారంభించడానికి కేవలం ఒక దశ మిగిలి ఉంది! ఇప్పుడు పూర్తి చేయండి.'
            }
        },
        business: {
            title: 'వ్యాపారం విజయవంతంగా సృష్టించబడింది!',
            description: 'అద్భుతం! మీ వ్యాపారం నమోదు చేయబడింది.'
        },
        product: {
            title: 'మీ తొలి ఉత్పత్తిని జోడించండి',
            description: 'స్టాక్ రికార్డ్ చేయడం ప్రారంభించడానికి ఒక ఉత్పత్తిని జోడించండి.'
        },
        stock: {
            title: 'స్టాక్ జోడించడం ప్రయత్నించండి',
            description: 'అడుగు మరియు బయిలుదేరే స్టాక్‌ను రికార్డ్ చేయడం ప్రారంభించండి.'
        },
        action: {
            add_business: {
                title: 'వ్యాపారం సృష్టించండి',
                description: 'కొత్త వ్యాపారం సృష్టించాలనుకుంటున్నారా? ఇక్కడ క్లిక్ చేయండి.'
            },
            join_business: {
                title: 'వ్యాపారంలో చేరండి',
                description: 'మీకు ఆహ్వాన కోడ్ ఉందా? ఇక్కడ చేరండి.'
            }
        }
    },
    business: {
        title: 'ఒక వ్యాపారాన్ని ఎంచుకోండి, కొత్తగా సృష్టించండి, లేదా చేరండి',
        add_business: {
            title: 'కొత్త వ్యాపారం సృష్టించండి',
            name: 'వ్యాపారం పేరు',
            name_placeholder: 'పేరు నమోదు చేయండి',
            category: 'వ్యాపార విభాగం',
            category_placeholder: 'విభాగాన్ని ఎంచుకోండి',
            button: 'వ్యాపారం సృష్టించండి'
        },
        edit_business: {
            title: 'వ్యాపారాన్ని సవరించండి'
        },
        join_business: {
            title: 'వ్యాపారంలో చేరండి',
            input_code: 'కింద ఆహ్వాన కోడ్‌ను నమోదు చేయండి:',
            field_placeholder: 'ఆహ్వాన కోడ్',
            confirm_title: 'చేరండి',
            confirm_text: 'మీరు భాగస్వామి అవుతారు '
        },
        no_business: 'ఉప లభ్యం కాని వ్యాపారం లేదు',
        create_or_join: 'వ్యాపారాన్ని సృష్టించండి లేదా చేరండి',
        message: {
            saved: 'వ్యాపారం విజయవంతంగా నిల్వ చేయబడింది!'
        }
    },
    profile: {
        edit_profile: {
            title: 'ప్రొఫైల్ సవరించండి',
            name: 'పూర్తి పేరు',
            name_placeholder: 'పూర్తి పేరు నమోదు చేయండి',
            email: 'ఈమెయిల్',
            phone_number: 'ఫోన్ నంబర్'
        },
        message: {
            required_name: 'పేరు అవసరం'
        }
    },
    product: {
        choose_product: 'ఉత్పత్తిని ఎంచుకోండి',
        choose_product_empty: 'ఉత్పత్తిని ఎంచుకోండి మరియు పరిమాణాన్ని నమోదు చేయండి',
        search: {
            placeholder: 'పేరుతో శోధించండి',
            tooltip: 'స్కానర్‌ను తెరువు'
        },
        column: {
            image: 'ఫోటో',
            name: 'పేరు',
            price: 'ధర',
            buying_price: 'ఖరీదు ధర',
            selling_price: 'అమ్మకం ధర',
            sku: 'SKU',
            stock: 'స్టాక్',
            quantity: 'పరిమాణం',
            latest_stock: 'బాకీ',
            product_deleted: 'ఉత్పత్తి తొలగించబడింది',
            info_not_found: 'సమాచారం అందుబాటులో లేదు',
            expired_date: 'అవసరానికి తేది'
        },
        no_product: 'ఉత్పత్తులు అందుబాటులో లేవు',
        add_product: {
            sku: 'ఉత్పత్తి కోడ్',
            sku_placeholder: 'ఉత్పత్తి కోడ్. ఉదాహరణ: 12345',
            name: 'ఉత్పత్తి పేరు',
            name_placeholder: 'ఉత్పత్తి పేరును నమోదు చేయండి',
            initial_stock: 'ప్రాథమిక స్టాక్',
            initial_stock_placeholder: 'ప్రాథమిక స్టాక్‌ను నమోదు చేయండి',
            buying_price: 'ఖరీదు ధర',
            buying_price_placeholder: 'ఖరీదు ధరను నమోదు చేయండి',
            selling_price: 'అమ్మకం ధర',
            selling_price_placeholder: 'అమ్మకం ధరను నమోదు చేయండి',
            category: 'కేటగిరీ',
            category_placeholder: 'కేటగిరీని ఎంచుకోండి',
            add_selling_price: 'తక్కువ ధరను చేర్చండి',
            expired_date: 'అవసరానికి తేది',
            expired_date_placeholder: 'తేదీని ఎంచుకోండి',
            description: 'వివరణ',
            description_placeholder: 'వివరణను నమోదు చేయండి'
        },
        choose_selling_price: 'అమ్మకం ధరను ఎంచుకోండి',
        selling_price: 'అమ్మకం ధర',
        total_product: 'మొత్తం ఉత్పత్తులు',
        total_quantity: 'మొత్తం స్టాక్',
        total_buying_price: 'మొత్తం ఖరీదు ధర',
        total_selling_price: 'మొత్తం అమ్మకం ధర',
        message: {
            required_name: 'ఉత్పత్తి పేరు అవసరం',
            required_sku: 'SKU అవసరం',
            required_category: 'కేటగిరీ అవసరం',
            required_quantity: 'ప్రాథమిక స్టాక్ అవసరం',
            invalid_input_qty: 'చెల్లని స్టాక్ పరిమాణం',
            required_buying_price: 'ఖరీదు ధర అవసరం',
            required_selling_price: 'అమ్మకం ధర అవసరం',
            required_selling_price_name: 'అమ్మకం ధర పేరు అవసరం',
            required_selling_price_price: 'అమ్మకం ధర అవసరం'
        }
    },
    note: {
        title: 'గమనికలు',
        add: 'గమనిక చేర్చండి',
        no_note: 'గమనికలు అందుబాటులో లేవు'
    },
    settings: {
        category: 'కేటగిరీ',
        language: 'భాష',
        switch_business: 'వ్యాపారం మార్చండి',
        currencySelect: 'నాణెం'
    },
    category: {
        category_list: {
            title: 'ఉత్పత్తి కేటగిరీలు'
        },
        add_category: {
            button: 'కొత్త కేటగిరీ',
            title: 'కొత్త కేటగిరీ చేర్చండి'
        },
        edit_category: {
            title: 'కేటగిరీని సవరించండి'
        },
        name: 'కేటగిరీ పేరు',
        name_placeholder: 'కేటగిరీ పేరును నమోదు చేయండి',
        no_category: 'కేటగిరీలు అందుబాటులో లేవు'
    },
    staff: {
        add: 'సిబ్బందిని చేర్చండి',
        edit: 'సిబ్బందిని సవరించండి',
        access: {
            stock: 'స్టాక్',
            add_product: 'కొత్త ఉత్పత్తి చేర్చండి',
            edit_product: 'ఉత్పత్తిని సవరించండి',
            delete_product: 'ఉత్పత్తిని తొలగించండి',
            view_capital_price: 'ఖర్చు ధరను వీక్షించండి',
            add_category: 'కొత్త కేటగిరీ చేర్చండి',
            edit_category: 'కేటగిరీని సవరించండి',
            delete_category: 'కేటగిరీని తొలగించండి',
            add_supplier: 'కొత్త వేదిక చేర్చండి',
            edit_supplier: 'వేదికను సవరించండి',
            delete_supplier: 'వేదికను తొలగించండి',
            add_customer: 'కొత్త కస్టమర్ చేర్చండి',
            edit_customer: 'కస్టమర్‌ను సవరించండి',
            delete_customer: 'కస్టమర్‌ను తొలగించండి',
            add_staff: 'సిబ్బందిని చేర్చండి',
            edit_staff: 'సిబ్బందిని సవరించండి',
            delete_staff: 'సిబ్బందిని తొలగించండి',
            change_business_profile: 'వ్యాపార ప్రొఫైల్‌ను మార్చండి'
        },
        no_staff: 'సిబ్బంది అందుబాటులో లేవు.'
    },
    code: {
        title: 'కొత్త సిబ్బంది చేర్చబడింది!',
        subtitle: 'Joining కి ఈ ఆహ్వాన కోడును పంచుకోండి:',
        valid: 'కోడ్ 48 గంటల పాటు మాత్రమే చెల్లుబాటు ఉంటుంది',
        share: 'వాట్సాప్‌లో పంచుకోండి',
        copied: 'కాపీ చేసారు!',
        invalid: 'చెల్లని కోడ్!'
    },
    history: {
        no_history: 'చరిత్ర లేదు.',
        detail: {
            remove: 'చరిత్రను తొలగించండి',
            confirm: 'ఈ ఫీచర్ ఇప్పటికే ఉన్న స్టాక్, చరిత్ర మరియు నివేదికలను ప్రభావితం చేస్తుంది.'
        }
    },
    supplier: {
        add: {
            title: 'వేదిక చేర్చండి'
        },
        edit: {
            title: 'వేదికను సవరించండి'
        },
        new: 'కొత్త వేదిక',
        no_supplier: 'వేదికలు అందుబాటులో లేవు.',
        bank: {
            title: 'బ్యాంక్ వివరాలు'
        },
        note: 'గమనికలు',
        form: {
            name: {
                label: 'వేదిక పేరు',
                placeholder: 'వేదిక పేరు నమోదు చేయండి',
                placheolder_not_mandatory: 'పేరు నమోదు చేయండి (ఐచికం)',
                error: {
                    required: 'పేరు ఖాళీగా ఉండలేం'
                }
            },
            contact: {
                label: 'సంప్రదింపు సంఖ్య',
                placeholder: '91934554432',
                error: {
                    required: 'సంప్రదింపు సంఖ్య ఖాళీగా ఉండలేం',
                    invalid: 'చెల్లని సంఖ్య. దేశ కోడ్ ఉండాలి'
                }
            },
            bank_name: {
                label: 'బ్యాంక్ పేరు',
                placeholder: 'బ్యాంక్ పేరు నమోదు చేయండి'
            },
            bank_account_name: {
                label: 'అకౌంట్ హోల్డర్ పేరు',
                placeholder: 'అకౌంట్ హోల్డర్ పేరు నమోదు చేయండి'
            },
            bank_account_number: {
                label: 'అకౌంట్ సంఖ్య',
                placeholder: 'అకౌంట్ సంఖ్య నమోదు చేయండి'
            },
            note: {
                label: 'గమనికలు',
                placeholder: 'గమనికలు చేర్చండి'
            }
        },
        choose: 'వేదికను ఎంచుకోండి'
    },
    customer: {
        add: {
            title: 'కొత్త కస్టమర్ చేర్చండి'
        },
        edit: {
            title: 'కస్టమర్ మార్చండి'
        },
        new: 'కొత్త కస్టమర్',
        no_customer: 'కస్టమర్లు అందుబాటులో లేవు.',
        note: 'గమనికలు',
        form: {
            name: {
                label: 'కస్టమర్ పేరు',
                placeholder: 'కస్టమర్ పేరు నమోదు చేయండి',
                placheolder_not_mandatory: 'పేరు నమోదు చేయండి (ఐచికం)',
                error: {
                    required: 'పేరు ఖాళీగా ఉండలేం'
                }
            },
            contact: {
                label: 'సంప్రదింపు సంఖ్య',
                placeholder: '91934554432',
                error: {
                    required: 'సంప్రదింపు సంఖ్య ఖాళీగా ఉండలేం',
                    invalid: 'చెల్లని సంఖ్య. దేశ కోడ్ ఉండాలి'
                }
            },
            address: {
                label: 'చిరునామా',
                placeholder: 'చిరునామా నమోదు చేయండి'
            },
            note: {
                label: 'గమనికలు',
                placeholder: 'గమనికలు చేర్చండి'
            }
        },
        choose: 'గణన చేసేది ఎంచుకోండి',
        report: {
            view: 'గణన రిపోర్ట్ చూడండి',
            title: 'గణన రిపోర్ట్',
            no_report: 'ఎంచుకున్న తేదీలో క покупателей కోసం రిపోర్టులు లేవు.',
            column: {
                name: 'పేరు',
                total_transaction: 'మొత్తం లావాదేవీలు',
                total_amount: 'మొత్తం అమ్మకాలు',
                total_profit: 'మొత్తం లాభం'
            }
        }
    },
    confirm_delete: 'మీరు ఈ డేటాను తొలగించాలనుకుంటున్నారా?',
    report: {
        title: 'నివేదిక'
    },
    accounting: {
        title: 'అకౌంటింగ్',
        view_report: 'ఆర్థిక నివేదికను చూడండి',
        dashboard_title: 'ఆర్థిక నివేదిక',
        stats: 'సంఖ్యాకాలు',
        transaction_report: 'ఘటన నివేదిక',
        filter: {
            date: {
                placeholder: 'తేదీని ఎంచుకోండి',
                submit: 'అనువర్తించు',
                cancel: 'ఫిల్టర్‌ను రీసెట్ చేయండి'
            },
            timeframe: {
                day: 'దినసరి',
                week: 'వారానికి',
                month: 'మాసానికి',
                year: 'వార్షికం'
            }
        },
        total_sales: 'మొత్తం అమ్మకాలు',
        total_purchases: 'మొత్తం కొనుగోళ్లు',
        total_profit: 'మొత్తం లాభం',
        assets_value: 'ప్రస్తుత ఆస్తి విలువ',
        table: {
            date: 'తేదీ',
            sales: 'అమ్మకాలు',
            purchases: 'కొనుగోళ్లు',
            profit: 'లాభం'
        },
        no_data: 'డేటా అందుబాటులో లేదు.'
    },
    stock_report: {
        title: 'స్టాక్ నివేదిక',
        view: 'స్టాక్ నివేదికను చూడండి',
        no_report: 'ఎంచుకున్న తేదీ లేదా సిబ్బందికి కోసం స్టాక్ రికార్డులు లేవు.',
        allStaffPlaceHolder: 'అన్ని సిబ్బంది',
        column: {
            total_product: 'ప్రస్తుత ఉత్పత్తి రకాలు',
            total_product_quantity: 'మొత్తం ప్రస్తుత ఉత్పత్తులు'
        }
    },
    subscription: {
        paywall_small: {
            title: 'వీఐపీ ఫీచర్',
            description:
                'ఈ ఫీచర్ కోసం మీ పరీక్షా కాలం ముగిసింది. సబ్‌ స్క్రైబ్ చేసి ఈ స్థానిక యాప్ అభివృద్ధిని మద్దతు ఇవ్వండి.',
            button: 'సబ్‌స్క్రిప్షన్ ప్రణాళికలను చూడండి'
        }
    },
    payment: {
        order: {
            title: 'చెల్లింపు'
        }
    },
    imageUploadMessage: {
        uploadSize: 'చిత్రం పరిమాణం 5MB కంటే మించకూడదు!',
        failedMessage: 'ఉత్పత్తి చిత్రాన్ని అప్‌లోడ్ చేయడంలో విఫలమైంది, దయచేసి మళ్ళీ ప్రయత్నించండి!',
        successUpload: 'చిత్రం విజయవంతంగా అప్‌లోడ్ చేయబడింది!',
        failedUIError: 'చిత్రాన్ని తొలగించడంలో విఫలమైంది, దయచేసి మళ్ళీ ప్రయత్నించండి!',
        SuccessErrorImage: 'చిత్రం విజయవంతంగా తొలగించబడింది!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'ఉత్పత్తి పేరు',
        categoryLabel: 'వర్గం',
        BuyingPriceLabel: 'కొనుగోలు ధర',
        SellingLabel: 'అమ్మకానికి ధర',
        StockLabel: 'స్టాక్'
    },
    historyColumns: {
        Transactionlabel: 'వ్యవహార రకం',
        Locallabel: 'స్థానిక సమయం',
        Createdlabel: 'సృష్టించిన వ్యక్తి',
        Vendorlabel: 'అందించిన వ్యక్తి పేరు',
        Buyerlabel: 'కొనుగోలుదారు పేరు',
        SKUlabel: 'SKU',
        Productlabel: 'ఉత్పత్తి పేరు',
        Categorylabel: 'వర్గం',
        Buyinglabel: 'కొనుగోలు ధర',
        Sellinglabel: 'అమ్మకానికి ధర',
        Initiallabel: 'ప్రాథమిక స్టాక్',
        Finallabel: 'చివరి స్టాక్',
        Stocklabel: 'స్టాక్ మార్పు'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'ఉత్పత్తి పేరు',
        categoryLabel: 'వర్గం',
        buyingPriceLabel: 'కొనుగోలు ధర',
        sellingPriceLabel: 'అమ్మకానికి ధర',
        stockInLabel: 'స్టాక్ లో',
        stockOutLabel: 'స్టాక్ బయట',
        remainingStockLabel: 'మిగిలిన స్టాక్'
    },
    accountingReportColumns: {
        labelDate: 'తేదీ',
        labelSales: 'అమ్మకాలు',
        labelPurchases: 'కొనుగొళ్లు',
        labelProfit: 'లాభం'
    },
    customerReportColumns: {
        labelName: 'పేరు',
        labelTotalTransaction: 'మొత్తం వ్యవహారాలు',
        labelTotalSales: 'మొత్తం అమ్మకాలు',
        labelTotalProfit: 'మొత్తం లాభం'
    },
    scannerDialog: {
        finished: 'ముగిసింది',
        reduce: 'కమీగా',
        successfull: 'విజయవంతంగా',
        searching: 'శోధిస్తున్నాం...',
        title: 'స్కానర్ సక్రియంగా ఉంది. దానిని బార్కోడ్‌కు చూపించండి!'
    },
    historyFilter: {
        SelectBuyer: 'కొనుగోలుదారుని ఎంచుకోండి',
        SelectVendor: 'అందించిన వ్యక్తిని ఎంచుకోండి'
    },
    productStockFilter: {
        StockLowtoHigh: 'స్టాక్ తక్కువ నుండి ఎక్కువ',
        StockHightoLow: 'స్టాక్ ఎక్కువ నుండి తక్కువ',
        AlphabetAZ: 'అక్షరాలు A-Z',
        AlphabetZA: 'అక్షరాలు Z-A'
    },
    minimizeTitle: 'స్కానర్ సక్రియంగా ఉంది',
    security_guarantee: {
        title: 'డేటా భద్రత హామీ'
    },
    invoice: {
        invoicePlaceholder: 'బిల్ నంబర్',
        invoiceTitle: 'ఇన్వాయిస్'
    }
}

export default te
