const common = {
    no_data: 'कोणतीही माहिती उपलब्ध नाही',
    header: {
        business_name: 'तुमचा व्यवसाय'
    },
    download_app: {
        text: 'सिंपली वेब आवृत्तीचा वापर करण्यासाठी कृपया लॅपटॉप किंवा पीसी वापरा.',
        button: 'मोबाइल आवृत्ती डाउनलोड करा'
    },
    unknown: 'अज्ञात',
    request_error:
        'एक त्रुटी आली आहे. कृपया पुन्हा प्रयत्न करा! किंवा तुमच्या समस्येची माहिती देण्यासाठी ग्राहक समर्थनाशी संपर्क साधा.',
    home: 'गृहपृष्ठ',
    product: 'उत्पादन',
    add_product: 'उत्पादन जोडा',
    edit_product: 'उत्पादन संपादित करा',
    stock_in: 'स्टॉकमध्ये',
    Sales_Turnover: 'विक्री टर्नओव्हर',
    profit: 'नफा',
    stock_out: 'स्टॉक बाहेर',
    staff: 'कर्मचारी',
    stock_audit: 'स्टॉक ऑडिट',
    button: {
        save: 'जतन करा',
        save_and_add_more: 'जतन करा & अधिक जोडा',
        submit: 'सादर करा',
        done: 'पूर्ण',
        edit: 'संपादित करा',
        save_edit: 'बदल जतन करा',
        yes: 'होय',
        clear: 'स्पष्ट करा',
        delete: 'हटवा',
        cancel: 'रद्द करा',
        reset: 'पुन्हा सेट करा'
    },
    select_date: 'तारीख निवडा',
    select_action: 'क्रिया निवडा',
    mandatory: ' *(आवश्यक)',
    not_mandatory: '(आवश्यक नाही)',
    business: 'व्यवसाय',
    account: 'खाता',
    profile: 'प्रोफाइल',
    setting: 'सेटिंग्ज',
    whichLanguage: 'मराठी',
    headerDashboard: 'व्हीआयपी बना',
    contact_us: 'आमच्याशी संपर्क साधा',
    logout: 'लॉगआउट',
    category: 'गट',
    data: 'डेटा',
    history: 'इतिहास',
    message: {
        login_success: 'यशस्वीरित्या लॉगिन केले!',
        onboard_success: 'आनंद!! तुमचा प्रवास सुरू झाला आहे!',
        successfully_saved: 'डेटा यशस्वीरित्या सुरक्षित केला',
        successfully_deleted: 'डेटा यशस्वीरित्या हटविला',
        error_required_field: 'आवश्यक क्षेत्र रिक्त ठेवता येणार नाही',
        no_access: 'अॅक्सेस नाही',
        error_update_history: 'इतिहास अद्यतन करण्यात अयशस्वी'
    },
    feedback: 'फीडबॅक द्या',
    contact: 'संपर्क',
    add: 'जोडा',
    supplier: 'विक्रेता',
    customer: 'ग्राहक',
    random: 'यादृच्छिक',
    scan: 'स्कॅन करा',
    enter: 'बारकोड स्कॅन करून लॉगिन करा',
    terms: {
        text: 'सुरू ठेवण्यासाठी, तुम्ही सहमत आहात',
        link: 'गोपनीयता धोरण'
    },
    others: 'इतर',
    calender: {
        presets: {
            today: 'आज',
            yesterday: 'काल',
            last_7_days: 'गेल्या 7 दिवसात',
            last_30_days: 'गेल्या 30 दिवसात',
            this_month: 'या महिन्यात',
            last_month: 'गेल्या महिन्यात',
            custom_range: 'कस्टम श्रेणी'
        }
    },
    print_large: 'मोठे छापणे (A4)',
    print_dot_matrix: 'मोठा प्रिंट (डॉट मॅट्रिक्स)',
    print_medium: 'मध्यम छापणे (75mm)',
    print_small: 'लहान छापणे (48mm)',
    view_invoice: 'इनव्हॉइस',
    price: 'किंमत',
    stock: 'स्टॉक',
    quantity: 'प्रमाण',
    total: 'एकूण',
    print_footer_left: 'तयार केलेले',
    print_footer_center: 'छापलेले',
    print_footer_date: 'वर',
    print_to: 'ला',
    language: 'मराठी',
    dateStatus: 'गेल्या 7 दिवसांत',
    placeholder: 'व्यवहाराचा प्रकार',
    staffHistory: 'स्टाफ निवडा',
    languageTitle: 'मराठी',
    date: 'तारीख',
    download: 'डाउनलोड',
    download_excel: 'एक्सेल डाउनलोड करा',
    currentTitle: 'चलन',
    Selectperiod: 'कालावधी निवडा',
    TransactionType: 'व्यवहाराचा प्रकार',
    SelectCustomer: 'ग्राहक निवडा',
    invoice: 'इनव्हॉइस',
    login: {
        google: 'गुगलने लॉगिन करा',
        apple: 'ॲपलने लॉगिन करा'
    },
    trusted_by: {
        title: '50,000 हून अधिक व्यवसायांनी \n इंडोनेशियामध्ये विश्वास ठेवला 🇮🇩'
    },
    privacyPolicyOne: 'साइन इन करून, आपण Simply च्या',
    privacyPolicyTwo: 'गोपनीयता धोरणाला',
    privacyPolicyThree: 'सहमत आहात.'
}

export default common
