const IconNote = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 10H14V12H3V10ZM3 8H14V6H3V8ZM3 16H10V14H3V16ZM18.01 12.87L18.72 12.16C18.8125 12.0673 18.9224 11.9937 19.0434 11.9436C19.1643 11.8934 19.294 11.8676 19.425 11.8676C19.556 11.8676 19.6856 11.8934 19.8066 11.9436C19.9276 11.9937 20.0375 12.0673 20.13 12.16L20.84 12.87C21.23 13.26 21.23 13.89 20.84 14.28L20.13 14.99L18.01 12.87ZM17.3 13.58L12 18.88V21H14.12L19.42 15.7L17.3 13.58Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconNote
