const common = {
    no_data: 'માહિતી ઉપલબ્ધ નથી',
    header: {
        business_name: 'તમારો બિઝનેસ'
    },
    download_app: {
        text: 'Simply ની વેબ વર્ઝનનો ઉપયોગ કરવા માટે, કૃપા કરીને લેપટોપ અથવા PC નો ઉપયોગ કરો.',
        button: 'મોબાઇલ વર્ઝન ડાઉનલોડ કરો'
    },
    unknown: 'અજ્ઞાત',
    request_error:
        'એક ભૂલ બની. કૃપા કરીને ફરીથી પ્રયત્ન કરો! અથવા તમારી સમસ્યા જણાવવા માટે કસ્ટમર સપોર્ટનો સંપર્ક કરો.',
    home: 'મુખ્ય પાનું',
    product: 'ઉત્પાદન',
    add_product: 'ઉત્પાદન ઉમેરો',
    edit_product: 'ઉત્પાદન સંપાદિત કરો',
    stock_in: 'સ્ટોક ઇન',
    Sales_Turnover: 'વેચાણ આદર',
    profit: 'નફો',
    stock_out: 'સ્ટોક આઉટ',
    staff: 'સ્ટાફ',
    stock_audit: 'સ્ટોક ઓડિટ',
    button: {
        save: 'સાચવો',
        save_and_add_more: 'સાચવો અને વધુ ઉમેરો',
        submit: 'સબમિટ કરો',
        done: 'પૂર્ણ',
        edit: 'સંપાદિત કરો',
        save_edit: 'ફેરફાર સાચવો',
        yes: 'હા',
        clear: 'ક્લિયર કરો',
        delete: 'ડિલીટ કરો',
        cancel: 'રદ કરો',
        reset: 'રીસેટ કરો'
    },
    select_date: 'તારીખ પસંદ કરો',
    select_action: 'ક્રિયા પસંદ કરો',
    mandatory: ' *(આવશ્યક)',
    not_mandatory: '(આવશ્યક નથી)',
    business: 'વ્યાપાર',
    account: 'ખાતું',
    profile: 'પ્રોફાઇલ',
    setting: 'સેટિંગ્સ',
    whichLanguage: 'ગુજરાતી',
    headerDashboard: 'VIP બનો',
    contact_us: 'અમારો સંપર્ક કરો',
    logout: 'લોગઆઉટ',
    category: 'કેટેગરી',
    data: 'ડેટા',
    history: 'ઇતિહાસ',
    message: {
        login_success: 'સફળતાપૂર્વક લોગિન થયું!',
        onboard_success: 'હુર્રે!! તમારો સફર શરૂ થયો છે!',
        successfully_saved: 'માહિતી સફળતાપૂર્વક સાચવાઈ',
        successfully_deleted: 'માહિતી સફળતાપૂર્વક ડિલીટ થઈ',
        error_required_field: 'આવશ્યક ફીલ્ડ ખાલી ન હોઈ શકે',
        no_access: 'પ્રવેશ નથી',
        error_update_history: 'ઇતિહાસ અપડેટ કરવામાં નિષ્ફળ'
    },
    feedback: 'પ્રતિસાદ આપો',
    contact: 'સંપર્ક કરો',
    add: 'ઉમેરો',
    supplier: 'વિક્રેતા',
    customer: 'ગ્રાહક',
    random: 'રેન્ડમ',
    scan: 'સ્કેન કરો',
    enter: 'બારકોડ સ્કેન કરીને લોગિન કરો',
    terms: {
        text: 'ચાલુ રાખવા માટે, તમે સ્વીકારે છે',
        link: 'ગોપનીયતા નીતિ'
    },
    others: 'અન્ય',
    calender: {
        presets: {
            today: 'આજે',
            yesterday: 'ગઇકાલે',
            last_7_days: 'છેલ્લા 7 દિવસ',
            last_30_days: 'છેલ્લા 30 દિવસ',
            this_month: 'આ મહિનો',
            last_month: 'છેલ્લો મહિનો',
            custom_range: 'કસ્ટમ રેન્જ'
        }
    },
    print_large: 'મોટું પ્રિન્ટ કરો (A4)',
    print_dot_matrix: 'મોટો છાપો (ડોટ મેટ્રિક્સ)',
    print_medium: 'મધ્યમ પ્રિન્ટ કરો (75mm)',
    print_small: 'નાનું પ્રિન્ટ કરો (48mm)',
    view_invoice: 'ઇન્વોઇસ',
    price: 'કિંમત',
    stock: 'સ્ટોક',
    quantity: 'જથ્થો',
    total: 'કુલ',
    print_footer_left: 'દ્વારા બનાવવામાં આવ્યું',
    print_footer_center: 'પ્રિન્ટ થયેલ છે',
    print_footer_date: 'પર',
    print_to: 'પ્રતિ',
    language: 'ગુજરાતી',
    dateStatus: 'છેલ્લા 7 દિવસ',
    placeholder: 'ટ્રાન્ઝેક્શન પ્રકાર',
    staffHistory: 'સ્ટાફ પસંદ કરો',
    languageTitle: 'ગુજરાતી',
    date: 'તારીખ',
    download: 'ડાઉનલોડ કરો',
    download_excel: 'એક્સેલ ડાઉનલોડ કરો',
    currentTitle: 'કરન્સી',
    Selectperiod: 'કાળ પસંદ કરો',
    TransactionType: 'ટ્રાન્ઝેક્શન પ્રકાર',
    SelectCustomer: 'ગ્રાહક પસંદ કરો',
    invoice: 'ઇન્વોઇસ',
    login: {
        google: 'ગૂગલ સાથે લોગિન કરો',
        apple: 'એપલ સાથે લોગિન કરો'
    },
    trusted_by: {
        title: '50,000થી વધુ બિઝનેસ દ્વારા વિશ્વસનીય \n ઇન્ડોનેશિયાના વિસ્તારમા 🇮🇩'
    },
    privacyPolicyOne: 'લૉગ ઇન કરતી વખતે, તમે Simply ની',
    privacyPolicyTwo: 'ગોપનીયતા નીતિ',
    privacyPolicyThree: 'ને માનતા છો.'
}

export default common
