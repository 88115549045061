const featuresPay = {
    featureTitle: 'ದತ್ತಾಂಶ ಆಧಾರಿತ ವ್ಯವಹಾರ ನಿರ್ಣಯಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ',
    featureDescription:
        'ಯಾವ ಐಟಮ್‌ಗಳು ಮಾರಾಟವಾಗುತ್ತಿವೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಲಾಭವನ್ನು ಒದಗಿಸುತ್ತವೆ ಎಂಬುದನ್ನು ತಿಳಿದುಕೊಳ್ಳಿ, ಮತ್ತು ಯಾವಾಗಲೂ, ಎಲ್ಲೆಡೆ ಹಣಕಾಸು ವರದಿಗಳಿಗೆ ಪ್ರವೇಶ ಪಡೆಯಿರಿ. ನಿಮ್ಮ ವ್ಯವಹಾರ ನಿಮ್ಮ ಕೈಯಲ್ಲಿದೆ!',
    featureButton: 'ಅಪ್‌ನ ಪ್ರೀಮಿಯಮ್ ಚಂದಾದಾರಿಕೆ',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'ಪ್ರಾರಂಭವಾಗಿ',
    featureEndingText: 'ಹಜಾರು',

    features: [
        {
            title: 'ಅನಿಯಮಿತ ಐಟಮ್‌ಗಳು',
            description: 'ನೀವು ಹೊಂದಿರುವ ಎಲ್ಲಾ ಐಟಮ್‌ಗಳನ್ನು ಯಾವುದೇ ನಿರ್ಬಂಧಗಳಿಲ್ಲದೆ ಸೇರಿಸಿ.'
        },
        {
            title: 'ಅನಿಯಮಿತ ಸಿಬ್ಬಂದಿ',
            description: 'ಯಾವುದೇ ನಿರ್ಬಂಧಗಳಿಲ್ಲದೆ ವ್ಯವಹಾರಕ್ಕೆ ಸಿಬ್ಬಂದಿ/ನಿರ್ವಹಕರನ್ನು ಸೇರಿಸಿ.'
        },
        {
            title: 'ಅನಿಯಮಿತ ಇತಿಹಾಸ',
            description: 'ಹಳೆಯ ಸ್ಟಾಕ್ ದಾಖಲೆಗಳಲ್ಲಿ ಬದಲಾವಣೆಗಳನ್ನು ಯಾವುದೇ ನಿರ್ಬಂಧಗಳಿಲ್ಲದೆ ವೀಕ್ಷಿಸಿ.'
        },
        {
            title: 'ಇತಿಹಾಸ ಶೋಧಕ',
            description: 'ಪ್ರಕಾರ, ವರ್ಗ, ಸಿಬ್ಬಂದಿ ಮತ್ತು ದಿನಾಂಕದ ಆಧಾರದ ಮೇಲೆ ಇತಿಹಾಸವನ್ನು ಸುಲಭವಾಗಿ ವೀಕ್ಷಿಸಿ.'
        },
        {
            title: 'ಎಕ್ಸೆಲ್‌ಗೆ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
            description: 'ಒಂದು ಕ್ಲಿಕ್ಕಿನಲ್ಲಿ ಮೈಕ್ರೋಸಾಫ್ಟ್ ಎಕ್ಸೆಲ್‌ನಲ್ಲಿ ಸ್ಟಾಕ್ ದಾಖಲೆಯ ಇತಿಹಾಸವನ್ನು ಪಡೆಯಿರಿ.'
        },
        {
            title: 'ಪಿಡಿಎಫ್‌ಗೆ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ',
            description: 'ಒಂದು ಕ್ಲಿಕ್ಕಿನಲ್ಲಿ ಇತ್ತೀಚಿನ ಐಟಮ್‌ಗಳು ಮತ್ತು ಸ್ಟಾಕ್ ಪಟ್ಟಿಯನ್ನು ಪಿಡಿಎಫ್‌ನಲ್ಲಿ ಪಡೆಯಿರಿ.'
        }
    ]
}

export default featuresPay
