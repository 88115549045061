const featuresPay = {
    featureTitle: 'डेटा पर आधारित व्यावसायिक निर्णय लें',
    featureDescription:
        'जानें कि कौन से आइटम बिक रहे हैं और सबसे अधिक लाभ प्रदान कर रहे हैं, और किसी भी समय, कहीं से भी वित्तीय रिपोर्ट तक पहुंच प्राप्त करें। आपके हाथ में आपका व्यवसाय!',
    featureButton: 'ऐप से प्रीमियम सदस्यता',
    featureStartingPrice: '80',
    featureEndingPart: '30',
    featureStaringText: 'शुरुआत',
    featureEndingText: 'हज़ार से',

    features: [
        {
            title: 'अनलिमिटेड आइटम्स',
            description: 'बिना किसी सीमा के आप अपने सभी आइटम्स को जोड़ सकते हैं।'
        },
        {
            title: 'अनलिमिटेड स्टाफ',
            description: 'व्यवसाय में बिना किसी सीमा के स्टाफ/एडमिन जोड़ें।'
        },
        {
            title: 'अनलिमिटेड हिस्ट्री',
            description: 'पुराने स्टॉक रिकॉर्ड में बदलावों को बिना किसी सीमा के देखें।'
        },
        {
            title: 'हिस्ट्री फिल्टर',
            description: 'प्रकार, श्रेणी, स्टाफ और तारीख के आधार पर इतिहास को आसानी से देखें।'
        },
        {
            title: 'एक्सेल में डाउनलोड',
            description: 'माइक्रोसॉफ्ट एक्सेल में स्टॉक रिकॉर्ड इतिहास को एक ही क्लिक में प्राप्त करें।'
        },
        {
            title: 'पीडीएफ में डाउनलोड',
            description: 'एक ही क्लिक में नवीनतम आइटम्स और स्टॉक सूची को पीडीएफ में प्राप्त करें।'
        }
    ]
}

export default featuresPay
