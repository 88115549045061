import React from 'react'

export const Countries = [
    {
        name: 'indonesia',
        code: '+62',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.25"
                    y="0.25"
                    width="27.5"
                    height="19.5"
                    rx="1.75"
                    fill="white"
                    stroke="#F5F5F5"
                    strokeWidth="0.5"
                />
                <mask
                    id="mask0_2703_23708"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </mask>
                <g mask="url(#mask0_2703_23708)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 10.6667H28V0H0V10.6667Z" fill="#E12237" />
                </g>
            </svg>
        )
    },
    {
        name: 'india',
        code: '+91',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.25"
                    y="0.25"
                    width="27.5"
                    height="19.5"
                    rx="1.75"
                    fill="white"
                    stroke="#F5F5F5"
                    strokeWidth="0.5"
                />
                <mask
                    id="mask0_2703_23712"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </mask>
                <g mask="url(#mask0_2703_23712)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V13.3333H0V20Z" fill="#1A9F0B" />
                    <path
                        d="M14 12.3333C15.2887 12.3333 16.3333 11.2887 16.3333 10C16.3333 8.71134 15.2887 7.66667 14 7.66667C12.7113 7.66667 11.6667 8.71134 11.6667 10C11.6667 11.2887 12.7113 12.3333 14 12.3333Z"
                        fill="#181A93"
                        fillOpacity="0.15"
                        stroke="#181A93"
                        strokeWidth="0.666667"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.0002 10.6667C14.3684 10.6667 14.6668 10.3682 14.6668 10C14.6668 9.63182 14.3684 9.33334 14.0002 9.33334C13.632 9.33334 13.3335 9.63182 13.3335 10C13.3335 10.3682 13.632 10.6667 14.0002 10.6667Z"
                        fill="#181A93"
                    />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 6.66667H28V0H0V6.66667Z" fill="#FFA44A" />
                </g>
            </svg>
        )
    },
    {
        name: 'malaysia',
        code: '+60',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_24041"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_24041)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28 0H13V1H28V0ZM13 3H28V4H13V3ZM28 5H13V6H28V5ZM13 8H28V9H13V8ZM28 11H0V12H28V11ZM0 13H28V14H0V13ZM28 16H0V17H28V16ZM28 19H0V20H28V19Z"
                        fill="#E1244A"
                    />
                    <rect width="16" height="11" fill="#0C3F8E" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.6665 9.33334C7.61161 9.33334 8.48017 9.00557 9.16468 8.45749C8.80208 8.59274 8.40959 8.66668 7.99984 8.66668C6.15889 8.66668 4.6665 7.17429 4.6665 5.33334C4.6665 3.49239 6.15889 2.00001 7.99984 2.00001C8.40959 2.00001 8.80208 2.07394 9.16468 2.2092C8.48017 1.66112 7.61161 1.33334 6.6665 1.33334C4.45736 1.33334 2.6665 3.1242 2.6665 5.33334C2.6665 7.54248 4.45736 9.33334 6.6665 9.33334ZM9.50948 7.73593L10.6665 6.42668L11.8235 7.73593L11.5213 6.01503L13.2663 5.92673L11.7324 5.09005L12.7514 3.6707L11.1409 4.34828L10.6665 2.66668L10.1921 4.34828L8.58162 3.6707L9.60058 5.09005L8.0667 5.92673L9.8117 6.01503L9.50948 7.73593Z"
                        fill="#FFD34F"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'thailand',
        code: '+66',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.25"
                    y="0.25"
                    width="27.5"
                    height="19.5"
                    rx="1.75"
                    fill="white"
                    stroke="#F5F5F5"
                    strokeWidth="0.5"
                />
                <mask
                    id="mask0_2703_24512"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </mask>
                <g mask="url(#mask0_2703_24512)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V16H0V20Z" fill="#F12532" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3334H28V6.66669H0V13.3334Z" fill="#322B6C" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 4H28V0H0V4Z" fill="#F12532" />
                </g>
            </svg>
        )
    },
    {
        //check icon
        name: 'brunei',
        code: '+673',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_23179"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_23179)">
                    <rect width="28" height="20" fill="#F7E250" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M-4.1674 3.38761L32.1674 16.6124L33.5355 12.8536L-2.79932 -0.371159L-4.1674 3.38761Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M-5.53556 7.14638L30.7992 20.3712L32.1673 16.6124L-4.16748 3.38761L-5.53556 7.14638Z"
                        fill="#262626"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'vietnam',
        code: '+84',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_24786"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_24786)">
                    <rect width="28" height="20" fill="#EA403F" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14 12.34L10.4733 14.8541L11.7745 10.7231L8.29366 8.1459L12.6246 8.1069L14 4L15.3754 8.1069L19.7063 8.1459L16.2255 10.7231L17.5267 14.8541L14 12.34Z"
                        fill="#FFFE4E"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'cambodia',
        code: '+855',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_23788"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_23788)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V14.6667H0V20Z" fill="#0F3EB6" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 14.6667H28V5.33334H0V14.6667Z" fill="#ED1A3C" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3332 8.72722H11.9998V10H15.9998V8.72722H14.6665V8.00001C14.6665 7.63182 14.368 7.33334 13.9998 7.33334C13.6316 7.33334 13.3332 7.63182 13.3332 8.00001V8.72722ZM17.4998 8.00001C17.2237 8.00001 16.9998 8.22387 16.9998 8.50001V10H17.9998V8.50001C17.9998 8.22387 17.776 8.00001 17.4998 8.00001ZM9.99984 10.6667H11.3332V13.3333H8.6665L9.99984 10.6667ZM17.9998 10.6667H16.6665V13.3333H19.3332L17.9998 10.6667ZM15.9998 14V11H11.9998V14H15.9998ZM9.99984 8.50001C9.99984 8.22387 10.2237 8.00001 10.4998 8.00001C10.776 8.00001 10.9998 8.22387 10.9998 8.50001V10H9.99984V8.50001Z"
                        fill="white"
                    />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 5.33333H28V0H0V5.33333Z" fill="#0F3EB6" />
                </g>
            </svg>
        )
    },
    {
        name: 'philippines',
        code: '+63',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.25"
                    y="0.25"
                    width="27.5"
                    height="19.5"
                    rx="1.75"
                    fill="white"
                    stroke="#F5F5F5"
                    strokeWidth="0.5"
                />
                <mask
                    id="mask0_2703_24267"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </mask>
                <g mask="url(#mask0_2703_24267)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 20H28V9.33331H12.4444L13.3333 9.99998L0 20Z"
                        fill="#DD1C34"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28 9.33333H12.4444L0 0H28V9.33333Z"
                        fill="#0D4BC3"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.66683 3.33335C2.66683 3.70154 2.36835 4.00002 2.00016 4.00002C1.63197 4.00002 1.3335 3.70154 1.3335 3.33335C1.3335 2.96516 1.63197 2.66669 2.00016 2.66669C2.36835 2.66669 2.66683 2.96516 2.66683 3.33335ZM3.39122 13.0796L4.53224 11.3266C4.5765 11.3311 4.6214 11.3334 4.66683 11.3334C4.71226 11.3334 4.75716 11.3311 4.80142 11.3266L5.94244 13.0796L5.50971 11.0332C5.57934 10.9763 5.64312 10.9125 5.7 10.8429L7.74643 11.2756L5.99345 10.1346C5.99789 10.0904 6.00016 10.0455 6.00016 10C6.00016 9.95459 5.99789 9.90969 5.99345 9.86543L7.74643 8.72441L5.7 9.15714C5.64312 9.0875 5.57934 9.02373 5.50971 8.96685L5.94244 6.92042L4.80142 8.6734C4.75716 8.66896 4.71226 8.66669 4.66683 8.66669C4.6214 8.66669 4.5765 8.66896 4.53224 8.6734L3.39122 6.92042L3.82395 8.96685C3.75431 9.02373 3.69054 9.08751 3.63366 9.15714L1.58723 8.72441L3.3402 9.86543C3.33577 9.90969 3.3335 9.95459 3.3335 10C3.3335 10.0455 3.33577 10.0904 3.3402 10.1346L1.58723 11.2756L3.63366 10.8429C3.69054 10.9125 3.75431 10.9763 3.82395 11.0332L3.39122 13.0796ZM10.0002 10.6667C10.3684 10.6667 10.6668 10.3682 10.6668 10C10.6668 9.63183 10.3684 9.33335 10.0002 9.33335C9.63197 9.33335 9.3335 9.63183 9.3335 10C9.3335 10.3682 9.63197 10.6667 10.0002 10.6667ZM2.00016 17.3334C2.36835 17.3334 2.66683 17.0349 2.66683 16.6667C2.66683 16.2985 2.36835 16 2.00016 16C1.63197 16 1.3335 16.2985 1.3335 16.6667C1.3335 17.0349 1.63197 17.3334 2.00016 17.3334Z"
                        fill="#FDD64D"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'myanmar',
        code: '+95',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_24159"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_24159)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V13.3333H0V20Z" fill="#F13D4F" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 13.3333H28V6.66666H0V13.3333Z" fill="#4AC94B" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 6.66667H28V0H0V6.66667Z" fill="#FFD043" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14 12.34L10.4733 14.8541L11.7745 10.7231L8.29366 8.1459L12.6246 8.1069L14 4L15.3754 8.1069L19.7063 8.1459L16.2255 10.7231L17.5267 14.8541L14 12.34Z"
                        fill="white"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'laos',
        code: '+856',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_23917"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_23917)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 14.6667H28V5.33334H0V14.6667Z" fill="#073A88" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V14.6667H0V20Z" fill="#E2273E" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.9998 13.3333C15.8408 13.3333 17.3332 11.8409 17.3332 9.99999C17.3332 8.15904 15.8408 6.66666 13.9998 6.66666C12.1589 6.66666 10.6665 8.15904 10.6665 9.99999C10.6665 11.8409 12.1589 13.3333 13.9998 13.3333Z"
                        fill="white"
                    />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 5.33333H28V0H0V5.33333Z" fill="#E2273E" />
                </g>
            </svg>
        )
    },
    {
        name: 'singapore',
        code: '+65',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="0.25"
                    y="0.25"
                    width="27.5"
                    height="19.5"
                    rx="1.75"
                    fill="white"
                    stroke="#F5F5F5"
                    strokeWidth="0.5"
                />
                <mask
                    id="mask0_2703_24451"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.5"
                    />
                </mask>
                <g mask="url(#mask0_2703_24451)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 10.6667H28V0H0V10.6667Z" fill="#E12237" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.6665 9.33331C7.61161 9.33331 8.48017 9.00554 9.16468 8.45746C8.80208 8.59271 8.40959 8.66665 7.99984 8.66665C6.15889 8.66665 4.6665 7.17426 4.6665 5.33331C4.6665 3.49236 6.15889 1.99998 7.99984 1.99998C8.40959 1.99998 8.80208 2.07391 9.16468 2.20917C8.48017 1.66109 7.61161 1.33331 6.6665 1.33331C4.45736 1.33331 2.6665 3.12417 2.6665 5.33331C2.6665 7.54245 4.45736 9.33331 6.6665 9.33331ZM11.9998 7.33331C11.9998 7.7015 11.7014 7.99998 11.3332 7.99998C10.965 7.99998 10.6665 7.7015 10.6665 7.33331C10.6665 6.96512 10.965 6.66665 11.3332 6.66665C11.7014 6.66665 11.9998 6.96512 11.9998 7.33331ZM8.6665 7.99998C9.03469 7.99998 9.33317 7.7015 9.33317 7.33331C9.33317 6.96512 9.03469 6.66665 8.6665 6.66665C8.29831 6.66665 7.99984 6.96512 7.99984 7.33331C7.99984 7.7015 8.29831 7.99998 8.6665 7.99998ZM13.3332 4.66665C13.3332 5.03484 13.0347 5.33331 12.6665 5.33331C12.2983 5.33331 11.9998 5.03484 11.9998 4.66665C11.9998 4.29846 12.2983 3.99998 12.6665 3.99998C13.0347 3.99998 13.3332 4.29846 13.3332 4.66665ZM7.33317 5.33331C7.70136 5.33331 7.99984 5.03484 7.99984 4.66665C7.99984 4.29846 7.70136 3.99998 7.33317 3.99998C6.96498 3.99998 6.6665 4.29846 6.6665 4.66665C6.6665 5.03484 6.96498 5.33331 7.33317 5.33331ZM10.6665 2.66665C10.6665 3.03484 10.368 3.33331 9.99984 3.33331C9.63165 3.33331 9.33317 3.03484 9.33317 2.66665C9.33317 2.29846 9.63165 1.99998 9.99984 1.99998C10.368 1.99998 10.6665 2.29846 10.6665 2.66665Z"
                        fill="white"
                    />
                </g>
            </svg>
        )
    },
    {
        name: 'timor-leste',
        code: '+670',
        svg: (
            <svg className="w-4.5 h-4.5" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="20" rx="2" fill="white" />
                <mask
                    id="mask0_2703_24530"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="28"
                    height="20">
                    <rect width="28" height="20" rx="2" fill="white" />
                </mask>
                <g mask="url(#mask0_2703_24530)">
                    <rect width="28" height="20" fill="#FF323E" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L17.3333 10L0 20V0Z" fill="#FFCC51" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L12 10L0 20V0Z" fill="#262626" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.94385 10.8244L5.05957 12.7634L4.59774 10.6829L2.48043 10.4411L4.31633 9.35898L3.89204 7.27056L5.48851 8.68221L7.3436 7.63333L6.49438 9.5879L8.06518 11.0281L5.94385 10.8244Z"
                        fill="white"
                    />
                </g>
            </svg>
        )
    }
]
