const IconUser = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-inherit h-inherit" viewBox="0 0 40 40">
            <path
                d="M20 19.625q-3.042 0-5.021-1.979T13 12.625q0-3.042 1.979-5.021T20 5.625q3.042 0 5.021 1.979T27 12.625q0 3.042-1.979 5.021T20 19.625ZM5.833 35v-5q0-1.708.834-2.979.833-1.271 2.166-1.938 2.834-1.375 5.605-2.041 2.77-.667 5.562-.667 2.792 0 5.542.687 2.75.688 5.583 2.021 1.375.667 2.208 1.938.834 1.271.834 2.979v5Zm3.5-3.5h21.334V30q0-.583-.271-1.062-.271-.48-.729-.688-2.542-1.25-4.896-1.812-2.354-.563-4.771-.563t-4.771.563q-2.354.562-4.896 1.812-.458.208-.729.667-.271.458-.271 1.083ZM20 16.125q1.5 0 2.5-1t1-2.5q0-1.5-1-2.5t-2.5-1q-1.5 0-2.5 1t-1 2.5q0 1.5 1 2.5t2.5 1Zm0-3.5ZM20 31.5Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconUser
