const IconDatabase = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.59961 14.4V18C3.59961 19.9884 7.36041 21.6 11.9996 21.6C16.6388 21.6 20.3996 19.9884 20.3996 18V14.4C20.3996 16.3884 16.6388 18 11.9996 18C7.36041 18 3.59961 16.3884 3.59961 14.4Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.79961 17.6669V18C4.79961 18.0914 4.8377 18.2787 5.11383 18.5585C5.39505 18.8434 5.86148 19.1551 6.5326 19.4427C7.87057 20.0161 9.80261 20.4 11.9996 20.4C14.1966 20.4 16.1287 20.0161 17.4666 19.4427C18.1377 19.1551 18.6042 18.8434 18.8854 18.5585C19.1615 18.2787 19.1996 18.0914 19.1996 18V17.6669C18.9483 17.8047 18.6845 17.9319 18.412 18.0487C16.7098 18.7782 14.4418 19.2 11.9996 19.2C9.55741 19.2 7.28945 18.7782 5.58722 18.0487C5.31469 17.9319 5.05094 17.8047 4.79961 17.6669ZM3.59961 18C3.59961 19.9884 7.36041 21.6 11.9996 21.6C16.6388 21.6 20.3996 19.9884 20.3996 18V14.4C20.3996 15.0788 19.9614 15.7136 19.1996 16.2554C17.7297 17.3009 15.0552 18 11.9996 18C8.94402 18 6.26948 17.3009 4.79961 16.2554C4.03783 15.7136 3.59961 15.0788 3.59961 14.4V18Z"
                fill="currentColor"
            />
            <path
                d="M3.59961 8.40002V12C3.59961 13.9884 7.36041 15.6 11.9996 15.6C16.6388 15.6 20.3996 13.9884 20.3996 12V8.40002C20.3996 10.3884 16.6388 12 11.9996 12C7.36041 12 3.59961 10.3884 3.59961 8.40002Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.79961 11.6669V12C4.79961 12.0914 4.8377 12.2787 5.11383 12.5585C5.39505 12.8434 5.86148 13.1551 6.5326 13.4427C7.87057 14.0161 9.80261 14.4 11.9996 14.4C14.1966 14.4 16.1287 14.0161 17.4666 13.4427C18.1377 13.1551 18.6042 12.8434 18.8854 12.5585C19.1615 12.2787 19.1996 12.0914 19.1996 12V11.6669C18.9483 11.8047 18.6845 11.9319 18.412 12.0487C16.7098 12.7782 14.4418 13.2 11.9996 13.2C9.55741 13.2 7.28945 12.7782 5.58722 12.0487C5.31469 11.9319 5.05094 11.8047 4.79961 11.6669ZM3.59961 12C3.59961 13.9884 7.36041 15.6 11.9996 15.6C16.6388 15.6 20.3996 13.9884 20.3996 12V8.40002C20.3996 9.07877 19.9614 9.71361 19.1996 10.2554C17.7297 11.3009 15.0552 12 11.9996 12C8.94402 12 6.26948 11.3009 4.79961 10.2554C4.03783 9.71361 3.59961 9.07877 3.59961 8.40002V12Z"
                fill="currentColor"
            />
            <path
                d="M20.3996 6.00002C20.3996 7.98842 16.6388 9.60002 11.9996 9.60002C7.36041 9.60002 3.59961 7.98842 3.59961 6.00002C3.59961 4.01162 7.36041 2.40002 11.9996 2.40002C16.6388 2.40002 20.3996 4.01162 20.3996 6.00002Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8854 6.55847C19.1615 6.27868 19.1996 6.09135 19.1996 6.00002C19.1996 5.90869 19.1615 5.72137 18.8854 5.44158C18.6042 5.15663 18.1377 4.84497 17.4666 4.55735C16.1287 3.98396 14.1966 3.60002 11.9996 3.60002C9.80261 3.60002 7.87057 3.98396 6.5326 4.55735C5.86148 4.84497 5.39505 5.15663 5.11383 5.44158C4.8377 5.72137 4.79961 5.90869 4.79961 6.00002C4.79961 6.09135 4.8377 6.27868 5.11383 6.55847C5.39505 6.84342 5.86148 7.15508 6.5326 7.44269C7.87057 8.01609 9.80261 8.40002 11.9996 8.40002C14.1966 8.40002 16.1287 8.01609 17.4666 7.44269C18.1377 7.15508 18.6042 6.84342 18.8854 6.55847ZM11.9996 9.60002C16.6388 9.60002 20.3996 7.98842 20.3996 6.00002C20.3996 4.01162 16.6388 2.40002 11.9996 2.40002C7.36041 2.40002 3.59961 4.01162 3.59961 6.00002C3.59961 7.98842 7.36041 9.60002 11.9996 9.60002Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconDatabase
