import * as Sentry from '@sentry/browser'

const setUser = (userId?: string) => {
    if (!!userId) Sentry.setUser({ id: userId })
}

const clearUser = () => {
    Sentry.setUser(null)
}

const sendSentryEvent = (message: any) => {
    Sentry.captureMessage(message, {})
}

const $sentry = {
    setUser,
    clearUser,
    sendSentryEvent
}

export default $sentry
