const IconCurrency = () => {
    return (
        <svg
            width="800px"
            height="800px"
            viewBox="0 0 512 512"
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    className="st0"
                    d="M373.3,348.2v-29.9c5.3,1.2,10.6,3.6,16,6.8c1.6,0.9,3.1,1.4,4.7,1.4c4.8,0,8.7-3.7,8.7-8.6   c0-3.7-2.2-6.1-4.7-7.5c-7.2-4.5-14.9-7.5-24.1-8.6v-3.4c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.8,3-6.8,6.7v3.3   c-19.4,1.6-32.7,13.1-32.7,29.7c0,17.6,10.6,26.1,33.3,32v30.6c-8.6-1.6-15.7-5.1-23-10.4c-1.4-1.1-3.3-1.7-5.1-1.7   c-4.8,0-8.6,3.7-8.6,8.6c0,3.3,1.6,5.8,4.2,7.5c9.5,6.7,20.2,10.9,31.9,12.1v9.6c0,3.7,3.1,6.7,6.8,6.7c3.7,0,6.7-3,6.7-6.7v-9.3   c19.8-1.9,33.1-13.2,33.1-30.3C407.1,363.6,397,354.1,373.3,348.2z M361,344.9c-12-3.9-14.9-7.9-14.9-14.5c0-6.8,5-12.1,14.9-13.1   V344.9z M373.3,394.8v-28.3c12,3.7,15.4,7.9,15.4,14.8C388.7,388.8,383.3,393.7,373.3,394.8z"
                />

                <path
                    className="st0"
                    d="M109.5,194.5c-3.4,1.1-5.1,3.7-5.1,6.8c0,3.7,3,6.7,7.3,6.7h68.1c4.7,0,8.4-3.6,8.4-8.2c0-4.7-3.7-8.4-8.4-8.4   h-42.9v-26.6h31.4c4.4,0,8.1-3.6,8.1-8.1c0-4.4-3.7-8.1-8.1-8.1h-31.4v-13.8c0-6.5,1.9-11.7,5.1-15.1c3-2.8,6.5-4.4,11.5-4.4   c7.2,0,11.8,3,16.6,8.1c1.2,1.4,3.4,3,6.8,3c5.3,0,9.5-4,9.5-9.3c0-3-1.2-5.1-3-6.8c-6.8-7.3-16-12.6-30.3-12.6   c-11.2,0-20.1,3.4-26.1,9.6c-6.2,6.2-9.6,15.7-9.6,27.1v14.3h-5c-4.5,0-8.1,3.7-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1h5v27.4L109.5,194.5z   "
                />

                <path
                    className="st0"
                    d="M365.7,255.6c-14.8,0-29.2,3.1-42.6,9.1c-37.1,16.7-61,53.8-61,94.5c0,1.1,0,2.3,0.1,3.4   c0.9,27,12,52.2,31.4,71c19.4,18.9,45.1,29.2,72.1,29.2c57.1,0,103.6-46.5,103.6-103.6C469.3,302.1,422.9,255.6,365.7,255.6z    M365.7,448.8c-48.7,0-88-38.1-89.6-86.7c0-1,0-1.9,0-2.9c0-35.2,20.7-67.2,52.8-81.7c11.6-5.2,24-7.9,36.8-7.9   c49.4,0,89.6,40.2,89.6,89.6C455.3,408.6,415.1,448.8,365.7,448.8z"
                />

                <path
                    className="st0"
                    d="M146.3,256.4c27.1,0,52.7-10.4,72.1-29.2c19.4-18.8,30.5-44,31.4-71c0-1.1,0.1-2.3,0.1-3.4   c0-40.7-24-77.7-61-94.5c-13.4-6.1-27.8-9.1-42.6-9.1c-57.1,0-103.6,46.5-103.6,103.6C42.7,209.9,89.1,256.4,146.3,256.4z    M146.3,63.2c12.8,0,25.2,2.7,36.8,7.9c32.1,14.5,52.8,46.6,52.8,81.7c0,1,0,1.9,0,2.9c-1.6,48.6-40.9,86.7-89.6,86.7   c-49.4,0-89.6-40.2-89.6-89.6C56.7,103.4,96.9,63.2,146.3,63.2z"
                />

                <path
                    className="st0"
                    d="M352.7,71.2V51.8c0-2.7-1.5-5.1-3.9-6.3c-2.4-1.2-5.2-0.9-7.3,0.7l-76.6,58.4c-1.7,1.3-2.8,3.4-2.8,5.6   c0,2.2,1,4.2,2.8,5.6l76.6,58.4c2.1,1.6,5,1.9,7.3,0.7c2.4-1.2,3.9-3.6,3.9-6.3v-16.4c34.1,7,64,29.1,80.7,60   c6.7,12.4,11.1,25.7,13,39.7c0.5,3.5,3.4,6,6.9,6h9c3.9,0,7-3.1,7-7v-48c0-33-12.2-64.7-34.4-89.1C413.4,90,384.3,75,352.7,71.2z    M455.3,228c-2.5-7.8-5.7-15.3-9.6-22.5c-19.7-36.5-55.8-62-96.6-68.2c-0.8-0.1-1.6-0.2-2.5-0.4c-0.3,0-0.6-0.1-1-0.1   c-1.7,0-3.3,0.6-4.6,1.7c-1.5,1.3-2.4,3.3-2.4,5.3v10.5l-58.1-44.2l58.1-44.2v11.6c0,3.7,2.8,6.7,6.5,7c1.3,0.1,2.6,0.2,3.8,0.3   c60.6,6.3,106.3,57,106.3,118V228z"
                />

                <path
                    className="st0"
                    d="M247.1,396.3l-76.6-58.4c-2.1-1.6-5-1.9-7.3-0.7c-2.4,1.2-3.9,3.6-3.9,6.3v16.4c-34.1-7-64-29.1-80.7-60   c-6.7-12.4-11.1-25.7-13-39.7c-0.5-3.5-3.4-6-6.9-6h-9c-3.9,0-7,3.1-7,7v48c0,33,12.2,64.7,34.4,89.1   c21.5,23.7,50.7,38.8,82.2,42.6v19.4c0,2.7,1.5,5.1,3.9,6.3c1,0.5,2,0.7,3.1,0.7c1.5,0,3-0.5,4.2-1.4l76.6-58.4   c1.7-1.3,2.8-3.4,2.8-5.6C249.9,399.7,248.9,397.6,247.1,396.3z M173.3,446.1v-11.6c0-3.7-2.8-6.7-6.5-7c-1.3-0.1-2.6-0.2-3.8-0.3   c-60.6-6.3-106.3-57-106.3-118V284c2.5,7.8,5.7,15.3,9.6,22.5c19.7,36.5,55.8,62,96.6,68.2c0.8,0.1,1.6,0.2,2.5,0.4   c2,0.3,4-0.3,5.6-1.7c1.5-1.3,2.4-3.3,2.4-5.3v-10.5l58.1,44.2L173.3,446.1z"
                />
            </g>
        </svg>
    )
}

export default IconCurrency
