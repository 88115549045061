const IconBoxCar = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 -960 960 960" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M230.118-138Q179-138 141.5-171.417 104-204.833 102-256H17v-473q0-39.05 27.475-66.525Q71.95-823 111-823h579v155h98l155 207.556V-256h-83q-3 51.167-40.382 84.583Q782.235-138 731.118-138 680-138 642.5-171.417 605-204.833 603-256H358q-3 50-39.882 84-36.883 34-88 34ZM230-210q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17ZM111-350h26.347q16.653-22 40.592-35t51-13q27.061 0 51.536 12.875Q304.951-372.25 321-350h275v-379H111v379Zm620 140q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-41-215h162L741-573h-51v148ZM354-529Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconBoxCar
