import { ClearUserState } from '~/api'
import { Button } from '~/components/Common/Button'
import Icon from '~/components/Icon'

import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import locale from '~/locale'
import { $error } from '~/utils/logger'

export const Logout = () => {
    return (
        <Button
            text={locale.id.common.logout}
            type="outlined"
            size="sm"
            icon={
                <span className="text-red contents">
                    <Icon name="logout" size="lg"></Icon>
                </span>
            }
            onClick={logout}></Button>
    )
}

export const logout = async () => {
    try {
        $posthog.reset()
        $sentry.clearUser()
        ClearUserState()
        window.location.href = '/login'
    } catch (error: any) {
        $error('Something is not right!', error)
    }
}
