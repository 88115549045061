import { useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover'

import { Spacer } from '~/components/Common/Spacer'
import { SelectOption } from '~/components/Form/Select'
import { DropdownListItem } from './DropdownListItem'

interface DropdownWrapperProps {
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * Dropdown parent
     */
    children: JSX.Element
    /**
     * Maximum height
     */
    maxHeight?: number
    /**
     * Disable input
     */
    disabled?: boolean
    /**
     * Match dropdown width to parent
     */
    fitToParentBox?: boolean
    /**
     * Options of select input
     */
    options?: SelectOption[]
    /**
     * Value of input
     */
    value?: any
    /**
     * Class name for the wrapper
     */
    className?: string
    /**
     * Dropdown item size
     */
    dropdownItemSize?: 'sm' | 'md' | 'lg'
    /**
     * Optional input event
     */
    onSelect?: (value: SelectOption) => void
    /**
     * Optional open dropdown event
     */
    onOpen?: () => void
    /**
     * Optional close dropdown event
     */
    onClose?: () => void
    /**
     * Popover side
     */
    side?: 'top' | 'right' | 'bottom' | 'left'
    /**
     * Popover alignment
     */
    align?: 'start' | 'center' | 'end'
}

export const DropdownWrapper = ({
    dropdownMinWidth,
    prepend,
    append,
    children,
    maxHeight = 300,
    disabled,
    fitToParentBox = false,
    options = [],
    value = '',
    onSelect,
    dropdownItemSize,
    onOpen = () => false,
    onClose = () => false,
    side = 'bottom',
    align = 'start'
}: DropdownWrapperProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpenChange = (open: boolean) => {
        setIsOpen(open)
        if (open) {
            onOpen()
        } else {
            onClose()
        }
    }

    const handleSelect = (_value: any) => {
        if (!onSelect || disabled) {
            return
        }

        onSelect(_value)
        setIsOpen(false)
    }

    return (
        <Popover open={isOpen} onOpenChange={handleOpenChange}>
            <PopoverTrigger asChild disabled={disabled}>
                {children}
            </PopoverTrigger>
            <PopoverContent
                side={side}
                align={align}
                style={{
                    minWidth: dropdownMinWidth,
                    maxHeight: maxHeight,
                    width: fitToParentBox ? '100%' : undefined,
                    overflowY: 'auto'
                }}
                className="p-0">
                {prepend}
                {options.map((item, index) => (
                    <DropdownListItem
                        key={index}
                        isActive={value === item.value}
                        size={dropdownItemSize}
                        onClick={() => handleSelect(item)}>
                        <>
                            {item.icon && <span className="flex-shrink-0 mr-2 flex items-center">{item.icon}</span>}
                            <span>{item.longText || item.text}</span>
                            <Spacer />
                            {item.subtext && <span className="text-xs text-gray-500">{item.subtext}</span>}
                        </>
                    </DropdownListItem>
                ))}
                {append}
            </PopoverContent>
        </Popover>
    )
}
