import { $error, $log } from './logger'

interface StoreI<T = any, COLLECTION_KEY = any> {
    id: string
    createdAt: number
    updatedAt: number
    collection: StoreCollection<T, COLLECTION_KEY>[]
}

interface StoreCollection<T, COLLECTION_KEY> {
    key: COLLECTION_KEY
    value: {
        data: T
        createdAt: number
        updatedAt: number
        expiredAt?: number
    }
}

/**
 * Local store
 */
class LocalStore<COLLECTION_KEY> {
    version: number
    id: string
    name: string
    timestamp: number

    /**
     * Constructor
     * @param {number} version
     * @param {string} id
     */
    constructor(version: number, id: string) {
        this.version = version
        this.id = id
        this.name = `app-store-v${this.version}`
        this.timestamp = Math.floor(new Date().getTime() / 1000)
    }

    /**
     * Get store data from local storage
     * @return {StoreI[]}
     */
    getStore(): StoreI[] {
        const data = localStorage.getItem(this.name)

        if (!data) {
            localStorage.setItem(this.name, JSON.stringify([]))
            return []
        }

        return JSON.parse(data)
    }

    /**
     * Set store data into local storage
     * @param {StoreI[]} data
     */
    setStore(data: StoreI[]) {
        $log('setStore', data)
        localStorage.setItem(this.name, JSON.stringify(data))
    }

    /**
     * Get store item from store
     * @return {StoreI | null}
     */
    getStoreItem(): StoreI | null {
        const store = this.getStore()
        const data = store.find((item) => item.id === this.id)
        return data ? data : null
    }

    /**
     * Get item collection
     * @param {COLLECTION_KEY} key
     * @param {boolean} withTimestamp
     * @return {T | null}
     */
    get<T = any>(key: COLLECTION_KEY): StoreCollection<T, COLLECTION_KEY>['value'] | null {
        const storeItem = this.getStoreItem()

        if (!storeItem) {
            return null
        }

        const collection = storeItem.collection.find((item) => item.key === key)

        if (!collection) {
            return null
        }

        if (this.getExpiry(collection.value.expiredAt)) {
            this.remove(key)
            return null
        }

        return collection.value
    }

    /**
     * Set item collection
     * @param {COLLECTION_KEY} key
     * @param {T} data
     * @param {string} expiredAt - days or hours ex: 1d, 24h. Optional
     */
    set<T = any>(key: COLLECTION_KEY, data: T, expiredAt?: string) {
        const store = this.getStore()
        const transformExpiredAt = expiredAt ? this.getExpiredAt(expiredAt, this.timestamp) : undefined

        // if store item not exist add as new entry else update
        const storeItemIndex = store.findIndex((item) => item.id === this.id)
        if (storeItemIndex === -1) {
            const collection: StoreI<T>['collection'] = [
                {
                    key,
                    value: {
                        data,
                        createdAt: this.timestamp,
                        updatedAt: this.timestamp,
                        expiredAt: transformExpiredAt
                    }
                }
            ]

            store.push({
                id: this.id,
                createdAt: this.timestamp,
                updatedAt: this.timestamp,
                collection
            })
            localStorage.setItem(this.name, JSON.stringify(store))
            return
        }

        const storeItem = store[storeItemIndex]

        // check if collection item exist if not add as new entry else update
        const collectionIndex = storeItem.collection.findIndex((item) => item.key === key)
        if (collectionIndex === -1) {
            storeItem.collection.push({
                key,
                value: {
                    data,
                    createdAt: this.timestamp,
                    updatedAt: this.timestamp,
                    expiredAt: transformExpiredAt
                }
            })

            this.setStore(store)
            return
        }

        storeItem.updatedAt = this.timestamp
        storeItem.collection[collectionIndex].value = {
            ...storeItem.collection[collectionIndex].value,
            data,
            updatedAt: this.timestamp,
            expiredAt: transformExpiredAt
        }

        this.setStore(store)
    }

    /**
     * Remove collection item
     * @param {COLLECTION_KEY} key
     * @return {boolean}
     */
    remove(key: COLLECTION_KEY): boolean {
        const store = this.getStore()

        const storeItemIndex = store.findIndex((item) => item.id === this.id)

        if (storeItemIndex === -1) {
            return false
        }

        // if collection length is more than 1 remove the item else remove the whole store item
        if (store[storeItemIndex].collection.length > 1) {
            store[storeItemIndex].collection = store[storeItemIndex].collection.filter((item) => item.key !== key)
            store[storeItemIndex].updatedAt = this.timestamp
            this.setStore(store)
            return true
        }

        this.setStore(store.filter((item) => item.id !== this.id))
        return true
    }

    /**
     * Clean previous version of the store
     */
    clean() {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)
            if (!key) continue

            if (key.startsWith('app-store-v') && !key.endsWith(`-v${this.version}`)) {
                localStorage.removeItem(key)
            }
        }
    }

    /**
     * Check if data is expired
     * @param {number} expiredAt unix timestamp
     * @return {boolean}
     */
    getExpiry(expiredAt?: number) {
        if (!expiredAt) {
            return false
        }
        return this.timestamp > expiredAt
    }

    /**
     * Check if data is expired
     * @param {string} value string
     * @param {number} currentTimeStamp unix timestamp
     * @return {number | undefined} expiredAtTimeStamp unix
     */
    getExpiredAt = (value: string, currentTimeStamp: number) => {
        try {
            const valueExtracted = Number(value.replace(/m|h|d|/g, ''))
            let timeToAdd = 0
            if (value.endsWith('m')) {
                timeToAdd = valueExtracted * 60
            } else if (value.endsWith('h')) {
                timeToAdd = valueExtracted * 60 * 60
            } else if (value.endsWith('d')) {
                timeToAdd = valueExtracted * 24 * 60 * 60
            } else {
                throw Error()
            }
            return currentTimeStamp + timeToAdd
        } catch (e) {
            $error('Unexpected expireAt format')
            return undefined
        }
    }
}

export default LocalStore

type StoreKey =
    | 'ACCESS_TOKEN'
    | 'SELECTED_BUSINESS'
    | 'SELECTED_PRODUCT'
    | 'ONBOARD_STEP'
    | 'OTPLESS_TOKEN'
    | 'OAUTH_TOKEN'
    | 'PLATFORM'

export const localStore = new LocalStore<StoreKey>(1, 'app')
