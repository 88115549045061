import { useState } from 'react'

export const useDebounce = (
    callback: (value: string) => void,
    callbackAfterTimeout: (value: string) => void,
    delay = 200
) => {
    const [typingTimer, setTypingTimer] = useState<ReturnType<typeof setTimeout>>()

    const debounce = (value: string) => {
        callback(value)

        if (!!typingTimer) {
            clearTimeout(typingTimer)
        }

        const _typingTimer = setTimeout(() => {
            callbackAfterTimeout(value)
        }, delay)

        setTypingTimer(_typingTimer)
    }

    return { debounce }
}
