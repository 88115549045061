const IconCloseFullscreen = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" className="w-inherit h-inherit">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M122 989.566 66.434 934l278.782-278.782H160.782V576H480v319.218h-79.218V710.784L122 989.566ZM480 576V256.216h79.784V440.65L838 162.434 893.566 218 615.35 496.216h184.434V576H480Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconCloseFullscreen
