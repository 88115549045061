import Head from 'next/head'

interface MetaProps {
    /**
     * Title
     */
    title?: string
    /**
     * Description
     */
    description?: string
    /**
     * Additional meta
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Use site name as prefix
     */
    useSiteName?: boolean
}

export const Meta = ({
    title = 'Simply - Simple Stock Inventory App',
    description = '',
    children,
    useSiteName = true
}: MetaProps) => {
    const fullTitle = useSiteName ? title + ' | Simply' : title
    const defaultDescription =
        'A complete solution for managing business needs, from stock entry, stock exit, invoicing, and financial reporting.'
    return (
        <>
            <Head>
                <title>{fullTitle}</title>
                <meta id="description" name="description" content={description || defaultDescription} />
                <meta id="robots" name="robots" content="noindex, nofollow" />
                <meta id="og-title" property="og:title" content={fullTitle} />
                <meta id="og-description" property="og:description" content={description || defaultDescription} />
                <meta id="twitter-title" name="twitter:title" content={fullTitle} />
                <meta id="twitter-description" name="twitter:description" content={description || defaultDescription} />
                {children}
            </Head>
        </>
    )
}
