const IconAdd = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 9H3V30C3 31.65 4.35 33 6 33H27V30H6V9ZM30 3H12C10.35 3 9 4.35 9 6V24C9 25.65 10.35 27 12 27H30C31.65 27 33 25.65 33 24V6C33 4.35 31.65 3 30 3ZM28.5 16.5H22.5V22.5H19.5V16.5H13.5V13.5H19.5V7.5H22.5V13.5H28.5V16.5Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconAdd
