interface DropdownListItemProps {
    /**
     * List of dropdown items
     */
    children: JSX.Element | JSX.Element[] | string
    /**
     * Active state
     */
    isActive?: boolean
    /**
     * Size
     */
    size?: 'sm' | 'md' | 'lg'
    /**
     * Click event
     */
    onClick?: () => void
}

export const DropdownListItem = ({ children, isActive = false, size = 'md', onClick }: DropdownListItemProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <div
            className={`flex items-center ${sizeMap[size]} hover:bg-[#FF520014] cursor-pointer ${
                isActive ? 'text-[#FF5200]' : 'text-neutrals-900 font-normal'
            }`}
            onClick={() => handleClick()}>
            {children}
        </div>
    )
}

const sizeMap = {
    sm: 'px-2 py-1.5 text-xs leading-normal',
    md: 'px-4 py-3 text-sm leading-normal font-medium',
    lg: 'px-4 py-3 text-sm leading-normal font-medium'
}
