import React from 'react'

const IconChevronRight = () => {
    return (
        <svg className="w-inherit h-inherit" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75173 17.6484C8.52677 17.4234 8.40039 17.1182 8.40039 16.8C8.40039 16.4818 8.52677 16.1767 8.75173 15.9516L12.7033 12L8.75173 8.04842C8.53314 7.8221 8.41219 7.51898 8.41493 7.20434C8.41766 6.88971 8.54386 6.58873 8.76635 6.36624C8.98884 6.14375 9.28982 6.01755 9.60445 6.01482C9.91909 6.01208 10.2222 6.13304 10.4485 6.35163L15.2485 11.1516C15.4735 11.3767 15.5999 11.6818 15.5999 12C15.5999 12.3182 15.4735 12.6234 15.2485 12.8484L10.4485 17.6484C10.2235 17.8734 9.91833 17.9998 9.60013 17.9998C9.28194 17.9998 8.97677 17.8734 8.75173 17.6484Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.75173 15.9516C8.52677 16.1767 8.40039 16.4818 8.40039 16.8C8.40039 17.1182 8.52677 17.4234 8.75173 17.6484C8.97677 17.8734 9.28194 17.9998 9.60013 17.9998C9.91833 17.9998 10.2235 17.8734 10.4485 17.6484L15.2485 12.8484C15.4735 12.6234 15.5999 12.3182 15.5999 12C15.5999 11.6818 15.4735 11.3767 15.2485 11.1516L10.4485 6.35163C10.2222 6.13304 9.91909 6.01208 9.60445 6.01482C9.28982 6.01755 8.98884 6.14375 8.76635 6.36624C8.54386 6.58873 8.41766 6.88971 8.41493 7.20434C8.41219 7.51898 8.53314 7.8221 8.75173 8.04842L12.7033 12L8.75173 15.9516Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default IconChevronRight
