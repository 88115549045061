const common = {
    no_data: 'Keine Daten verfügbar',
    header: {
        business_name: 'Ihr Unternehmen'
    },
    download_app: {
        text: 'Um die Simply-Webversion zu verwenden, nutzen Sie bitte einen Laptop oder PC.',
        button: 'Mobile Version herunterladen'
    },
    unknown: 'Unbekannt',
    request_error:
        'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut! Oder kontaktieren Sie den Kundenservice, um Ihr Problem zu melden.',
    home: 'Startseite',
    product: 'Produkt',
    add_product: 'Produkt hinzufügen',
    edit_product: 'Produkt bearbeiten',
    stock_in: 'Wareneingang',
    Sales_Turnover: 'Umsatz',
    profit: 'Gewinn',
    stock_out: 'Warenausgang',
    staff: 'Mitarbeiter',
    stock_audit: 'Bestandsprüfung',
    button: {
        save: 'Speichern',
        save_and_add_more: 'Speichern & Mehr hinzufügen',
        submit: 'Einreichen',
        done: 'Fertig',
        edit: 'Bearbeiten',
        save_edit: 'Änderungen speichern',
        yes: 'Ja',
        clear: 'Löschen',
        delete: 'Löschen',
        cancel: 'Abbrechen',
        reset: 'Zurücksetzen'
    },
    select_date: 'Datum auswählen',
    select_action: 'Aktion auswählen',
    mandatory: ' *(Erforderlich)',
    not_mandatory: '(Nicht erforderlich)',
    business: 'Geschäft',
    account: 'Konto',
    profile: 'Profil',
    setting: 'Einstellungen',
    whichLanguage: 'Deutsch',
    headerDashboard: 'Werden Sie ein VIP',
    contact_us: 'Kontaktieren Sie uns',
    logout: 'Ausloggen',
    category: 'Kategorie',
    data: 'Daten',
    history: 'Verlauf',
    message: {
        login_success: 'Erfolgreich eingeloggt!',
        onboard_success: 'Hurra!! Ihre Reise hat begonnen!',
        successfully_saved: 'Daten erfolgreich gespeichert',
        successfully_deleted: 'Daten erfolgreich gelöscht',
        error_required_field: 'Das erforderliche Feld darf nicht leer sein',
        no_access: 'Kein Zugriff',
        error_update_history: 'Aktualisierung des Verlaufs fehlgeschlagen'
    },
    feedback: 'Feedback geben',
    contact: 'Kontakt',
    add: 'Hinzufügen',
    supplier: 'Lieferant',
    customer: 'Kunde',
    random: 'Zufällig',
    scan: 'Scannen',
    enter: 'Einloggen durch Scannen des Barcodes',
    terms: {
        text: 'Durch die Fortsetzung stimmen Sie der',
        link: 'Datenschutzrichtlinie'
    },
    others: 'Sonstiges',
    calender: {
        presets: {
            today: 'Heute',
            yesterday: 'Gestern',
            last_7_days: 'Letzte 7 Tage',
            last_30_days: 'Letzte 30 Tage',
            this_month: 'Dieser Monat',
            last_month: 'Letzter Monat',
            custom_range: 'Benutzerdefinierter Zeitraum'
        }
    },
    print_large: 'Drucken Groß (A4)',
    print_dot_matrix: 'Großdruck (Dot Matrix)',
    print_medium: 'Drucken Mittel (75mm)',
    print_small: 'Drucken Klein (48mm)',
    view_invoice: 'Rechnung',
    price: 'Preis',
    stock: 'Bestand',
    quantity: 'Menge',
    total: 'Gesamt',
    print_footer_left: 'Erstellt von',
    print_footer_center: 'Gedruckt am',
    print_footer_date: 'am',
    print_to: 'An',
    language: 'Deutsch',
    dateStatus: 'Letzte 7 Tage',
    placeholder: 'Transaktionstyp',
    staffHistory: 'Mitarbeiter auswählen',
    languageTitle: 'Deutsch',
    date: 'Datum',
    download: 'Herunterladen',
    download_excel: 'Excel herunterladen',
    currentTitle: 'Währung',
    Selectperiod: 'Zeitraum auswählen',
    TransactionType: 'Transaktionstyp',
    SelectCustomer: 'Kunde auswählen',
    invoice: 'Rechnung',
    login: {
        google: 'Mit Google anmelden',
        apple: 'Mit Apple anmelden'
    },
    trusted_by: {
        title: 'Vertraut von über 50.000 Unternehmen \n in ganz Indonesien 🇮🇩'
    },
    privacyPolicyOne: 'Durch die Anmeldung stimmen Sie Simplys',
    privacyPolicyTwo: 'Datenschutzrichtlinie',
    privacyPolicyThree: 'zu.'
}

export default common
