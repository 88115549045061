// redux/slices/currencySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrencyState } from '../type'

// Define the initial state structure for currency
const getInitialCurrency = (): { currency: string; currencySymbol: string } => {
    if (typeof window !== 'undefined') {
        const savedCurrency = localStorage.getItem('currency') || '' // Default to 'USD'
        const savedCurrencySymbol = localStorage.getItem('currencySymbol') || '' // Default symbol for USD
        return { currency: savedCurrency, currencySymbol: savedCurrencySymbol }
    }
    return { currency: '', currencySymbol: '' } // Default value for server-side rendering
}

const initialState: CurrencyState = getInitialCurrency()

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrencyState(state, action: PayloadAction<{ currency: string; currencySymbol: string }>) {
            state.currency = action.payload.currency
            state.currencySymbol = action.payload.currencySymbol

            if (typeof window !== 'undefined') {
                localStorage.setItem('currency', action.payload.currency) // Save currency to localStorage
                localStorage.setItem('currencySymbol', action.payload.currencySymbol) // Save currency symbol to localStorage
            }
        }
    }
})

export const { setCurrencyState } = currencySlice.actions
export default currencySlice.reducer
