import common from './common'
import featuresPay from './feature'

const ur = {
    featuresPay,
    common,
    short: {
        finance: 'مالیات',
        stock: 'اسٹاک'
    },
    onboarding: {
        welcome: 'خوش آمدید!',
        step: {
            product: {
                title: '65% مکمل',
                description: 'عظیم کامیابی کا آغاز کرنے کے لیے صرف 2 مراحل باقی ہیں!'
            },
            stock: {
                title: '95% تقریباً مکمل!!',
                description: 'بس ایک مرحلہ باقی ہے عظیم کامیابی کا آغاز کرنے کے لیے! ابھی مکمل کریں۔'
            }
        },
        business: {
            title: 'کاروبار کامیابی کے ساتھ بنایا گیا!',
            description: 'بہت خوب! آپ کا کاروبار رجسٹر ہو گیا ہے۔'
        },
        product: {
            title: 'اپنی پہلی پروڈکٹ شامل کریں',
            description: 'اسٹاک ریکارڈنگ شروع کرنے کے لیے پروڈکٹ شامل کریں'
        },
        stock: {
            title: 'اسٹاک شامل کرنے کی کوشش کریں',
            description: 'آنے اور جانے والے اسٹاک کو ریکارڈ کرنا شروع کریں'
        },
        action: {
            add_business: {
                title: 'کاروبار بنائیں',
                description: 'نیا کاروبار بنانا چاہتے ہیں؟ یہاں کلک کریں'
            },
            join_business: {
                title: 'کاروبار میں شامل ہوں',
                description: 'آپ کے پاس دعوتی کوڈ ہے؟ یہاں شامل ہوں'
            }
        }
    },
    business: {
        title: 'کاروبار کا انتخاب کریں، نیا بنائیں، یا شامل ہوں',
        add_business: {
            title: 'نیا کاروبار بنائیں',
            name: 'کاروبار کا نام',
            name_placeholder: 'نام درج کریں',
            category: 'کاروبار کی قسم',
            category_placeholder: 'زمرہ منتخب کریں',
            button: 'کاروبار بنائیں'
        },
        edit_business: {
            title: 'کاروبار میں ترمیم کریں'
        },
        join_business: {
            title: 'کاروبار میں شامل ہوں',
            input_code: 'نیچے دعوتی کوڈ درج کریں:',
            field_placeholder: 'دعوتی کوڈ',
            confirm_title: 'شامل ہوں',
            confirm_text: 'آپ ٹیم کا حصہ بن جائیں گے '
        },
        no_business: 'کوئی کاروبار دستیاب نہیں',
        create_or_join: 'کاروبار بنائیں یا شامل ہوں',
        message: {
            saved: 'کاروبار کامیابی کے ساتھ محفوظ ہو گیا!'
        }
    },
    profile: {
        edit_profile: {
            title: 'پروفائل میں ترمیم کریں',
            name: 'پورا نام',
            name_placeholder: 'پورا نام درج کریں',
            email: 'ای میل',
            phone_number: 'فون نمبر'
        },
        message: {
            required_name: 'نام درکار ہے'
        }
    },
    product: {
        choose_product: 'پروڈکٹ منتخب کریں',
        choose_product_empty: 'پروڈکٹ منتخب کریں اور مقدار درج کریں',
        search: {
            placeholder: 'نام کے ذریعے تلاش کریں',
            tooltip: 'اسکینر کھولیں'
        },
        column: {
            image: 'تصویر',
            name: 'نام',
            price: 'قیمت',
            buying_price: 'قیمت خرید',
            selling_price: 'قیمت فروخت',
            sku: 'SKU',
            stock: 'اسٹاک',
            quantity: 'مقدار',
            latest_stock: 'باقی',
            product_deleted: 'پروڈکٹ حذف کر دی گئی ہے',
            info_not_found: 'معلومات دستیاب نہیں',
            expired_date: 'میعاد ختم ہونے کی تاریخ'
        },
        no_product: 'کوئی پروڈکٹ دستیاب نہیں',
        add_product: {
            sku: 'پروڈکٹ کوڈ',
            sku_placeholder: 'پروڈکٹ کوڈ۔ مثال: 12345',
            name: 'پروڈکٹ کا نام',
            name_placeholder: 'پروڈکٹ کا نام درج کریں',
            initial_stock: 'ابتدائی اسٹاک',
            initial_stock_placeholder: 'ابتدائی اسٹاک درج کریں',
            buying_price: 'قیمت خرید',
            buying_price_placeholder: 'قیمت خرید درج کریں',
            selling_price: 'قیمت فروخت',
            selling_price_placeholder: 'قیمت فروخت درج کریں',
            category: 'زمرہ',
            category_placeholder: 'زمرہ منتخب کریں',
            add_selling_price: 'ہول سیل قیمت شامل کریں',
            expired_date: 'میعاد ختم ہونے کی تاریخ',
            expired_date_placeholder: 'تاریخ منتخب کریں',
            description: 'تفصیل',
            description_placeholder: 'تفصیل درج کریں'
        },
        choose_selling_price: 'فروخت کی قیمت منتخب کریں',
        selling_price: 'قیمت فروخت',
        total_product: 'کل پروڈکٹس',
        total_quantity: 'کل اسٹاک',
        total_buying_price: 'کل خرید قیمت',
        total_selling_price: 'کل فروخت قیمت',
        message: {
            required_name: 'پروڈکٹ کا نام ضروری ہے',
            required_sku: 'SKU ضروری ہے',
            required_category: 'زمرہ ضروری ہے',
            required_quantity: 'ابتدائی اسٹاک ضروری ہے',
            invalid_input_qty: 'غلط اسٹاک مقدار',
            required_buying_price: 'قیمت خرید ضروری ہے',
            required_selling_price: 'قیمت فروخت ضروری ہے',
            required_selling_price_name: 'فروخت کی قیمت کا نام ضروری ہے',
            required_selling_price_price: 'فروخت کی قیمت ضروری ہے'
        }
    },
    note: {
        title: 'نوٹس',
        add: 'نوٹ شامل کریں',
        no_note: 'کوئی نوٹس دستیاب نہیں'
    },
    settings: {
        category: 'زمرہ',
        language: 'زبان',
        switch_business: 'کاروبار تبدیل کریں',
        currencySelect: 'کرنسی'
    },
    category: {
        category_list: {
            title: 'پروڈکٹ کیٹیگریز'
        },
        add_category: {
            button: 'نیا زمرہ',
            title: 'نیا زمرہ شامل کریں'
        },
        edit_category: {
            title: 'زمرہ ترمیم کریں'
        },
        name: 'زمرہ کا نام',
        name_placeholder: 'زمرہ کا نام درج کریں',
        no_category: 'کوئی زمرے دستیاب نہیں'
    },
    staff: {
        add: 'عملہ شامل کریں',
        edit: 'عملہ میں ترمیم کریں',
        access: {
            stock: 'اسٹاک',
            add_product: 'نیا پروڈکٹ شامل کریں',
            edit_product: 'پروڈکٹ میں ترمیم کریں',
            delete_product: 'پروڈکٹ حذف کریں',
            view_capital_price: 'لاگت کی قیمت دیکھیں',
            add_category: 'نیا زمرہ شامل کریں',
            edit_category: 'زمرہ میں ترمیم کریں',
            delete_category: 'زمرہ حذف کریں',
            add_supplier: 'نیا فروش شامل کریں',
            edit_supplier: 'فروش میں ترمیم کریں',
            delete_supplier: 'فروش حذف کریں',
            add_customer: 'نیا گاہک شامل کریں',
            edit_customer: 'گاہک میں ترمیم کریں',
            delete_customer: 'گاہک حذف کریں',
            add_staff: 'عملہ شامل کریں',
            edit_staff: 'عملہ میں ترمیم کریں',
            delete_staff: 'عملہ حذف کریں',
            change_business_profile: 'کاروبار پروفائل تبدیل کریں'
        },
        no_staff: 'کوئی عملہ دستیاب نہیں۔'
    },
    code: {
        title: 'نیا عملہ شامل کیا گیا!',
        subtitle: 'شامل ہونے کے لیے نیچے دعوتی کوڈ شیئر کریں:',
        valid: 'کوڈ صرف 48 گھنٹوں کے لیے موزوں ہے',
        share: 'واٹس ایپ پر شیئر کریں',
        copied: 'کاپی ہو گیا!',
        invalid: 'غلط کوڈ!'
    },
    history: {
        no_history: 'کوئی تاریخ نہیں۔',
        detail: {
            remove: 'تاریخ ہٹا دیں',
            confirm: 'یہ فیچر موجودہ اسٹاک، تاریخ، اور رپورٹس پر اثر انداز ہوگا۔'
        }
    },
    supplier: {
        add: {
            title: 'فروش شامل کریں'
        },
        edit: {
            title: 'فروش میں ترمیم کریں'
        },
        new: 'نیا فروش',
        no_supplier: 'کوئی فروش دستیاب نہیں۔',
        bank: {
            title: 'بینک کی تفصیلات'
        },
        note: 'نوٹس',
        form: {
            name: {
                label: 'فروش کا نام',
                placeholder: 'فروش کا نام درج کریں',
                placheolder_not_mandatory: 'نام درج کریں (اختیاری)',
                error: {
                    required: 'نام خالی نہیں ہو سکتا'
                }
            },
            contact: {
                label: 'رابطہ نمبر',
                placeholder: '91934554432',
                error: {
                    required: 'رابطہ نمبر خالی نہیں ہو سکتا',
                    invalid: 'غلط نمبر۔ ملک کا کوڈ شامل کرنا ضروری ہے'
                }
            },
            bank_name: {
                label: 'بینک کا نام',
                placeholder: 'بینک کا نام درج کریں'
            },
            bank_account_name: {
                label: 'اکاؤنٹ ہولڈر کا نام',
                placeholder: 'اکاؤنٹ ہولڈر کا نام درج کریں'
            },
            bank_account_number: {
                label: 'اکاؤنٹ نمبر',
                placeholder: 'اکاؤنٹ نمبر درج کریں'
            },
            note: {
                label: 'نوٹس',
                placeholder: 'نوٹس شامل کریں'
            }
        },
        choose: 'فروش منتخب کریں'
    },
    customer: {
        add: {
            title: 'گاہک شامل کریں'
        },
        edit: {
            title: 'گاہک میں ترمیم کریں'
        },
        new: 'نیا گاہک',
        no_customer: 'کوئی گاہک دستیاب نہیں۔',
        note: 'نوٹس',
        form: {
            name: {
                label: 'گاہک کا نام',
                placeholder: 'گاہک کا نام درج کریں',
                placheolder_not_mandatory: 'نام درج کریں (اختیاری)',
                error: {
                    required: 'نام خالی نہیں ہو سکتا'
                }
            },
            contact: {
                label: 'رابطہ نمبر',
                placeholder: '91934554432',
                error: {
                    required: 'رابطہ نمبر خالی نہیں ہو سکتا',
                    invalid: 'غلط نمبر۔ ملک کا کوڈ شامل کرنا ضروری ہے'
                }
            },
            address: {
                label: 'پتہ',
                placeholder: 'پتہ درج کریں'
            },
            note: {
                label: 'نوٹس',
                placeholder: 'نوٹس شامل کریں'
            }
        },
        choose: 'خریدار منتخب کریں',
        report: {
            view: 'خریدار کی رپورٹ دیکھیں',
            title: 'خریدار کی رپورٹ',
            no_report: 'منتخب تاریخ پر خریداروں کے لئے کوئی رپورٹ موجود نہیں۔',
            column: {
                name: 'نام',
                total_transaction: 'کل ٹرانزیکشن',
                total_amount: 'کل فروخت',
                total_profit: 'کل منافع'
            }
        }
    },
    confirm_delete: 'کیا آپ واقعی اس ڈیٹا کو حذف کرنا چاہتے ہیں؟',
    report: {
        title: 'رپورٹ'
    },
    accounting: {
        title: 'محاسبہ',
        view_report: 'مالی رپورٹ دیکھیں',
        dashboard_title: 'مالی رپورٹ',
        stats: 'اعداد و شمار',
        transaction_report: 'ٹرانزیکشن رپورٹ',
        filter: {
            date: {
                placeholder: 'تاریخ منتخب کریں',
                submit: 'لگائیں',
                cancel: 'فلٹر ری سیٹ کریں'
            },
            timeframe: {
                day: 'روزانہ',
                week: 'ہفتہ وار',
                month: 'ماہانہ',
                year: 'سالانہ'
            }
        },
        total_sales: 'کل فروخت',
        total_purchases: 'کل خریداری',
        total_profit: 'کل منافع',
        assets_value: 'موجودہ اثاثوں کی قدر',
        table: {
            date: 'تاریخ',
            sales: 'فروخت',
            purchases: 'خریداری',
            profit: 'منافع'
        },
        no_data: 'کوئی ڈیٹا دستیاب نہیں۔'
    },
    stock_report: {
        title: 'اسٹاک رپورٹ',
        view: 'اسٹاک رپورٹ دیکھیں',
        no_report: 'منتخب کردہ تاریخ یا عملے کے لیے کوئی اسٹاک ریکارڈ نہیں ہے۔',
        allStaffPlaceHolder: 'تمام عملہ',
        column: {
            total_product: 'موجودہ مصنوعات کی اقسام',
            total_product_quantity: 'کل موجودہ مصنوعات'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP خصوصیت',
            description:
                'اس خصوصیت کے لیے آپ کا ٹرائل دورانیہ ختم ہوگیا ہے۔ مقامی ایپ کی ترقی کی حمایت کرنے کے لیے سبسکرائب کریں۔',
            button: 'سبسکرپشن پلان دیکھیں'
        }
    },
    payment: {
        order: {
            title: 'ادائیگی'
        }
    },
    imageUploadMessage: {
        uploadSize: 'تصویر کا حجم 5MB سے زیادہ نہیں ہونا چاہیے!',
        failedMessage: 'آئٹم کی تصویر اپ لوڈ کرنے میں ناکامی، براہ کرم دوبارہ کوشش کریں!',
        successUpload: 'تصویر کامیابی سے اپ لوڈ ہوگئی!',
        failedUIError: 'تصویر کو حذف کرنے میں ناکامی، براہ کرم دوبارہ کوشش کریں!',
        SuccessErrorImage: 'تصویر کامیابی سے حذف کر دی گئی ہے!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'پروڈکٹ کا نام',
        categoryLabel: 'زمرہ',
        BuyingPriceLabel: 'خریداری کی قیمت',
        SellingLabel: 'فروخت کی قیمت',
        StockLabel: 'اسٹاک'
    },
    historyColumns: {
        Transactionlabel: 'ٹرانزیکشن کی قسم',
        Locallabel: 'مقامی وقت',
        Createdlabel: 'بنانے والا',
        Vendorlabel: 'فروخت کنندہ کا نام',
        Buyerlabel: 'خریدار کا نام',
        SKUlabel: 'SKU',
        Productlabel: 'پروڈکٹ کا نام',
        Categorylabel: 'زمرہ',
        Buyinglabel: 'خریداری کی قیمت',
        Sellinglabel: 'فروخت کی قیمت',
        Initiallabel: 'ابتدائی اسٹاک',
        Finallabel: 'حتمی اسٹاک',
        Stocklabel: 'اسٹاک کی تبدیلی'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'پروڈکٹ کا نام',
        categoryLabel: 'زمرہ',
        buyingPriceLabel: 'خریداری کی قیمت',
        sellingPriceLabel: 'فروخت کی قیمت',
        stockInLabel: 'اسٹاک میں',
        stockOutLabel: 'اسٹاک سے',
        remainingStockLabel: 'باقی اسٹاک'
    },
    accountingReportColumns: {
        labelDate: 'تاریخ',
        labelSales: 'فروخت',
        labelPurchases: 'خریداری',
        labelProfit: 'منافع'
    },
    customerReportColumns: {
        labelName: 'نام',
        labelTotalTransaction: 'کل ٹرانزیکشن',
        labelTotalSales: 'کل فروخت',
        labelTotalProfit: 'کل منافع'
    },
    scannerDialog: {
        finished: 'مکمل',
        reduce: 'کم کریں',
        successfull: 'کامیاب',
        searching: 'تلاش جاری ہے...',
        title: 'سکینر فعال ہے۔ بار کوڈ کی طرف اشارہ کریں!'
    },
    historyFilter: {
        SelectBuyer: 'خریدار منتخب کریں',
        SelectVendor: 'فروخت کنندہ منتخب کریں'
    },
    productStockFilter: {
        StockLowtoHigh: 'اسٹاک کم سے زیادہ',
        StockHightoLow: 'اسٹاک زیادہ سے کم',
        AlphabetAZ: 'حروف تہجی A-Z',
        AlphabetZA: 'حروف تہجی Z-A'
    },
    minimizeTitle: 'سکینر فعال',
    security_guarantee: {
        title: 'ڈیٹا سیکیورٹی کی ضمانت'
    },
    invoice: {
        invoicePlaceholder: 'انوائس نمبر',
        invoiceTitle: 'انوائس'
    }
}

export default ur
